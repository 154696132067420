import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  ButtonV2,
  Icon,
  Info,
  ModalV2,
  TooltipV2,
  FormItem,
  FormLabel,
  SelectV2,
  ComboBoxV3,
  Popover,
  Toggle,
  NumberField,
} from 'components'
import { usePersistentTab } from 'domains/occurrence/hooks'

import { dateNow } from 'utilities/date'
import joinClassNames from 'utilities/joinClassNames'
import { CustomerPaths } from 'routes/constants/paths/customer'

import { useToast } from 'shared/hooks'
import { getCustomer } from 'shared/hooks/services'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { useGetDeviceType } from 'shared/hooks/services/device/useGetDeviceType'

import { getAccount } from 'services'
import { getPeriodicEvents } from 'services/periodicEventControl'
import { useQueryCentral, useQueryCentralByAccounts } from 'services/central'
import { getDeviceModel, getDeviceModels } from 'services/deviceModel'
import { CentralRefused, useEnableCentral } from 'services/centralRefused'
import { getManufacturerById, getManufacturers } from 'services/manufacturer'

import { schema } from './schema'
import styles from './styles.module.scss'
import { times, TimeType, Form } from './types'

const timeOptions: { name: string; value: TimeType }[] = [
  { name: 'Horas', value: 'hours' },
  { name: 'Minutos', value: 'minutes' },
]

type EquipmentFound = 'central' | 'gprs'

interface EnableCentralProps {
  central: CentralRefused
}

const handleGetManufacturers = async (searchFilter: string, offset = 0) =>
  await getManufacturers(offset, { name: searchFilter })

const EnableCentral = ({ central: currentCentral }: EnableCentralProps) => {
  const { addToast } = useToast()
  const closeRef = useRef<HTMLButtonElement>(null)
  const { replaceUrl } = usePersistentTab('central-navigation')

  const {
    register,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<Form>({
    resolver: joiResolver(schema),
  })

  const { customer, account, keepAlive, automaticTest, central, module } =
    watch()

  const [periodicEventFound, setPeriodicEventFound] = useState('')

  const { mutate, isPending } = useEnableCentral()
  const { deviceType: modelType } = useGetDeviceType('10')
  const { deviceType: centralType } = useGetDeviceType('6')
  const { data: centralByAccount, isPending: isPendingCentralByAccount } =
    useQueryCentralByAccounts(account?.id ? [account?.id] : [])

  const { data: centrals } = useQueryCentral({
    code: currentCentral.macAddress || currentCentral.imei,
    externalId: currentCentral.account,
  })

  const [equipmentFound, setEquipmentFound] = useState<
    EquipmentFound | undefined
  >(undefined)

  const accountCentral = centralByAccount?.data[0]

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) =>
      await fetchAccounts(offset, 15, customer?.id || undefined, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      }),
    [customer],
  )

  const handleGetCentralModels = useCallback(
    async (searchFilter: string, offset = 0) =>
      await getDeviceModels(offset, {
        name: searchFilter,
        manufacturerId: central?.manufacture?.id,
        deviceTypeId: centralType?.[0]?.id,
      }),
    [centralType, central],
  )

  const handleGetModelModels = useCallback(
    async (searchFilter: string, offset = 0) =>
      await getDeviceModels(offset, {
        description: searchFilter,
        manufacturerId: module?.manufacture?.id,
        deviceTypeId: modelType?.[0]?.id,
      }),
    [modelType, module],
  )

  const onSubmit = useCallback(
    (data: Form) => {
      const { account, customer, central, module, keepAlive, automaticTest } =
        data

      const handleTime = (type: TimeType) => {
        return type === 'minutes' ? 1 : 60
      }

      mutate(
        {
          account,
          customer,
          centralRefusedId: currentCentral.id,
          deviceDescription: currentCentral.model,
          centralAccount: currentCentral.account,
          central: {
            ...central,
            code: currentCentral.macAddress || currentCentral.imei || '',
          },
          ...(data.module?.model && {
            module: {
              ...module,
              model: {
                ...data.module?.model,
              },
            },
          }),
          rehabilitation: !!centrals?.totalElements,
          ...(keepAlive && {
            keepAlive: {
              interval:
                keepAlive.interval * handleTime(keepAlive.type || 'minutes'),
              failEventRecurrenceInterval:
                keepAlive?.failEventRecurrenceInterval.time *
                handleTime(
                  keepAlive.failEventRecurrenceInterval.type || 'minutes',
                ),
            },
          }),
          ...(automaticTest && {
            automaticTest: {
              tolerance:
                automaticTest.tolerance.time *
                handleTime(automaticTest?.tolerance.type || 'minutes'),
              interval:
                automaticTest?.interval *
                handleTime(automaticTest?.type || 'minutes'),
            },
          }),
        },
        {
          onSuccess: () => {
            addToast({
              message: `GPRS/Ethernet habilitado com sucesso para a conta ${data.account?.aggregatedName}`,
              type: 'success',
            })

            if (closeRef.current) {
              closeRef.current.click()
            }
          },
          onError: () => {
            addToast({
              message: 'Erro ao habilitar GPRS/Ethernet. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [addToast, mutate, closeRef, centrals],
  )

  const handleSeeCentral = useCallback(async () => {
    replaceUrl(CustomerPaths('central', account.id))
  }, [account, customer, centralByAccount, replaceUrl])

  useEffect(() => {
    const handleSetByCurrentDevice = async () => {
      await getDeviceModels(0, {
        description: currentCentral.model,
        deviceTypeId: centralType?.[0]?.id,
      }).then(async (data) => {
        const central = data.data[0]

        if (central?.id) {
          await getManufacturerById(central.manufacturerId).then(
            ({ id, name }) => {
              setValue(
                'central',
                {
                  model: { id: central.id, name: central.name },
                  manufacture: { id, name },
                },
                {
                  shouldValidate: true,
                },
              )
            },
          )

          setEquipmentFound('central')
        } else {
          await getDeviceModels(0, {
            description: currentCentral.model,
            deviceTypeId: modelType?.[0]?.id,
          }).then(async (response) => {
            const data = response.data[0]

            if (data?.id) {
              await getManufacturerById(data.manufacturerId).then(
                ({ id, name }) =>
                  setValue(
                    'module',
                    {
                      model: { id: data.id, name: data.name },
                      manufacture: { id, name },
                    },
                    {
                      shouldValidate: true,
                    },
                  ),
              )

              setEquipmentFound('gprs')
            }
          })
        }
      })
    }

    if (!equipmentFound) {
      handleSetByCurrentDevice()
    }
  }, [])

  const handlePeriodicEvents = async (
    centralId: string,
    rehabilitation = false,
  ) => {
    const periodicEvents = await getPeriodicEvents({
      centralId,
    }).then((data) => data?.data?.sort((a, b) => a.createdAt - b.createdAt))

    const keepAlive = periodicEvents?.find((data) => data.type === 'KEEPALIVE')
    const automaticTests = periodicEvents?.find(
      (data) => data.type === 'AUTOTEST',
    )

    if (rehabilitation) {
      const status = [keepAlive && 'KEEPALIVE', automaticTests && 'AUTOTEST']
        .filter(Boolean)
        .join('_')

      setPeriodicEventFound(status)
    }

    setValue(
      'keepAlive',
      keepAlive
        ? {
            failEventRecurrenceInterval: {
              time: keepAlive.failEventRecurrenceInterval,
              type: 'minutes',
            },
            interval: keepAlive.interval,
            type: 'minutes',
          }
        : undefined,
    )

    setValue(
      'automaticTest',
      automaticTests
        ? {
            tolerance: {
              time: automaticTests.tolerance,
              type: 'minutes',
            },
            interval: automaticTests.interval,
            type: 'minutes',
          }
        : undefined,
    )
  }

  useEffect(() => {
    const handleSetByCurrentAccount = () => {
      setValue(
        'central',
        {
          model: {
            id: accountCentral?.model?.id || '',
            name: accountCentral?.model?.name || '',
          },
          manufacture: {
            id: accountCentral?.manufacturer?.id || '',
            name: accountCentral?.manufacturer?.name || '',
          },
        },
        {
          shouldValidate: true,
        },
      )

      if (accountCentral?.gprsModuleModelId) {
        getDeviceModel(accountCentral?.gprsModuleModelId).then(async (data) => {
          setValue(
            'module.model',
            { id: data.id, name: data.name },
            {
              shouldValidate: true,
            },
          )

          await getManufacturerById(data.manufacturerId).then(({ id, name }) =>
            setValue(
              'module.manufacture',
              { id, name },
              {
                shouldValidate: true,
              },
            ),
          )
        })
      } else {
        setValue('module', undefined, { shouldValidate: true })
      }
    }

    if (!equipmentFound && !centrals?.data.length) {
      handleSetByCurrentAccount()
    }

    if (accountCentral?.id && !centrals?.data.length) {
      handlePeriodicEvents(accountCentral.id)
    }
  }, [accountCentral])

  useEffect(() => {
    const central = centrals?.data[0]

    if (central?.id) {
      const handleSetAccount = async () => {
        const { account, customer } = await getAccount(central?.accountId)

        setValue(
          'account',
          {
            id: account.id,
            aggregatedName: `${account.code} - ${account.name}`,
          },
          { shouldValidate: true },
        )

        setValue(
          'customer',
          {
            id: customer.id,
            name: customer.name,
          },
          { shouldValidate: true },
        )
      }

      handleSetAccount()

      const handleSetCentral = async () => {
        await getDeviceModel(central?.modelId).then(async (data) => {
          if (data?.id) {
            await getManufacturerById(data.manufacturerId).then(
              ({ id, name }) => {
                setValue(
                  'central',
                  {
                    model: { id: data.id, name: data.name },
                    manufacture: { id, name },
                  },
                  {
                    shouldValidate: true,
                  },
                )
              },
            )
          }
        })

        if (central?.gprsModuleModelId) {
          await getDeviceModel(central?.gprsModuleModelId).then(
            async (data) => {
              if (data?.id) {
                await getManufacturerById(data.manufacturerId).then(
                  ({ id, name }) => {
                    setValue(
                      'module',
                      {
                        model: { id: data.id, name: data.name },
                        manufacture: { id, name },
                      },
                      {
                        shouldValidate: true,
                      },
                    )
                  },
                )
              }
            },
          )
        } else {
          setValue('module', undefined, { shouldValidate: true })
        }
      }

      handleSetCentral()
      handlePeriodicEvents(central.id, true)
    }
  }, [centrals])

  return (
    <>
      <ModalV2.Title>Habilitar módulo GPRS/Ethernet</ModalV2.Title>
      <div className={styles.container}>
        <section>
          <Info.Root>
            <Info.Title>Conta da central</Info.Title>
            <Info.Content>{currentCentral.account || '--'}</Info.Content>
          </Info.Root>
          <Info.Root>
            <Info.Title>
              Modelo do equipamento
              <TooltipV2.Root>
                <TooltipV2.Trigger>
                  <Icon name="information" color="element-medium" width={12} />
                </TooltipV2.Trigger>
                <TooltipV2.Content position="bottom">
                  Informação enviada automaticamente pela comunicação entre o
                  equipamento e o sistema. Pode se referir tanto ao modelo da
                  central quanto ao modelo do módulo GPRS.
                </TooltipV2.Content>
              </TooltipV2.Root>
            </Info.Title>
            <Info.Content>
              {currentCentral.model || '--'}
              {!equipmentFound ? ' (Não homologado)' : ''}
            </Info.Content>
          </Info.Root>
          <Info.Root>
            <Info.Title>IP/Porta</Info.Title>
            <Info.Content>{currentCentral.hostAddress || '--'}</Info.Content>
          </Info.Root>
          <Info.Root>
            <Info.Title>Código</Info.Title>
            <Info.Content>
              {currentCentral.macAddress || currentCentral.imei || '--'}
            </Info.Content>
          </Info.Root>
          <Info.Root>
            <Info.Title>Data da comunicação</Info.Title>
            <Info.Content>{dateNow(currentCentral.datetime)}</Info.Content>
          </Info.Root>
        </section>
        <form>
          <FormItem>
            <FormLabel>Cliente</FormLabel>
            <ComboBoxV3.Root valueKey="name" findOptions={getCustomer}>
              {({ unSelectedOptions }) => (
                <>
                  <ComboBoxV3.Field value={customer?.name || ''} />
                  <ComboBoxV3.Group>
                    {unSelectedOptions?.map((option) => (
                      <ComboBoxV3.Option
                        key={option.id}
                        onClick={() => {
                          setValue(
                            'customer',
                            {
                              id: option.id,
                              name: option.name,
                            },
                            {
                              shouldValidate: true,
                            },
                          )

                          if (option.accounts?.length === 1) {
                            const account = option.accounts[0]

                            setValue(
                              'account',
                              {
                                id: account.id,
                                aggregatedName: account.aggregatedName,
                              },
                              {
                                shouldValidate: true,
                              },
                            )
                          } else {
                            setValue('account', undefined, {
                              shouldValidate: true,
                            })
                          }
                        }}
                      >
                        {option.name}
                      </ComboBoxV3.Option>
                    ))}
                  </ComboBoxV3.Group>
                </>
              )}
            </ComboBoxV3.Root>
          </FormItem>
          <FormItem>
            <FormLabel>Conta</FormLabel>
            <ComboBoxV3.Root
              findOptions={handleGetAccounts}
              valueKey="aggregatedAccountName"
            >
              {({ unSelectedOptions }) => (
                <>
                  <ComboBoxV3.Field value={account?.aggregatedName || ''} />
                  <ComboBoxV3.Group>
                    {unSelectedOptions?.map((option) => (
                      <ComboBoxV3.Option
                        key={option.id}
                        onClick={() => {
                          setValue(
                            'account',
                            {
                              id: option.id,
                              aggregatedName: option.aggregatedAccountName,
                            },
                            {
                              shouldValidate: true,
                            },
                          )

                          if (option.customer) {
                            setValue(
                              'customer',
                              {
                                id: option.customer?.id,
                                name: option.customer?.name,
                              },
                              {
                                shouldValidate: true,
                              },
                            )
                          }
                        }}
                      >
                        {option.aggregatedAccountName}
                      </ComboBoxV3.Option>
                    ))}
                  </ComboBoxV3.Group>
                </>
              )}
            </ComboBoxV3.Root>
          </FormItem>

          {centrals?.totalElements ? (
            <div className={styles.alert}>
              <div>
                <Icon name="warning" color="accent-red-default" width={18} />
                <span>
                  Esta central já está cadastrada, porém desabilitada. Reabilite
                  para restaurar a comunicação com o sistema.
                </span>
              </div>
              <ButtonV2
                appearance="tertiary"
                color="red"
                onClick={() => handleSeeCentral()}
              >
                VER CENTRAL
              </ButtonV2>
            </div>
          ) : (
            accountCentral?.id && (
              <div className={styles.warning}>
                <div>
                  <Icon
                    name="warning"
                    color="accent-yellow-default"
                    width={18}
                  />
                  <span>
                    Esta conta já possui uma central cadastrada. Ao confirmar
                    habilitação, ela será substituída.
                  </span>
                </div>
                <ButtonV2
                  appearance="tertiary"
                  color="yellow"
                  onClick={() => handleSeeCentral()}
                >
                  VER CENTRAL
                </ButtonV2>
              </div>
            )
          )}
          {account ? (
            <>
              <FormItem>
                <FormLabel>Fabricante da central</FormLabel>
                <ComboBoxV3.Root
                  findOptions={handleGetManufacturers}
                  valueKey="name"
                >
                  {({ unSelectedOptions }) => (
                    <>
                      <ComboBoxV3.Field
                        value={central?.manufacture?.name || ''}
                        disabled={
                          equipmentFound === 'central' ||
                          !!centrals?.totalElements
                        }
                      />
                      <ComboBoxV3.Group>
                        {unSelectedOptions?.map((option) => (
                          <ComboBoxV3.Option
                            key={option.id}
                            onClick={() => {
                              setValue(
                                'central.manufacture',
                                {
                                  id: option.id,
                                  name: option?.name || '',
                                },
                                {
                                  shouldValidate: true,
                                },
                              )
                            }}
                          >
                            {option.name}
                          </ComboBoxV3.Option>
                        ))}
                      </ComboBoxV3.Group>
                    </>
                  )}
                </ComboBoxV3.Root>
              </FormItem>
              <FormItem>
                <FormLabel>Modelo da central</FormLabel>
                <ComboBoxV3.Root
                  findOptions={handleGetCentralModels}
                  valueKey="name"
                >
                  {({ unSelectedOptions }) => (
                    <>
                      <ComboBoxV3.Field
                        value={central?.model?.name || ''}
                        disabled={
                          equipmentFound === 'central' ||
                          !!centrals?.totalElements
                        }
                      />
                      <ComboBoxV3.Group>
                        {unSelectedOptions?.map((option) => (
                          <ComboBoxV3.Option
                            key={option.id}
                            onClick={async () => {
                              setValue(
                                'central.model',
                                {
                                  id: option.id,
                                  name: option?.name || '',
                                },
                                {
                                  shouldValidate: true,
                                },
                              )

                              const manufacturer = await getManufacturerById(
                                option.manufacturerId,
                              )

                              setValue(
                                'central.manufacture',
                                {
                                  id: option.manufacturerId,
                                  name: manufacturer.name,
                                },
                                { shouldValidate: true },
                              )
                            }}
                          >
                            {option.name}
                          </ComboBoxV3.Option>
                        ))}
                      </ComboBoxV3.Group>
                    </>
                  )}
                </ComboBoxV3.Root>
              </FormItem>
              {(centrals?.totalElements
                ? !!centrals?.data[0]?.gprsModuleModelId
                : equipmentFound !== 'central') && (
                <>
                  <FormItem>
                    <FormLabel>Fabricante do módulo GPRS</FormLabel>
                    <ComboBoxV3.Root
                      findOptions={handleGetManufacturers}
                      valueKey="name"
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            value={module?.manufacture?.name || ''}
                            disabled={
                              equipmentFound === 'gprs' ||
                              !!centrals?.data[0]?.gprsModuleModelId
                            }
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => {
                                  setValue(
                                    'module.manufacture',
                                    {
                                      id: option.id,
                                      name: option?.name || '',
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )
                                }}
                              >
                                {option.name}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>
                  <FormItem>
                    <FormLabel>Modelo do módulo GPRS</FormLabel>
                    <ComboBoxV3.Root
                      findOptions={handleGetModelModels}
                      valueKey="name"
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            value={module?.model?.name || ''}
                            disabled={
                              equipmentFound === 'gprs' ||
                              !!centrals?.data[0]?.gprsModuleModelId
                            }
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={async () => {
                                  setValue(
                                    'module.model',
                                    {
                                      id: option.id,
                                      name: option?.name || '',
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )

                                  const manufacturer =
                                    await getManufacturerById(
                                      option.manufacturerId,
                                    )

                                  setValue(
                                    'module.manufacture',
                                    {
                                      id: option.manufacturerId,
                                      name: manufacturer.name,
                                    },
                                    { shouldValidate: true },
                                  )
                                }}
                              >
                                {option.name}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>
                </>
              )}
              <section>
                <FormItem className={styles.toggle}>
                  <Toggle
                    disabled={periodicEventFound.includes('KEEPALIVE')}
                    checked={!!keepAlive?.type}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setValue('keepAlive.type', 'minutes', {
                          shouldValidate: true,
                        })
                        setValue(
                          'keepAlive.failEventRecurrenceInterval.type',
                          'minutes',
                          {
                            shouldValidate: true,
                          },
                        )
                      } else {
                        setValue('keepAlive', undefined, {
                          shouldValidate: true,
                        })
                      }
                    }}
                  />
                  Habilitar “Keep-Alive”
                </FormItem>
                {keepAlive?.type && (
                  <div className={styles.time}>
                    <div>
                      <FormItem>
                        <FormLabel>
                          Tempo
                          <TooltipV2.Root>
                            <TooltipV2.Trigger>
                              <Icon
                                name="information"
                                color="element-default"
                                width={12}
                              />
                            </TooltipV2.Trigger>
                            <TooltipV2.Content position="bottom">
                              Frequência de verificação da comunicação entre
                              dispositivos conectados.
                            </TooltipV2.Content>
                          </TooltipV2.Root>
                        </FormLabel>
                        <NumberField
                          disabled={periodicEventFound.includes('KEEPALIVE')}
                          {...register('keepAlive.interval')}
                        />
                      </FormItem>
                      <SelectV2.Root valueKey="value" data={timeOptions}>
                        {({ unSelectedOptions }) => (
                          <>
                            <SelectV2.Field
                              disabled={periodicEventFound.includes(
                                'KEEPALIVE',
                              )}
                              value={times[keepAlive.type || 'minutes']}
                            />

                            <SelectV2.Group>
                              {unSelectedOptions?.map((option) => (
                                <SelectV2.Option
                                  key={option.value}
                                  onClick={() => {
                                    setValue('keepAlive.type', option.value, {
                                      shouldValidate: true,
                                    })
                                  }}
                                >
                                  {option.name}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Group>
                          </>
                        )}
                      </SelectV2.Root>
                    </div>
                    <div>
                      <FormItem>
                        <FormLabel>
                          Intervalo de falhas
                          <TooltipV2.Root>
                            <TooltipV2.Trigger>
                              <Icon
                                name="information"
                                color="element-medium"
                                width={12}
                              />
                            </TooltipV2.Trigger>
                            <TooltipV2.Content position="bottom">
                              Tempo definido entre a geração de eventos em caso
                              de falha de comunicação. Ao preencher “0”, o
                              evento será gerado uma única vez.
                            </TooltipV2.Content>
                          </TooltipV2.Root>
                        </FormLabel>
                        <NumberField
                          disabled={periodicEventFound.includes('KEEPALIVE')}
                          {...register(
                            'keepAlive.failEventRecurrenceInterval.time',
                          )}
                        />
                      </FormItem>
                      <SelectV2.Root valueKey="value" data={timeOptions}>
                        {({ unSelectedOptions }) => (
                          <>
                            <SelectV2.Field
                              disabled={periodicEventFound.includes(
                                'KEEPALIVE',
                              )}
                              value={
                                times[
                                  keepAlive.failEventRecurrenceInterval.type ||
                                    'minutes'
                                ]
                              }
                            />

                            <SelectV2.Group>
                              {unSelectedOptions?.map((option) => (
                                <SelectV2.Option
                                  key={option.value}
                                  onClick={() => {
                                    setValue(
                                      'keepAlive.failEventRecurrenceInterval.type',
                                      option.value,
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Group>
                          </>
                        )}
                      </SelectV2.Root>
                    </div>
                  </div>
                )}

                <FormItem className={styles.toggle}>
                  <Toggle
                    disabled={periodicEventFound.includes('AUTOTEST')}
                    checked={!!automaticTest?.type}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setValue('automaticTest.type', 'minutes', {
                          shouldValidate: true,
                        })
                        setValue('automaticTest.tolerance.type', 'minutes', {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('automaticTest', undefined, {
                          shouldValidate: true,
                        })
                      }
                    }}
                  />
                  Habilitar teste automático
                </FormItem>
                {automaticTest?.type && (
                  <div className={styles.time}>
                    <div>
                      <FormItem>
                        <FormLabel>
                          Tempo
                          <TooltipV2.Root>
                            <TooltipV2.Trigger>
                              <Icon
                                name="information"
                                color="element-medium"
                                width={12}
                              />
                            </TooltipV2.Trigger>
                            <TooltipV2.Content position="bottom">
                              Período de espera pelo envio do teste automático.
                              Se esse prazo for ultrapassado, será gerado um
                              evento de falha de comunicação.
                            </TooltipV2.Content>
                          </TooltipV2.Root>
                        </FormLabel>
                        <NumberField
                          disabled={periodicEventFound.includes('AUTOTEST')}
                          {...register('automaticTest.interval')}
                        />
                      </FormItem>
                      <SelectV2.Root valueKey="value" data={timeOptions}>
                        {({ unSelectedOptions }) => (
                          <>
                            <SelectV2.Field
                              disabled={periodicEventFound.includes('AUTOTEST')}
                              value={times[automaticTest?.type || 'minutes']}
                            />

                            <SelectV2.Group>
                              {unSelectedOptions?.map((option) => (
                                <SelectV2.Option
                                  key={option.value}
                                  onClick={() => {
                                    setValue(
                                      'automaticTest.type',
                                      option.value,
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Group>
                          </>
                        )}
                      </SelectV2.Root>
                    </div>
                    <div>
                      <FormItem>
                        <FormLabel>
                          Tolerância
                          <TooltipV2.Root>
                            <TooltipV2.Trigger>
                              <Icon
                                name="information"
                                color="element-medium"
                                width={12}
                              />
                            </TooltipV2.Trigger>
                            <TooltipV2.Content position="bottom">
                              Intervalo programado para que um teste automático
                              seja enviado para o sistema.
                            </TooltipV2.Content>
                          </TooltipV2.Root>
                        </FormLabel>
                        <NumberField
                          disabled={periodicEventFound.includes('AUTOTEST')}
                          {...register('automaticTest.tolerance.time')}
                        />
                      </FormItem>
                      <SelectV2.Root valueKey="value" data={timeOptions}>
                        {({ unSelectedOptions }) => (
                          <>
                            <SelectV2.Field
                              disabled={periodicEventFound.includes('AUTOTEST')}
                              value={
                                times[automaticTest.tolerance.type || 'minutes']
                              }
                            />

                            <SelectV2.Group>
                              {unSelectedOptions?.map((option) => (
                                <SelectV2.Option
                                  key={option.value}
                                  onClick={() => {
                                    setValue(
                                      'automaticTest.tolerance.type',
                                      option.value,
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Group>
                          </>
                        )}
                      </SelectV2.Root>
                    </div>
                  </div>
                )}
              </section>
            </>
          ) : (
            <div className={styles.emptyState}>
              <Icon name="empty-state" width={34} />
              <span>Selecione um cliente e uma conta</span>
              <p>para definir opções personalizadas</p>
            </div>
          )}
        </form>
      </div>
      <ModalV2.Footer>
        <ModalV2.Close>
          <ButtonV2 appearance="tertiary" ref={closeRef}>
            Cancelar
          </ButtonV2>
        </ModalV2.Close>
        {centrals?.totalElements ? (
          <ButtonV2
            color="red"
            onClick={() => onSubmit(watch())}
            disabled={!isValid || isPending || isPendingCentralByAccount}
          >
            Reabilitar
          </ButtonV2>
        ) : accountCentral?.id ? (
          <Popover.Root>
            <Popover.Trigger asChild>
              <ButtonV2
                color="red"
                disabled={!isValid || isPending || isPendingCentralByAccount}
              >
                Habilitar
              </ButtonV2>
            </Popover.Trigger>
            <Popover.Content
              position="top"
              offsetY={-32}
              offsetX={-300}
              className={joinClassNames(styles.popover, styles.arrow)}
            >
              <div>
                <Icon name="warning" color="accent-yellow-default" />
                <span>Substituir central</span>
              </div>
              <p>
                Tem certeza de que deseja substituir o módulo atual? Esta ação
                não poderá ser desfeita.
              </p>
              <Popover.Footer>
                <Popover.Close>
                  <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
                </Popover.Close>
                <ButtonV2
                  color="red"
                  type="submit"
                  disabled={isPending || isPendingCentralByAccount}
                  onClick={() => onSubmit(watch())}
                >
                  Substituir
                </ButtonV2>
              </Popover.Footer>
            </Popover.Content>
          </Popover.Root>
        ) : (
          <ButtonV2
            onClick={() => onSubmit(watch())}
            disabled={!isValid || isPending || isPendingCentralByAccount}
          >
            Habilitar
          </ButtonV2>
        )}
      </ModalV2.Footer>
    </>
  )
}

export default EnableCentral
