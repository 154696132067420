import {
  Button,
  Loader,
  ProtectedButton,
  Table,
  TableRef,
  Tags,
} from 'components'
import { FC, useCallback, useRef, useState } from 'react'
import { CellProps, Column } from 'react-table'
import {
  AggregatedTechnicianSchedulePause,
  Technician,
} from 'services/technicianSchedule/types'

import styles from './styles.module.scss'
import { maskedDateTime } from 'utilities/date'
import { Pagination, Result } from 'services/types'
import { useDebounce, usePermissions, useToast, useToggle } from 'shared/hooks'
import {
  TechnicianSchedulePauseDriver,
  useDeleteTechnicianSchedulePause,
} from 'services/technicianSchedule'
import { useForm } from 'react-hook-form'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useGetUsers } from 'shared/hooks/services'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'
import { ReactComponent as Close } from 'assets/svg/closeCircle.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'

import { Fragment } from 'domains/attendancePolicy/types'
import Form from 'domains/serviceOrders/screens/list/TechnicianSchedulesPause/components/Form'
import { TechnicianScheduleForm } from 'domains/serviceOrders/screens/list/TechnicianSchedulesPause/schemas/technicianScheduleForm'
import { DeleteModal } from 'domains/serviceOrders/screens/list/TechnicianSchedulesPause/components/DeleteModal'
import { currentPeriod } from 'domains/serviceOrders/screens/list/TechnicianSchedulesPause/utils'
import { UbideskPermissions } from 'routes/types'
import { ViewDetailsModal } from 'domains/serviceOrders/screens/list/TechnicianSchedulesPause/components/ViewDetailsModal'

const columns: Column<AggregatedTechnicianSchedulePause>[] = [
  {
    id: 'technician',
    Header: 'Técnico',
    accessor: (technicianSchedule) => technicianSchedule?.technician?.name,
  },

  {
    id: 'coverage',
    Header: 'Perfil de atendimento',
    accessor: (technicianSchedule) => technicianSchedule?.technician,
    Cell: (cell: CellProps<AggregatedTechnicianSchedulePause>) => {
      const technician = cell.value as Technician
      return (
        <Tags
          size="sm"
          className={styles.firstTag}
          keyId={technician?.id}
          keyLabel={technician?.name}
          data={technician?.coverages || []}
        />
      )
    },
  },
  {
    id: 'startDate',
    Header: 'Início',
    accessor: (technicianSchedule) =>
      maskedDateTime(technicianSchedule.startDate),
  },
  {
    id: 'endDate',
    Header: 'Fim',
    accessor: (technicianSchedule) =>
      maskedDateTime(technicianSchedule.endDate),
  },
]

const TechnicianSchedulesPause: FC = () => {
  const [resetPagination, setResetPagination] = useState<boolean>(false)
  const [selectedTechnicianSchedulePause, setSelectedTechnicianSchedulePause] =
    useState<TechnicianScheduleForm | undefined>()

  const loader = useToggle()
  const formModal = useToggle()
  const deleteModal = useToggle()
  const viewDetailsModal = useToggle()

  const { addToast } = useToast()

  const tableRef = useRef<TableRef>(null)

  const form = useForm<{ technician: Fragment }>()

  const { handleSubmit, watch, setValue, register } = form

  const [filter, setFilter] = useState('')
  const handleFilter = useDebounce(setFilter)

  const { hasAccess } = usePermissions()

  const { mutate: remove, isPending: removeIsLoading } =
    useDeleteTechnicianSchedulePause()

  const {
    isError: isErrorTechnicians,
    isFetching: isFetchingTechnicians,
    users: technicians,
    fetchNextUsersPage: fetchNextTechniciansPage,
  } = useGetUsers({ ...(!!filter && { name: filter }) })

  const fetchTechnicianSchedulesPause = useCallback(
    async (pagination: Pagination) => {
      let result: Result<AggregatedTechnicianSchedulePause> = {
        data: [],
        totalElements: 0,
      }
      loader.show()

      const { technician } = watch()

      try {
        const response = await TechnicianSchedulePauseDriver.query({
          ...pagination,
          ...(technician && { technicianId: technician.id }),
        })
        result = {
          data: response.data,
          totalElements: response?.totalElements,
        }
      } finally {
        loader.hide()
        setResetPagination(false)
      }

      return result
    },

    [watch], //eslint-disable-line
  )

  return (
    <div className={styles.container}>
      <Loader isVisible={removeIsLoading} />

      {!!selectedTechnicianSchedulePause && (
        <ViewDetailsModal
          isVisible={viewDetailsModal.isVisible}
          data={selectedTechnicianSchedulePause}
          onClose={() => {
            setSelectedTechnicianSchedulePause(undefined)
            viewDetailsModal.hide()
          }}
        />
      )}

      <DeleteModal
        isVisible={deleteModal.isVisible}
        onSubmit={() => {
          remove(selectedTechnicianSchedulePause?.id || '', {
            onSuccess: () => {
              addToast({
                type: 'success',
                message: 'Bloqueio excluído com sucesso.',
              })
              setSelectedTechnicianSchedulePause(undefined)
              tableRef.current?.handleFetchPage()
              deleteModal.hide()
            },
            onError: () => {
              addToast({
                type: 'alert',
                message: 'Erro ao excluir bloqueio. Tente novamente.',
              })
            },
          })
        }}
        onClose={() => {
          deleteModal.hide()
        }}
      />

      {formModal.isVisible && (
        <Form
          technicianScheduleId={selectedTechnicianSchedulePause?.id}
          data={selectedTechnicianSchedulePause}
          isVisible
          onSave={() => {
            formModal.hide()
            setSelectedTechnicianSchedulePause(undefined)
            tableRef.current?.handleFetchPage()
          }}
          onClose={() => {
            formModal.hide()
            setSelectedTechnicianSchedulePause(undefined)
          }}
        />
      )}

      <span className={styles.title}>Bloqueios de agenda</span>
      <div className={styles.divider} />

      <form
        className={styles.inputsWrapper}
        onSubmit={handleSubmit(() => {
          setResetPagination(true)
          tableRef.current?.handleFetchPage()
        })}
      >
        <div className={styles.innerWrapper}>
          <div className={styles.filtersButtons}>
            <Combobox
              id="technician-input"
              className={styles.technicianFilter}
              label={{
                text: 'Técnico',
              }}
              onSearch={(value) => {
                if (value === '') {
                  setValue('technician', undefined)
                  return
                }

                handleFilter(value)
              }}
              items={parseDataToComboboxV2(
                technicians.map((technician) => ({
                  id: technician.id,
                  name: technician.name,
                })) || [],
                'name',
              )}
              value={watch('technician')?.name || ''}
              {...register('technician')}
              onChange={(selected) => {
                const technician = selected as ComboboxItem<Fragment>

                if (technician) {
                  setValue('technician', technician.value)
                }
              }}
              isLoading={isFetchingTechnicians}
              isError={isErrorTechnicians}
              onEndReached={fetchNextTechniciansPage}
            />

            <Button
              buttonTitle="Pesquisar"
              type="primary"
              width="108px"
              htmlType="submit"
            />

            <ProtectedButton
              id="add-user-button"
              buttonTitle="Novo bloqueio de agenda"
              type="secondary"
              icon={PlusIcon}
              width="235px"
              permissionName={UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE}
              onClick={() => {
                formModal.show()
              }}
            />
          </div>
        </div>
      </form>
      <Table
        innerRef={tableRef}
        columns={columns}
        fetcher={fetchTechnicianSchedulesPause}
        resetPagination={resetPagination}
        paginated
        actions={[
          {
            label: 'Editar',
            Icon: EditIcon,
            handler: (item) => {
              const startTime = [
                new Date(item.startDate).getHours().toString().padStart(2, '0'),
                new Date(item.startDate)
                  .getMinutes()
                  .toString()
                  .padStart(2, '0'),
              ].join(':')

              const endTime = [
                new Date(item.endDate).getHours().toString().padStart(2, '0'),
                new Date(item.endDate).getMinutes().toString().padStart(2, '0'),
              ].join(':')

              setSelectedTechnicianSchedulePause({
                id: item.id,
                reason: item.reason,
                technician: {
                  id: item.technician.id,
                  name: item.technician.name,
                },
                startDate: item.startDate,
                endDate: item.endDate,
                note: item.note || '',
                ...(startTime !== '00:00' && {
                  startTime,
                }),
                ...(endTime !== '23:59' && {
                  endTime,
                }),
              })

              formModal.show()
            },
            isVisible: () =>
              hasAccess(UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE),
          },
          {
            label: 'Encerrar bloqueio',
            Icon: Close,
            handler: (item) => {
              setSelectedTechnicianSchedulePause(item)

              deleteModal.show()
            },
            isVisible: ({ startDate, endDate }) =>
              currentPeriod(startDate, endDate) &&
              hasAccess(UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE),
          },
          {
            label: 'Excluir bloqueio',
            Icon: TrashIcon,
            handler: (item) => {
              setSelectedTechnicianSchedulePause(item)

              deleteModal.show()
            },

            isVisible: () =>
              hasAccess(UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE),
          },
          {
            label: 'Visualizar',
            Icon: InspectIcon,
            handler: (item) => {
              setSelectedTechnicianSchedulePause(item)
              viewDetailsModal.show()
            },
            isVisible: () =>
              !hasAccess(UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE),
          },
        ]}
      />
    </div>
  )
}

export default TechnicianSchedulesPause
