import { FC, useCallback, useRef, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import { ReactComponent as Delete } from 'assets/svg/delete.svg'

import { useToast, useUserInfo } from 'shared/hooks'
import { Button, Icon, Modal } from 'components'
import { useSendImage } from 'services/attendance'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'

interface FormProps {
  note: string
  images: File[]
}

export const schema = Joi.object<FormProps>({
  note: Joi.string().optional(),
  images: Joi.array().min(1).required(),
})

const ImageUploader: FC = () => {
  const { occurrenceId } = useParams()
  const { addToast } = useToast()
  const { handleCloseModal } = useModal()
  const { userInfo } = useUserInfo()
  const { mutate, isPending } = useSendImage(occurrenceId)
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
  })

  const [dragging, setDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (event.dataTransfer && event.dataTransfer.files.length) {
        const currentValue = watch('images') || []

        setValue(
          'images',
          [...currentValue, ...Array.from(event.dataTransfer.files)],
          { shouldValidate: true },
        )
      }

      setDragging(false)
    },
    [setValue, watch],
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setDragging(true)
    },
    [],
  )

  const handleRemoveImage = useCallback(
    (index: number) => {
      const newImages = watch('images')

      newImages.splice(index, 1)
      setValue('images', newImages)
    },
    [setValue, watch],
  )

  const onSubmit = useCallback(
    ({ images, note }: FormProps) => {
      if (occurrenceId) {
        mutate(
          {
            images,
            principalOwner: {
              id: occurrenceId,
              name: 'Occurrence',
            },
            owners: [
              {
                id: userInfo.id,
                name: 'Attendant',
              },
            ],
            note,
          },
          {
            onSuccess: handleCloseModal,
            onError: () =>
              addToast({
                message:
                  'Erro ao criar intervenção. Verifique a extensão das imagens! Apenas as opções jpeg, jpg e png são permitidas.',
                error: true,
                type: 'alert',
              }),
          },
        )
      }
    },
    [addToast, occurrenceId, handleCloseModal, mutate],
  )

  return (
    <Modal
      isVisible
      size="md"
      onClose={handleCloseModal}
      className={styles.container}
      title="Adicionar foto à ocorrência"
    >
      <form className={styles.modal} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainContent}>
          <div
            className={[styles.dropZone, dragging && styles.dragging]
              .filter(Boolean)
              .join(' ')}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={() => setDragging(false)}
          >
            <input
              multiple
              id="file"
              type="file"
              {...register('images')}
              ref={fileInputRef}
              accept="image/jpeg, image/png"
              className={styles.hiddenButton}
              onChange={(event) => {
                if (event.target?.files?.length) {
                  const currentValue = watch('images') || []

                  setValue(
                    'images',
                    [...currentValue, ...Array.from(event.target.files || [])],
                    { shouldValidate: true },
                  )
                }
              }}
            />
            <div className={styles.dropZoneContent}>
              <Icon name="gallery-add" color="background-default" width={42} />
              <p>
                <span onClick={() => fileInputRef.current?.click()}>
                  Selecione
                </span>{' '}
                ou arraste as imagens para adicionar à galeria da ocorrência
              </p>
            </div>
          </div>
          <div className={styles.imagesWrapper}>
            <p>Arquivos enviados</p>
            <ul className={styles.imagesList}>
              {watch('images')?.map((image, index) => (
                <li key={index}>
                  <img alt="" src={URL.createObjectURL(image)} width={100} />
                  <div className={styles.imageDetails}>
                    <div>
                      <span>{image.name}</span>
                      <button
                        type="button"
                        className={styles.imageAction}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <Delete />
                        Remover
                      </button>
                    </div>
                    <div />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.observation}>
          <label htmlFor="observation">Observações</label>
          <textarea
            rows={10}
            maxLength={255}
            {...register('note')}
            className={styles.textArea}
            onChange={(event) =>
              setValue('note', event.target.value, { shouldValidate: true })
            }
          />
        </div>

        <Modal.Footer>
          <Button
            type="secondary"
            buttonTitle="Cancelar"
            onClick={handleCloseModal}
          />
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValid || isPending}
            buttonTitle="Salvar"
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ImageUploader
