import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

import { Loading, ServiceOrderForm } from '../../components'
import { useToast, useUserInfo } from 'shared/hooks'

import {
  ServiceOrderPayload,
  useGetSO,
  usePutServiceOrder,
} from 'services/serviceOrderV2'
import styles from './styles.module.scss'
import { handleRenderState } from 'utilities/handleRenderState'
import { ErrorState } from 'components'
import EmptyState from 'components/EmptyStateV2'

const Update = () => {
  const { serviceOrderId } = useParams()
  const { addToast } = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const { userInfo } = useUserInfo()
  const {
    mutate,
    isPending: isPendingPutSO,
    isError: isGetSOError,
  } = usePutServiceOrder(serviceOrderId)
  const {
    data: serviceOrder,
    isPending: isPendingGetSO,
    isError: isPutSOError,
  } = useGetSO(serviceOrderId)

  const onSubmit = useCallback(
    (data: ServiceOrderPayload) => {
      if (serviceOrderId) {
        mutate(
          { ...data, id: serviceOrderId, userId: userInfo?.id },
          {
            onSuccess: () => {
              addToast({ message: 'Ordem de serviço editada com sucesso.' })

              if (location.state?.shouldReturnToSOInfo) {
                return navigate(`/so/${data.id}`)
              }

              navigate(-1)
            },
            onError: () => {
              addToast({
                type: 'alert',
                message:
                  'Não foi possível editar a ordem de serviço. Tente novamente',
              })
            },
          },
        )
      }
    },
    [addToast, location, navigate],
  )

  return (
    <main className={styles.main}>
      <div className={styles.title}>
        <span>Editar ordem de serviço</span>
        <hr />
      </div>
      {
        {
          view: (
            <ServiceOrderForm onSubmit={onSubmit} serviceOrder={serviceOrder} />
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyState />,
        }[
          handleRenderState({
            data: serviceOrder,
            isError: isGetSOError || isPutSOError,
            isLoading: isPendingGetSO || isPendingPutSO,
          })
        ]
      }
    </main>
  )
}

export default Update
