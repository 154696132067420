import { useForm } from 'react-hook-form'
import { jwtDecode } from 'jwt-decode'
import * as Joi from '@hapi/joi'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  Icon,
  ModalV2,
  ButtonV2,
  FormItem,
  FormLabel,
  TextField,
  TextareaV2,
} from 'components'

import {
  AccessToken,
  LoginRequest,
  useGetPermissions,
  useLogin,
} from 'services/auth'
import {
  AccountFragment,
  SERVICE_ORDER_STATUS_ENUM,
} from 'services/serviceOrder'
import { useCreateIntervention, useDefaultMode } from 'services'
import { CENTRAL_MODE } from 'services/central/types'
import { useQueryCentralByAccounts } from 'services/central'
import { formatDecimal } from 'utilities/date'
import { UbideskPermissions } from 'routes/types'

import { useToast, useUserInfo } from '../../../../shared/hooks'

import styles from './styles.module.scss'

export type ServiceOrderFragment = {
  id: string
  number: number
  account?: AccountFragment
  status: SERVICE_ORDER_STATUS_ENUM
  technicianId?: string
  inProgressAt?: boolean
  [key: string]: unknown
}

type Form = LoginRequest & { note: string }

export const schema = Joi.object<Form>({
  note: Joi.string().required(),
  login: Joi.string().required(),
  password: Joi.string().required(),
})

export type CancelServiceOrderProps = {
  isVisible: boolean
  onClose: () => void
  serviceOrder: ServiceOrderFragment
}

export const CancelServiceOrder = ({
  isVisible,
  onClose,
  serviceOrder,
}: CancelServiceOrderProps) => {
  const { userInfo } = useUserInfo()

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<Form>({
    defaultValues: {
      login: userInfo.username,
    },
    resolver: joiResolver(schema),
  })

  const { addToast } = useToast()

  const { data: central, isLoading } = useQueryCentralByAccounts(
    [serviceOrder.account?.id || ''].filter(Boolean),
  )
  const { mutate: loginFn, isPending } = useLogin()
  const { mutate: mutateDefaultMode, isPending: defaultModeIsPending } =
    useDefaultMode(central?.data[0]?.id)

  const { mutate: getPermissions, isPending: permissionIsPending } =
    useGetPermissions()

  const handleOnCancel = () => {
    reset({})
    onClose()
  }

  const { mutate: createIntervention } = useCreateIntervention(serviceOrder?.id)

  const handleUpdateStatus = async (note: string) => {
    const userId = userInfo.id
    createIntervention(
      {
        typeName: 'STATUS_CHANGE',
        note: {
          note,
          hidden: false,
          userId,
        },
        attributes: {
          status: 'CANCELED',
          technicianId: serviceOrder?.technicianId,
        },
        userId,
      },
      {
        onSuccess: () => {
          addToast({
            type: 'success',
            message: `Ordem de serviço #${formatDecimal(
              serviceOrder.number,
            )} cancelada com sucesso.`,
          })
          handleOnCancel()
        },
        onError: () =>
          addToast({
            type: 'alert',
            message: 'Erro ao cancelar ordem de serviço. Tente novamente',
          }),
      },
    )
  }

  const handlePutCentralInDefaultMode = async (note: string) => {
    const currentCentral = central?.data[0]

    if (currentCentral?.id) {
      if (
        serviceOrder.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS &&
        currentCentral.mode === CENTRAL_MODE.MAINTENANCE
      ) {
        mutateDefaultMode(
          { userId: userInfo.id },
          {
            onSuccess: async () => await handleUpdateStatus(note),
            onError: () => {
              addToast({
                type: 'alert',
                message:
                  'Erro ao cancelar ordem de serviço e retirar a central do modo manutenção. Tente novamente.',
              })
            },
          },
        )
      } else {
        handleUpdateStatus(note)
      }
    } else {
      handleUpdateStatus(note)
    }
  }

  const handleOnSubmit = ({ login, password, note }: Form) => {
    loginFn(
      {
        login,
        password,
      },
      {
        onSuccess: (data) => {
          if (serviceOrder.inProgressAt) {
            const {
              user: { id: userId },
            } = jwtDecode(data.access_token) as AccessToken

            getPermissions(userId, {
              onSuccess: async (permissions) => {
                const permission = UbideskPermissions.CANCEL_EXECUTED_SO

                const hasAccess = () => {
                  if (permissions) {
                    if (permission in permissions) {
                      return permissions[permission]
                    }
                  }

                  return false
                }

                if (hasAccess()) {
                  handlePutCentralInDefaultMode(note)
                } else {
                  addToast({
                    message:
                      'Esse usuário não tem permissão para realizar essa ação.',
                    type: 'alert',
                  })
                }
              },
              onError: () => {
                addToast({
                  message:
                    'Erro ao verificar permissões do usuário. Tente novamente.',
                  type: 'alert',
                })
              },
            })
          } else {
            handlePutCentralInDefaultMode(note)
          }
        },
        onError: (error) => {
          if (error.message.includes('401')) {
            addToast({
              message: 'Usuário ou senha inválidos. Tente novamente.',
              type: 'alert',
            })
          } else {
            addToast({
              message:
                'Não foi possível realizar a ação devido a um erro em nossos servidores. Tente novamente.',
              type: 'alert',
            })
          }
        },
      },
    )
  }

  return (
    <ModalV2.Root isOpen={isVisible}>
      <ModalV2.Content>
        <ModalV2.Title>
          <Icon name="warning" color="accent-yellow-default" width={20} />
          {`Cancelar ordem de serviço #${serviceOrder.number}`}
        </ModalV2.Title>
        <p>
          Entre com um usuário autorizado para confirmar o cancelamento. Esta
          ação não poderá ser desfeita.
        </p>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className={styles.container}
        >
          <FormItem>
            <FormLabel>Usuário</FormLabel>
            <TextField
              placeholder="Usuário"
              {...register('login')}
              autoComplete="username"
            />
          </FormItem>
          <FormItem>
            <FormLabel>Senha</FormLabel>
            <TextField
              type="password"
              placeholder="••••••••"
              {...register('password')}
              autoComplete="current-password"
            />
          </FormItem>

          <FormItem>
            <FormLabel>Observações</FormLabel>
            <TextareaV2
              rows={5}
              {...register('note')}
              autoComplete="current-password"
            />
          </FormItem>

          <ModalV2.Footer>
            <ButtonV2 appearance="tertiary" onClick={handleOnCancel}>
              Cancelar
            </ButtonV2>
            <ButtonV2
              type="submit"
              color="red"
              disabled={
                !isValid ||
                isPending ||
                permissionIsPending ||
                isLoading ||
                defaultModeIsPending
              }
            >
              Cancelar
            </ButtonV2>
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}
