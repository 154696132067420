import { ButtonV2, Drawer, Icon, IconButton } from 'components'
import Modal from 'components/ModalV2'
import { useToggle } from 'shared/hooks'
import { Content } from './components'
import styles from './styles.module.scss'
import { Intervention } from 'services'

type PreviousVersionsProps = {
  triggerTitle: string
  intervention?: Intervention
}

const PreviousVersions = ({
  triggerTitle,
  intervention,
}: PreviousVersionsProps) => {
  const modalTrigger = useToggle()

  return (
    <>
      <Drawer.Root>
        <div className={styles.trigger}>
          <Drawer.Trigger>
            <ButtonV2 appearance="tertiary">
              <Icon name="history" />
              {triggerTitle}
            </ButtonV2>
          </Drawer.Trigger>
        </div>

        <Drawer.Content position="right">
          <div className={styles.drawer}>
            <Drawer.Title>
              Versões anteriores
              <span>
                <Drawer.Close onClick={modalTrigger.show}>
                  <IconButton>
                    <Icon name="arrow-expand" color="element-default" />
                  </IconButton>
                </Drawer.Close>
                <Drawer.Close />
              </span>
            </Drawer.Title>
            <Content context="drawer" intervention={intervention} />
          </div>
        </Drawer.Content>
      </Drawer.Root>

      <Modal.Root isOpen={modalTrigger.isVisible}>
        <Modal.Content size="lg">
          <div className={styles.modal}>
            <Modal.Title className={styles.title}>
              Versões anteriores
              <Modal.Close onClick={modalTrigger.hide} />
            </Modal.Title>
            <Content intervention={intervention} />
          </div>
        </Modal.Content>
      </Modal.Root>
    </>
  )
}

export default PreviousVersions
