import { ComponentProps, forwardRef } from 'react'
import styles from '../field.module.scss'

const Textarea = forwardRef<HTMLTextAreaElement, ComponentProps<'textarea'>>(
  ({ className, autoComplete = 'off', ...props }, ref) => (
    <textarea
      {...props}
      ref={ref}
      autoComplete={autoComplete}
      className={[styles.field, className].join(' ')}
    />
  ),
)

Textarea.displayName = 'Textarea'

export default Textarea
