import { useCallback, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { Coverage, PARAMETERS_POLICY_EN_PT } from '../../types'

import { CoverageList } from '../CoverageList/CoverageList'
import { AutomationList } from '../AutomationList/AutomationList'
import { Combobox } from 'components/ComboboxV2/Combobox'
import {
  Breadcrumbs,
  Button,
  Checkbox,
  ComboboxItem,
  ContainerScreen,
  Icon,
  Input,
  RadioGroup,
} from 'components'

import { RadioButton } from 'components/RadioButton/RadioButton'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ServiceType } from 'services/serviceType/types'
import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { AggregatedAttendPolicyResponse } from 'services/attendancePolicy/types'

import { useGetOccurrenceType } from 'shared/hooks/services/occurrence/useGetOccurrenceType'
import { OccurrenceTypeQueryResponse } from 'services/occurrence/types'

import { formKeys } from 'domains/attendancePolicy/components/AttendPolicyFormGroup/types'

import {
  removeParentheses,
  parametersKeys,
} from 'domains/attendancePolicy/components/AttendancePolicyBaseForm/utilities'

import { CustomerPaths } from 'routes/constants/paths/customer'
import { usePersistentTab } from 'domains/occurrence/hooks'

import styles from './AttendancePolicyBaseForm.module.scss'
import { PatrimonyDriver } from 'services/patrimony'

interface AttendancePolicyFormProps {
  goToForm: (formKey: formKeys, ruleIndex?: string) => void
  onGoBack: () => void
}

export const AttendancePolicyBaseForm = ({
  onGoBack,
  goToForm,
}: AttendancePolicyFormProps) => {
  const { setValue, watch, formState, register } =
    useFormContext<AggregatedAttendPolicyResponse>()

  const attendPolicyId = watch('id')
  const name = watch('name')
  const active = watch('active')
  const parameters = watch('parameters')
  const serviceType = watch('serviceType')
  const occurrenceType = watch('occurrenceType')
  const occurrencePriority = watch('occurrencePriority')
  const coverages = watch('coverages')
  const rules = watch('rules')

  const [isAdvancedSettings, setIsAdvancedSettings] = useState(() => {
    const hasCoverage = Object.values(coverages).some((coverage) =>
      Boolean(coverage.length),
    )
    return hasCoverage
  })

  const [serviceTypeFilter, setServiceTypeFilter] = useState('')

  const {
    serviceTypes,
    isFetching: isFetchingServiceTypes,
    fetchNextServiceTypesPage,
  } = useGetServiceType(serviceTypeFilter)

  const {
    occurrenceTypes,
    isFetching: isFetchingOccurrenceTypes,
    fetchNextOccurrenceTypesPage,
  } = useGetOccurrenceType()

  const { replaceUrl } = usePersistentTab('attendance-policy-account-tab')

  const checkParameter = useCallback(
    (parameter: PARAMETERS_POLICY_EN_PT) => {
      if (!parameters) {
        return false
      }

      const parameterKey = parametersKeys[parameter]
      const isSelected = parameters[parameterKey]

      return isSelected
    },
    [parameters],
  )

  const handleDeleteAutomation = useCallback(
    (index: number) => {
      const newRules = rules.filter((rule) => rule.index !== index)

      setValue(
        'rules',
        newRules.map((item, index) => ({ ...item, index: index + 1 })),
      )
    },
    [rules, setValue],
  )

  const handleCopyAutomation = useCallback(
    (index: string) => {
      const copiedRule = rules.find((rule) => rule.index === Number(index))

      if (!copiedRule) return

      const quantityCopied = rules.filter(
        (rule) =>
          removeParentheses(rule.name) === removeParentheses(copiedRule.name),
      ).length

      setValue('rules', [
        ...rules,
        {
          ...copiedRule,
          index: rules.length + 1,
          name: `${removeParentheses(copiedRule.name)} (${quantityCopied})`,
        },
      ])
    },
    [rules, setValue],
  )

  const onInspectAccount = async (coverage: Coverage) => {
    let accountId = ''
    if (coverage.type === 'Conta') {
      accountId = coverage.precedence.account?.id || "'"
    } else {
      accountId = await PatrimonyDriver.queryPatrimoniesFragmentList(
        coverage.precedence.customer?.id || '',
      ).then((patrimony) => patrimony.data[0].accountId)
    }

    replaceUrl(CustomerPaths('account', accountId))
  }

  return (
    <ContainerScreen
      clickable
      divider={false}
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Configurações', href: '/' },
            { title: 'Nova regra', href: '#' },
          ]}
        />
      }
    >
      <div className={styles.headerSeparator} />
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <h1>
            {attendPolicyId
              ? 'Regra de atendimento'
              : 'Nova regra de atendimento'}
          </h1>
          <p>
            Permite a realização automática de fluxos de ocorrências,
            atualização de status e execução de ações em resposta a eventos
            específicos por meio de regras pré-configuradas
          </p>
        </div>
        <div className={styles.formWrapper}>
          <section
            className={[
              styles.firstSection,
              attendPolicyId && styles.firstSectionWithStatus,
            ].join(' ')}
          >
            {attendPolicyId && (
              <div className={styles.statusOptions}>
                <RadioGroup title="Situação">
                  <RadioButton
                    checked={Boolean(active)}
                    name="status"
                    onChange={() => setValue('active', true)}
                    value="Ativa"
                  />
                  <RadioButton
                    checked={!active}
                    name="status"
                    onChange={() => setValue('active', false)}
                    value="Inativa"
                  />
                </RadioGroup>
              </div>
            )}
            <Input
              label="Nome"
              placeholder="Digite o nome da regra"
              id="policy-name"
              name="policy-name"
              autoComplete="off"
              value={name || ''}
              onChange={(event) => {
                setValue('name', event.target.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }}
              maxLength={60}
            />
            <div>
              <p className={styles.checkboxContentLabel}>Parâmetros</p>
              <div className={styles.checkboxItems}>
                {Object.values(PARAMETERS_POLICY_EN_PT).map((parameter) => {
                  return (
                    <Checkbox
                      key={parameter}
                      id={parameter}
                      checked={checkParameter(parameter)}
                      label={parameter}
                      small
                      onChange={(event) => {
                        const checked = event.target.checked
                        const parameterKey = parametersKeys[parameter]

                        setValue(
                          'parameters',
                          {
                            ...parameters,
                            [parameterKey]: checked,
                          },
                          { shouldValidate: true },
                        )
                      }}
                    />
                  )
                })}
              </div>
            </div>

            <Combobox
              id="serviceType"
              label={{ text: 'Tipo de serviço' }}
              value={
                serviceType ? { label: 'name', value: serviceType } : undefined
              }
              items={parseDataToComboboxV2(
                serviceTypes?.map((serviceType) => ({
                  id: serviceType.id,
                  name: serviceType.name,
                })) || [],
                'name',
              )}
              onChange={(selected) => {
                const selectedServiceType = selected as ComboboxItem<
                  Pick<ServiceType, 'id' | 'name'>
                >
                if (selectedServiceType) {
                  setValue('serviceType', selectedServiceType?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              }}
              isLoading={isFetchingServiceTypes}
              onEndReached={fetchNextServiceTypesPage}
              onSearch={(search) => setServiceTypeFilter(search)}
            />
            <Combobox
              id="occurrenceType"
              label={{ text: 'Tipo de ocorrência' }}
              value={
                occurrenceType
                  ? { label: 'name', value: occurrenceType }
                  : undefined
              }
              items={parseDataToComboboxV2(
                occurrenceTypes?.map((coverageType) => ({
                  id: coverageType.id,
                  name: coverageType.name,
                })) || [],
                'name',
              )}
              onChange={(selected) => {
                const selectedOccurrenceType = selected as ComboboxItem<
                  Pick<OccurrenceTypeQueryResponse, 'id' | 'name'>
                >
                setValue('occurrenceType', selectedOccurrenceType?.value)
              }}
              isLoading={isFetchingOccurrenceTypes}
              onEndReached={fetchNextOccurrenceTypesPage}
            />

            <Input
              type="number"
              id="occurrencePriority"
              name="occurrencePriority"
              wrapperClassName={styles.triggerTimeInput}
              value={String(occurrencePriority) || '0'}
              min={0}
              max={1000}
              onChange={(input) => {
                setValue('occurrencePriority', Number(input.target.value))
              }}
              label="Prioridade de atendimento"
            />

            <Input
              {...register('occurrenceTitle')}
              label="Título identificador"
              placeholder="Digite um nome padrão para a ocorrência"
              id="occurrenceTitle"
              name="occurrenceTitle"
              wrapperClassName={styles.triggerTimeInput}
              value={watch('occurrenceTitle')}
              maxLength={45}
              onChange={(input) => {
                setValue('occurrenceTitle', input.target.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }}
            />
          </section>
          <section className={styles.secondSection}>
            <p>Lista de automações</p>
            <AutomationList
              items={rules.sort((prev, next) => prev.index - next.index)}
              onAddNewAutomation={() => goToForm('RULE')}
              onEdit={({ id }) => {
                goToForm('RULE', id)
              }}
              onCopy={({ id }) => handleCopyAutomation(id)}
              onDelete={({ index }) => handleDeleteAutomation(index)}
            />
            <div className={styles.advancedSettings}>
              <Button
                buttonTitle="Configurações avançadas"
                type="tertiary"
                onClick={() => setIsAdvancedSettings(!isAdvancedSettings)}
              >
                <Icon
                  name="arrow-down"
                  className={[
                    styles.arrow,
                    isAdvancedSettings && styles.upsideArrow,
                  ].join(' ')}
                />
              </Button>
            </div>

            {isAdvancedSettings ? (
              <div>
                <p>Lista de abrangências</p>
                <CoverageList
                  coverageGrouper={coverages}
                  onDeleteAll={() =>
                    setValue('coverages', {
                      customers: [],
                      accounts: [],
                    })
                  }
                  onAddNewCoverage={() => goToForm('COVERAGE')}
                  onInspectAccount={onInspectAccount}
                />
              </div>
            ) : null}
          </section>
        </div>

        <div className={styles.footer}>
          <Button
            buttonTitle="Voltar"
            onClick={onGoBack}
            type="secondary"
            width="172px"
          />
          <Button
            buttonTitle="Salvar"
            type="primary"
            htmlType="submit"
            disabled={!formState.isValid}
            width="172px"
          />
        </div>
      </div>
    </ContainerScreen>
  )
}
