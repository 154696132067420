import { ContactResponse } from 'domains/customer/components/CentralForm/types'
import { ActionPlan } from 'services/attendance/types'
import { PhoneResponse } from 'services/contact/types'
import { PartitionStatus } from 'services/patrimony/types'
import { ConnectionIPMode } from 'services/serviceOrder'
import { Result } from 'services/types'

export enum CENTRAL_MODE {
  'DEFAULT' = 1,
  'MAINTENANCE' = 2,
  'STANDBY' = 3,
}

export const timeOption = {
  hours: 'Horas',
  minutes: 'Minutos',
}
export type TimeOption = keyof typeof timeOption

export type RemoteControls = {
  id: string
  name: string
}

export type PeriodicEventControlDeactivateRequest = {
  reactivationDateTime?: number
  disabledByUser: string
  disableReason: string
}

export type PartitionContacts = {
  id: string
  name: string
  type: string
  remoteControls?: RemoteControls[]
  email?: string
  phone?: PhoneResponse
}

export type PartitionFragment = {
  id: string
  code: string
  name: string
  deletionWarning?: {
    linkedDevices?: boolean
    defaultPartition?: boolean
  }
  status: PartitionStatus
  actionPlan?: ActionPlan[]
  contacts?: PartitionContacts[]
  externalId?: string
  keyNumber?: string
}

export type CentralAggregatedResponse = {
  id: string
  connected: boolean
  lastConnectionTimestamp: number
  code: string
  powerStatus?: number
  batteryLevel?: number
  tamper: boolean
  demo: boolean
  mode?: CENTRAL_MODE
  active?: boolean
  installationImageId?: string
  hasCommandSupport: boolean
  isUbisafe: boolean
  externalId?: string
  topicGroup?: string
  aggregatedAccountName?: string
  serviceType?: {
    id: string
    name: string
  }
  manufacturer?: {
    id: string
    name: string
  }
  model?: {
    id: string
    name: string
  }
  safeCall?: {
    enabled: boolean
    delay: number
    number: number
  }
  versions: {
    firmware?: string
    software?: string
    centralCore?: string
  }
  ethernet?: {
    connectionMode: number
    connectionStatus: boolean
    ipMode: ConnectionIPMode
    ip: string
  }
  wifi?: {
    connectionStatus: boolean
    signal: number
    ipMode: ConnectionIPMode
    ip: string
    ssid: string
  }
  gprs?: {
    connectionStatus: boolean
    telecom: string
    iccid: string
    signal: number
    moduleNumber?: string
  }
  partitions?: PartitionFragment[]
  createdAt: number
  centralUsers?: ContactResponse[]
  gprsModuleModelId?: string
}

export type CentralPartition = {
  id: string
  name: string
  code: string
  status: PartitionStatus
}

export type PartitionFragmentPayload = {
  id: string
  name: string
  code: string
  keyNumber?: string
  status?: PartitionStatus
  email?: string
  phone?: {
    provinceCode: string
    number: number
  }
  externalId?: string
}

export type CentralContactList = {
  id?: string
  contactId?: string
  code: string
  name?: string
  partitionIds?: string[]
}

export type Livecycle = 'ALPHA' | 'BETA' | 'RELEASE'

export enum CentralStatus {
  Available = 'AVAILABLE',
  Installed = 'INSTALLED',
  Uninstalled = 'UNINSTALLED',
}

type Manufacturer = {
  id: string
  name: string
}

export type CentralResponse = {
  id: string
  code?: string
  name: string
  model: {
    id: string
    name: string
    isUbisafe: boolean
    hasCommandSupport: boolean
    isUbisafe?: boolean
  }
  manufacturer: Manufacturer
  gprsModuleManufacturer: Manufacturer
  account: {
    id: string
    name: string
    aggregatedAccountName: string
  }
  modelDescription?: string
  gprsModuleModel: {
    id: string
    name: string
  }
  active?: boolean
  externalId?: string
  versionCode: number
  topicGroup?: string
  connected: boolean
  livecycle: Livecycle
  mode: number
  status: CentralStatus
  lastConnectStatus: number
  accountId?: string
  publicKey?: string
  privateKey?: string
  certificatePem?: string
  createdAt: number
  updatedAt: number
  partitions: PartitionFragmentPayload[]
  installationImageId?: string
}

export type CentralPayload = {
  id?: string
  code?: string
  active: boolean
  modelId: string
  commandPassword?: string
  modelDescription?: string
  gprsModuleModelId?: string
  externalId?: string
  versionCode: number
  topicGroup?: string
}

export const periodicEventControlType = {
  KEEPALIVE: 'KEEPALIVE',
  AUTOTEST: 'AUTOTEST',
}

export type PeriodicEventControlType = keyof typeof periodicEventControlType

export type Monitoring = {
  id?: string
  active?: boolean
  interval?: number
  tolerance?: number
  type?: PeriodicEventControlType
  failEventRecurrenceInterval?: number
}

export type CreateCentralPayload = {
  accountId?: string
  central: CentralPayload
  monitoring?: {
    autoTest: Monitoring
    keepAlive: Monitoring
  }
  partitions: Omit<PartitionFragmentPayload, 'phone' | 'email'>[]
  image?: File
}

export type UpdateCentralPayload = {
  contacts: CentralContactList[]
} & Omit<CreateCentralPayload, 'accountId'>

export interface PeriodicEventControl {
  id: string
  type: PeriodicEventControlType
  tolerance: number
  interval: number
  centralId: string
  failEventRecurrenceInterval: number
  reactivationDateTime?: number
  disabledBy?: { id: string; name: string }
  disableReason?: string
  active: boolean
  createdAt: number
  updatedAt: number
}

export type CentralFindByIdResult = {
  central: CentralResponse & {
    installationImageId: string
    commandPassword?: string
  }
  monitoring?: {
    keepAlive: PeriodicEventControl
    autoTest: PeriodicEventControl
  }
  contacts: CentralContactList[]
  partitions: PartitionFragment[]
}

export declare type Query = {
  code?: string
  name?: string
  versionCode?: number
  accountId?: string
  modelId?: string
  livecycle?: Livecycle
  mode?: number
  externalId?: string
  active?: boolean
  modemFw?: string
  modemModel?: string
  modemRevision?: string
  createdFrom?: number
  createdTo?: number
  updatedFrom?: number
  updatedTo?: number
  recordsPerPage?: number
  offset?: number
  sort?: string
  sortType?: string
}

export type QueryResponse = Result<{
  id: string
  connected: boolean
  mode: number
  status: CentralStatus
  accountId?: string
  code: string
  name: string
  modelId?: string
  modelDescription?: string
  gprsModuleModelId?: string
  externalId?: string
  active?: boolean
  activeDatetime?: number
  commandPassword?: string
}>
