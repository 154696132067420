import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { dateNow } from 'utilities/date'
import { useGetImageFile } from 'services/image'
import {
  useDeleteNote,
  InterventionNote,
  Intervention,
} from 'services/serviceOrderV2'
import { useToast } from 'shared/hooks'
import joinClassNames from 'utilities/joinClassNames'
import { ButtonV2, Icon, IconButton, Tag, TooltipV2 } from 'components'
import { useModal } from 'domains/serviceOrder/screens/Details/context'

import { Edit } from './components'
import styles from './styles.module.scss'
import { handleDownload } from '../../../../utils'
import UserVerification from '../../../UserVerification'

type NoteProps = {
  intervention: Intervention
  note: InterventionNote
}

const Note = ({ note, intervention }: NoteProps) => {
  const { serviceOrderId } = useParams()

  const { addToast } = useToast()
  const { handleOpenModal, handleCloseModal } = useModal()
  const [renderState, setRenderState] = useState<'remove' | 'edit' | null>(null)

  const { mutate } = useDeleteNote(serviceOrderId, intervention.id, note.id)

  const { data: images } = useGetImageFile(note.images)

  const handleDelete = () => {
    mutate(null, {
      onSuccess: () => {
        addToast({
          message: 'Nota excluída com sucesso.',
          type: 'success',
        })

        setRenderState(null)
      },

      onError: () => {
        addToast({
          message: 'Erro ao excluir nota. Tente novamente.',
          type: 'alert',
        })
      },
    })
  }

  return (
    <li
      className={joinClassNames(styles.container, note.hidden && styles.hidden)}
    >
      <div className={styles.title}>
        <div>
          <Icon name="reply" color="element-default" width={10} />
          <Icon
            name={note.hidden ? 'hide' : 'view'}
            color="element-bold"
            width={10}
          />
          <span>
            <strong>{note.user.name}</strong>
            <small> - {dateNow(note.datetime)}</small>
          </span>
          {note?.updatedBy && (
            <>
              <i>(Editada)</i>
              <TooltipV2.Root>
                <TooltipV2.Trigger>
                  <Icon name="information" width={10} color="element-medium" />
                </TooltipV2.Trigger>
                <TooltipV2.Content position="bottom" size="lg">
                  <span>
                    Nota editada por {note.updatedBy.name} e autorizada por{' '}
                    {note.authorizedBy?.name}
                  </span>
                </TooltipV2.Content>
              </TooltipV2.Root>
            </>
          )}
        </div>
        <div>
          <IconButton>
            <Icon
              name="edit"
              width={14}
              onClick={() => setRenderState('edit')}
              color={renderState === 'edit' ? 'brand' : 'element'}
            />
          </IconButton>
          <IconButton>
            <Icon
              name="delete"
              width={14}
              onClick={() => setRenderState('remove')}
              color={renderState === 'remove' ? 'brand' : 'element'}
            />
          </IconButton>
        </div>
      </div>
      {renderState === 'edit' ? (
        <Edit
          note={note}
          intervention={intervention}
          onClose={() => {
            setRenderState(null)
          }}
          images={images?.map((image) => image.url)}
        />
      ) : (
        <div className={styles.content}>
          <p>{note.note}</p>

          {note.tags && (
            <div className={styles.tags}>
              {note.tags?.map((tag) => (
                <Tag key={tag.id} size="md">
                  {tag.name}
                </Tag>
              ))}
            </div>
          )}

          {!!images?.length && (
            <div className={styles.attaches}>
              <div>
                {images.slice(0, 4).map((image, index) => {
                  return index < 3 ? (
                    <img
                      key={image.id}
                      alt=""
                      src={image.url}
                      onClick={() =>
                        handleOpenModal({
                          type: 'GALLERY',
                          data: {
                            imageId: image.id,
                            onClose: handleCloseModal,
                            images: images || [],
                          },
                        })
                      }
                    />
                  ) : (
                    <div key={image.id} className={styles.imageMessage}>
                      <img alt="" src={image.url} />
                      <button
                        onClick={() =>
                          handleOpenModal({
                            type: 'GALLERY',
                            data: {
                              imageId: image.id,
                              onClose: handleCloseModal,
                              images: images || [],
                            },
                          })
                        }
                      >
                        Ver todos
                      </button>
                    </div>
                  )
                })}
              </div>
              <div>
                <Tag color="outlined">
                  <Icon name="attach" color="element-default" width={12} />
                  {images?.length} anexos
                </Tag>
                <ButtonV2
                  size="md"
                  appearance="tertiary"
                  onClick={() => handleDownload(images)}
                >
                  <Icon name="download" /> Download
                </ButtonV2>
              </div>
            </div>
          )}
        </div>
      )}
      <UserVerification
        type="delete"
        onSubmit={handleDelete}
        isOpen={renderState === 'remove'}
        onCancel={() => setRenderState(null)}
      />
    </li>
  )
}

export default Note
