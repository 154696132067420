import { Breadcrumbs, Button, Wizard } from 'components'
import { useCallback, useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'

import { OfficeHoursPayload, Scale } from 'services/officeHours/types'

import {
  GeneralInformation,
  BusinessHours,
} from 'domains/customer/screens/OfficeHours/components/OfficeHoursForm/components'

import styles from './OfficeHoursForm.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { officeHoursSchema } from 'domains/customer/screens/OfficeHours/schemas/officeHoursSchema'
import { OfficeHoursFormData } from 'domains/customer/screens/OfficeHours/types'
import { useNavigate } from 'react-router-dom'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { CustomerPaths } from 'routes/constants/paths/customer'

interface OfficeHoursFormProps {
  officeHours?: OfficeHoursFormData
  onSubmit: (data: OfficeHoursPayload) => void
}

export const OfficeHoursForm = ({
  officeHours,
  onSubmit,
}: OfficeHoursFormProps) => {
  const { customer, account } = useCustomerContext()
  const customerName = customer?.name ?? ''

  const [activeStep, setActiveStep] = useState(0)

  const navigate = useNavigate()

  const form = useForm<OfficeHoursFormData>({
    mode: 'onChange',
    resolver: joiResolver(officeHoursSchema),
    defaultValues: {
      name: officeHours?.name,
      tolerance: officeHours?.tolerance,
      scales: officeHours?.scales || [],
      parameters: officeHours?.parameters || {
        active: false,
        armedOutOfScaleActive: false,
        disarmedOutOfScaleActive: false,
        notArmedActive: false,
        notDisarmedActive: false,
      },
      coverage: {
        accounts: officeHours?.coverage.accounts || [],
        partitions: officeHours?.coverage.partitions || [],
        patrimonies: officeHours?.coverage.patrimonies || [],
      },
    },
  })

  const { handleSubmit, formState } = form
  const { customer: customerContext } = useCustomerContext()

  const submit = useCallback(() => {
    const customerId = customerContext?.id ?? ''
    const officeHours = form.getValues()

    const workDayScale = officeHours.scales.find(
      (scale) => scale.dayOfWork === 'WORKDAYS',
    )

    let unGroupedBusinessDaysScale: Scale[] = []
    if (workDayScale) {
      unGroupedBusinessDaysScale = [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
      ].map((dayOfWork) => {
        return {
          dayOfWork,
          periods: workDayScale.periods,
        }
      }) as Scale[]
    }

    const { id, coverage, parameters, ...rest } = officeHours

    const payload: OfficeHoursPayload = {
      ...rest,
      ...parameters,
      customerId,
      coverage: {
        patrimonyIds: coverage.patrimonies.map((patrimony) => patrimony.id),
        accountIds: coverage.accounts.map((account) => account.id),
        partitionIds: coverage.partitions.map((partition) => partition.id),
      },
      scales: [
        ...officeHours.scales.filter((scale) => scale.dayOfWork !== 'WORKDAYS'),
        ...unGroupedBusinessDaysScale,
      ] as Scale[],
    }

    onSubmit(payload)
  }, [form, onSubmit])

  return (
    <div className={styles.container}>
      <div className={styles.wrapperBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Contas', href: '/account/list' },
            {
              title: 'Horários',
              href: `/account/management/${account?.id}/officeHours?page=1`,
            },
            { title: customerName, href: '' },
          ]}
        />
        <div className={styles.divider} />
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submit)}>
          <Wizard activeStepIndex={activeStep}>
            <Wizard.Step title="Informações gerais">
              <GeneralInformation customerId={customer?.id || ''} />
            </Wizard.Step>
            <Wizard.Step title="Definição de horários">
              <BusinessHours />
            </Wizard.Step>
          </Wizard>

          {activeStep === 0 ? (
            <div>
              <Button
                type="secondary"
                buttonTitle="Voltar à listagem"
                className={styles.previousButton}
                width="172px"
                onClick={() =>
                  navigate(CustomerPaths('officeHours', account?.id))
                }
              />
              <Button
                type="primary"
                buttonTitle="Próximo"
                className={styles.nextButton}
                width="172px"
                onClick={() => setActiveStep(1)}
              />
            </div>
          ) : (
            <>
              <Button
                type="secondary"
                buttonTitle="Voltar"
                className={styles.previousButton}
                width="172px"
                onClick={() => setActiveStep(0)}
              />
              <Button
                type="primary"
                buttonTitle="Salvar"
                className={styles.nextButton}
                width="172px"
                disabled={!formState.isValid}
                htmlType="submit"
              />
            </>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
