import { useInfiniteQuery } from '@tanstack/react-query'
import { PatrimonyDriver } from 'services/patrimony'
import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'
import { PatrimonyFragment } from 'services/patrimony/types'
import { useMemo } from 'react'

export const getPatrimonyFragment = async (
  customerId: string,
  offset = 0,
  filter?: string,
) => {
  const response = await PatrimonyDriver.queryPatrimoniesFragmentList(
    customerId,
    {
      ...(filter && { name: filter }),
      offset,
      recordsPerPage: 15,
    },
  )
  return response
}

export const useGetPatrimonyFragment = (
  customerId: string,
  filter?: string,
) => {
  const query = useInfiniteQuery({
    queryKey: ['patrimony-fragment', customerId, filter || ''],
    queryFn: ({ pageParam }) =>
      getPatrimonyFragment(customerId, pageParam, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PatrimonyFragment>(lastPage, allPages),
  })

  const patrimonyFragment = useMemo(
    () => flattenPages<PatrimonyFragment>(query.data),
    [query],
  )

  return { ...query, data: patrimonyFragment }
}
