import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'

import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'

import {
  AccountQuery,
  AccountTag,
  AccountTagQueryPayload,
  AggregatedAccount,
  UpdateAccountNotePayload,
} from './types'
import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { Result, Pagination } from '../types'
export interface AccountApiClient {
  queryAggregatedAccounts(
    queryParams: Partial<Pagination>,
  ): Promise<Result<AggregatedAccount>>
  updateNote(payload: UpdateAccountNotePayload): Promise<void>
  queryTags(queryParams: AccountTagQueryPayload): Promise<Result<AccountTag>>
}

export class AccountDriverImpl implements AccountApiClient {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}
  public async queryTags(
    queryParams: AccountTagQueryPayload,
  ): Promise<Result<AccountTag>> {
    const result = await this.httpClient.get<Result<AccountTag>>(
      '/account/tags/query',
      { params: queryParams },
    )

    return result.data
  }

  public async queryAggregatedAccounts(
    queryParams?: Partial<AccountQuery>,
  ): Promise<Result<AggregatedAccount>> {
    const response = await this.httpClient.get<Result<AggregatedAccount>>(
      'aggregation/searchAccounts',
      { params: queryParams },
    )

    return response.data
  }

  public async updateNote(payload: UpdateAccountNotePayload): Promise<void> {
    await this.httpClient.patch(`account/${payload.accountId}/note`, {
      note: payload.note,
    })
  }
}

export default new AccountDriverImpl()

export const getAccounts = async (
  offset = 0,
  customerId?: string,
  params?: { name?: string; patrimonyId?: string },
) => {
  const response = await HttpClient.get<Result<AggregatedAccount>>(
    'aggregation/searchAccounts',
    { params: { ...params, customerId, offset } },
  )

  return response.data
}

export const useGetAccounts = (customerId?: string, filter?: AccountQuery) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['accounts', filter, customerId],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getAccounts(pageParam, customerId, filter),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<AggregatedAccount>(lastPage, allPages),
    enabled: !!customerId,
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}

const getAccountTags = async (offset = 0, name?: string) => {
  const response = await HttpClient.get<Result<AccountTag>>(endpoints.query, {
    params: { type: 'Principal', offset, name },
  })

  return response.data
}

export const useGetAccountTags = (name = '') => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['tags', name],
    queryFn: ({ pageParam }) => getAccountTags(pageParam, name),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<AccountTag>(lastPage, allPages),
  })

  return {
    ...infiniteQuery,
    data: flattenPages<AccountTag>(infiniteQuery.data),
  }
}
