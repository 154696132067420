import * as Joi from '@hapi/joi'
import { jwtDecode } from 'jwt-decode'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  Icon,
  ModalV2,
  ButtonV2,
  FormItem,
  FormLabel,
  TextField,
} from 'components'
import {
  AccessToken,
  LoginRequest,
  useGetPermissions,
  useLogin,
} from 'services/auth'
import { useToast } from 'shared/hooks'

import styles from './styles.module.scss'
import { UbideskPermissions } from 'routes/types'

interface UserVerificationProps {
  isOpen: boolean
  type?: 'edit' | 'delete'
  onCancel: () => void
  onSubmit: (userId: string) => void
}

export const schema = Joi.object<LoginRequest>({
  login: Joi.string().required(),
  password: Joi.string().required(),
})

const UserVerification = ({
  isOpen,
  type = 'edit',
  onSubmit,
  onCancel,
}: UserVerificationProps) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginRequest>({
    resolver: joiResolver(schema),
  })
  const { addToast } = useToast()
  const { mutate: loginFn } = useLogin()
  const { mutate: getPermissions } = useGetPermissions()

  const handleOnCancel = () => {
    reset({})
    onCancel()
  }

  const handleOnSubmit = ({ login, password }: LoginRequest) => {
    loginFn(
      {
        login,
        password,
      },
      {
        onSuccess: (data) => {
          const {
            user: { id: userId },
          } = jwtDecode(data.access_token) as AccessToken

          getPermissions(userId, {
            onSuccess: (permissions) => {
              const permission = UbideskPermissions.SO_EDIT

              const hasAccess = () => {
                if (permissions) {
                  if (permission in permissions) {
                    return permissions[permission]
                  }
                }

                return false
              }

              if (hasAccess()) {
                onSubmit(userId)
              } else {
                addToast({
                  message:
                    'Esse usuário não tem permissão para realizar essa ação.',
                  type: 'alert',
                })
              }
            },
            onError: () => {
              addToast({
                message:
                  'Erro ao verificar permissões do usuário. Tente novamente.',
                type: 'alert',
              })
            },
          })
        },
        onError: (error) => {
          if (error.message.includes('401')) {
            addToast({
              message: 'Usuário ou senha inválidos. Tente novamente.',
              type: 'alert',
            })
          } else {
            addToast({
              message:
                'Não foi possível realizar a ação devido a um erro em nossos servidores. Tente novamente.',
              type: 'alert',
            })
          }
        },
      },
    )
  }

  return (
    <ModalV2.Root isOpen={isOpen}>
      <ModalV2.Content>
        <ModalV2.Title>
          <Icon name="warning" color="accent-yellow-default" width={20} />
          {type === 'edit' ? 'Editar' : 'Excluir'} nota
        </ModalV2.Title>
        <p>
          Entre com um usuário autorizado para confirmar a
          {type === 'edit' ? ' edição' : ' exclusão'}. Esta ação não poderá ser
          desfeita.
        </p>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className={styles.container}
        >
          <FormItem>
            <FormLabel>Usuário</FormLabel>
            <TextField
              placeholder="Usuário"
              {...register('login')}
              autoComplete="username"
            />
          </FormItem>
          <FormItem>
            <FormLabel>Senha</FormLabel>
            <TextField
              type="password"
              placeholder="••••••••"
              {...register('password')}
              autoComplete="current-password"
            />
          </FormItem>
          <ModalV2.Footer>
            <ButtonV2 appearance="tertiary" onClick={handleOnCancel}>
              Cancelar
            </ButtonV2>
            {type === 'edit' ? (
              <ButtonV2 type="submit" disabled={!isValid}>
                Salvar
              </ButtonV2>
            ) : (
              <ButtonV2 type="submit" color="red" disabled={!isValid}>
                Excluir
              </ButtonV2>
            )}
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default UserVerification
