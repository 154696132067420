import { cloneElement, Fragment, ReactElement } from 'react'

import { ReactComponent as SuccessIcon } from 'assets/svg/accept.svg'
import { ReactComponent as FailIcon } from 'assets/svg/fail.svg'

import { ReactComponent as WhatsAppIcon } from 'assets/svg/whatsapp.svg'
import {
  ReactComponent as ImageVerificationIcon,
  ReactComponent as ImageIcon,
} from 'assets/svg/image.svg'
import { ReactComponent as IntrusionIcon } from 'assets/svg/intrusion.svg'

import { ReactComponent as AvailableIcon } from 'assets/svg/exclamationMessage.svg'
import { ReactComponent as WaitingIcon } from 'assets/svg/exclamationTriangle.svg'
import { ReactComponent as FinishedIcon } from 'assets/svg/shield.svg'
import {
  ReactComponent as HiddenIcon,
  ReactComponent as EyeHiddenIcon,
} from 'assets/svg/eyeHidden.svg'

import { ReactComponent as AttendanceIcon } from 'assets/svg/headphones.svg'

import { ReactComponent as PinIcon } from 'assets/svg/location.svg'
import { ReactComponent as CarIcon } from 'assets/svg/car.svg'
import { ReactComponent as TravelWaitingIcon } from 'assets/svg/travelWaiting.svg'
import { ReactComponent as OpenLetterIcon } from 'assets/svg/openLetter.svg'
import { ReactComponent as ManualIcon } from 'assets/svg/manual.svg'

import { ReactComponent as RefusePhoneIcon } from 'assets/svg/refusePhone.svg'

import {
  AlertTypes,
  InterventionStates,
  InterventionStatus,
  HistoryIntervention,
  InterventionTypeName,
} from 'services/attendance/types'
import { Icon } from 'components'

const colors = {
  default: '#009F87',
  error: '#da291c',
  green: '#64b745',
}

const statusIcon: Record<InterventionStatus, ReactElement> = {
  SUCCESS: <SuccessIcon />,
  FAILURE: <FailIcon />,
  PENDING: <></>,
}

export const handleStatusIcon = (intervention: HistoryIntervention) => {
  return statusIcon[intervention.attributes?.status]
}

const alertIcons: Record<AlertTypes, ReactElement> = {
  CHECKLIST_SENT: <Icon name="checklist" color="accent-red-default" />,
  SUPPORT_REQUEST: <Icon name="support-request" color="accent-red-default" />,
  TRAVEL_WITHOUT_CHANGES: <Icon name="timeout" color="accent-red-default" />,
  COORDINATES_UPDATE_REQUEST: (
    <Icon name="coordinate-update" color="accent-red-default" />
  ),
}

const stateIcon: Record<InterventionStates, ReactElement> = {
  QUEUED: <WaitingIcon />,
  HIDDEN: <HiddenIcon />,
  TRAVEL_STARTED: <CarIcon />,
  AVAILABLE: <AvailableIcon />,
  TRAVEL_SENT: <Icon name="message" width={24} height={24} />,
  TRAVEL_FINISHED: <PinIcon />,
  IN_PROGRESS: <AttendanceIcon />,
  TRAVEL_VIEWED: <OpenLetterIcon />,
  TRAVEL_WAITING: <TravelWaitingIcon />,
  FINISHED: <FinishedIcon color={colors.green} />,
}

const recordIcon: Record<'TRUE' | 'FALSE', ReactElement> = {
  TRUE: <EyeHiddenIcon />,
  FALSE: <ManualIcon />,
}

export const handleInterventionIcon = (
  intervention: HistoryIntervention,
): ReactElement => {
  const typeName: Record<InterventionTypeName, ReactElement> = {
    PHONE_CALL: <Icon name="phone" />,
    SEND_IMAGE: <ImageIcon />,
    SEND_MESSAGE: <WhatsAppIcon />,
    REQUIRE_PHONE_CALL: <Fragment />,
    SEND_CHECKLIST: <Icon name="checklist" />,
    CONFIRM_INTRUSION: <IntrusionIcon />,
    IMAGE_VERIFICATION: <ImageVerificationIcon />,
    AUTOMATIC: <ManualIcon />,
    REFUSE_PHONE_CALL_REQUIREMENT: <RefusePhoneIcon />,
    SEND_ALERT: alertIcons[intervention.attributes?.alertType],
    STATE_CHANGE: stateIcon[intervention.attributes?.stateName],
    MANUAL_RECORD:
      recordIcon[intervention.attributes?.hidden?.toString().toUpperCase()],
  }

  const icon = typeName[intervention.typeName]
  const hasPropsColor = !!icon?.props?.color

  return hasPropsColor ? icon : cloneElement(icon, { color: colors.default })
}
