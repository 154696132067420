import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { ReactComponent as PlusSignIcon } from 'assets/svg/plus.svg'

import { useToast } from 'shared/hooks'
import { formatPhone } from 'utilities/masks'
import { useGetAccounts } from 'services/account'
import { Alert, ContactItem } from './components'
import {
  useCreateContact,
  useEditContact,
  useGetContact,
  useRemoveContact,
} from 'services/contact'
import {
  Button,
  Checkbox,
  ComboboxItem,
  Input,
  Loader,
  Modal,
} from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { AggregatedAccount } from 'services/account/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ContactPayload, PhoneType } from 'services/contact/types'

import styles from './styles.module.scss'
import { formSchema } from './utils'
import { Form } from './types'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'

type ContactModalProps = {
  isVisible?: boolean
  onClose?: () => void
}

const ContactModal = ({ isVisible = true, onClose }: ContactModalProps) => {
  const { contactId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: contact, isFetching: contactIsFetching } =
    useGetContact(contactId)

  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      ubiAppUser: false,
      allPartitions: false,
      allActionPlans: false,
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  })

  const [remove, setRemove] = useState<string[]>([])
  const [accountFilter, setAccountFilter] = useState('')
  const { customer } = useCustomerContext()
  const customerId = customer?.id || ''

  const { addToast } = useToast()
  const { mutate: editContact, isPending: editContactIsLoading } =
    useEditContact(contactId)
  const { mutate: createContact, isPending: createContactIsLoading } =
    useCreateContact()

  const formFunction = contactId ? editContact : createContact
  const { mutate: removeContact } = useRemoveContact(contactId)

  const { data, isError, isFetching, fetchNextPage } = useGetAccounts(
    customerId,
    {
      name: accountFilter,
    },
  )

  const showAlert = useMemo(
    () =>
      contact?.accounts.some(
        (account) => account.actionable || account.hasControl,
      ),
    [contact],
  )

  const handleClose = () => {
    reset()
    if (onClose) {
      onClose()
    } else {
      navigate(-1)
    }
  }

  const handleRemoveAccount = useCallback(() => {
    const accountIds = watch('accounts').map((account) => account.id)
    const data = remove.filter((remove) => !accountIds.includes(remove))

    removeContact(data, {
      onSuccess: () => {
        addToast({
          type: 'success',
          message: 'Contato editado com sucesso.',
        })
        reset()
      },
      onError: () => {
        addToast({
          type: 'alert',
          message: 'Erro ao cadastrar o contato. Tente novamente.',
        })
      },
    })
  }, [removeContact, watch, remove, addToast, reset])

  const onSubmit = useCallback(
    (data: Form) => {
      const phoneLength = data.phone?.length || 0

      const payload: ContactPayload = {
        name: data.name,
        role: data.role,
        email: data.email,
        accounts: data.accounts?.filter((account) => {
          return { id: account.id, admin: !!account.admin }
        }),
        customerId,
        ubiAppUser: data.ubiAppUser,
        allPartitions: data.allPartitions,
        allActionPlans: data.allActionPlans,
        ...(phoneLength && {
          phone: {
            type: 'C',
            number: Number(
              data.phone?.substring(5, phoneLength).replace('-', ''),
            ),
            provinceCode: Number(data.phone?.substring(1, 3)),
          },
        }),
      }

      formFunction(payload, {
        onSuccess: () => {
          addToast({
            type: 'success',
            message: 'Contato editado com sucesso.',
          })

          if (remove.length) {
            handleRemoveAccount()
          } else {
            reset()
          }

          if (onClose) {
            onClose()
          } else {
            navigate(location.state.background.pathname, {
              state: { contactName: data.name },
            })
          }
        },
        onError: () => {
          addToast({
            type: 'alert',
            message: 'Erro ao cadastrar o contato. Tente novamente.',
          })
        },
      })
    },
    [
      customerId,
      formFunction,
      addToast,
      remove,
      location,
      navigate,
      handleRemoveAccount,
      reset,
    ],
  )

  const handleAddToAccounts = useCallback(() => {
    const newAccount = watch('account')
    const currentAccounts = watch('accounts')

    if (
      newAccount &&
      !currentAccounts?.find((account) => account.id === newAccount.id)
    ) {
      setValue(
        'accounts',
        currentAccounts ? [...currentAccounts, newAccount] : [newAccount],
        { shouldDirty: true, shouldValidate: true },
      )
      setValue('account', undefined)
    }
  }, [watch, setValue])

  // TODO remove this useEffect after updating react-hook-form. This was created because in this version async default value is not accepted
  useEffect(() => {
    if (contact && !contactIsFetching) {
      const phoneNumber = `${contact.phone?.provinceCode || ''}${
        contact?.phone?.number || ''
      }`

      const phoneFormatted = phoneNumber
        ? formatPhone(
            phoneNumber.length < 15 ? PhoneType.Comercial : PhoneType.CellPhone,
            phoneNumber,
          )
        : ''

      const formValues = {
        name: contact.name,
        phone: phoneFormatted,
        role: contact.role,
        ubiAppUser: contact.ubiAppUser,
        email: contact.email,
        accounts: contact?.accounts.map((account) => ({
          ...account,
          canEdit: true,
        })),
        allPartitions: !!contact?.hasPartitions,
        allActionPlans: !!contact?.accounts.some(
          (account) => account.actionable,
        ),
      }

      Object.entries(formValues).map(([key, value]) => {
        return setValue(key, value, {
          shouldValidate: true,
        })
      })
    }
  }, [contact, contactIsFetching, setValue])

  return (
    <Modal
      isVisible={isVisible}
      title={contactId ? 'Editar contato' : 'Cadastro de novo contato'}
      onClose={handleClose}
      size="md"
    >
      <div className={styles.container}>
        {contactIsFetching || createContactIsLoading || editContactIsLoading ? (
          <Loader isVisible />
        ) : (
          <>
            {showAlert && <Alert />}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.content}>
                <div>
                  <Input
                    label="Nome"
                    {...register('name')}
                    value={watch('name')}
                    onChange={(event) =>
                      setValue('name', event.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                  <Input
                    label="Função"
                    maxLength={45}
                    {...register('role')}
                    value={watch('role')}
                    onChange={(event) =>
                      setValue('role', event.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                  <Input
                    label="Telefone"
                    {...register('phone')}
                    value={watch('phone')}
                    maxLength={15}
                    onChange={(event) => {
                      const value = event.target.value

                      setValue(
                        'phone',
                        formatPhone(
                          value.length < 15
                            ? PhoneType.Comercial
                            : PhoneType.CellPhone,
                          value,
                        ),
                        { shouldValidate: true, shouldDirty: true },
                      )
                    }}
                  />
                  <Input
                    label="E-mail"
                    {...register('email')}
                    value={watch('email')}
                    onChange={(event) =>
                      setValue('email', event.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                  <div className={styles.ubiAppUser}>
                    <Checkbox
                      small
                      label="Usuário do UbiApp"
                      id="ubiAppUser"
                      disabled={!watch('email')}
                      {...register('ubiAppUser')}
                      checked={!!watch('ubiAppUser')}
                      onChange={() =>
                        setValue('ubiAppUser', !watch('ubiAppUser'), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                    />
                  </div>
                  <div className={styles.checkboxBox}>
                    <h3>Detalhes de vinculação</h3>
                    <Checkbox
                      small
                      id="allPartitions"
                      {...register('allPartitions')}
                      checked={!!watch('allPartitions')}
                      label="Adicionar este contato a todos as partições das contas a serem vinculadas."
                      onChange={() =>
                        setValue('allPartitions', !watch('allPartitions'), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                    />

                    <Checkbox
                      small
                      id="allActionPlans"
                      {...register('allActionPlans')}
                      disabled={!watch('allPartitions') || !watch('phone')}
                      checked={!!watch('allActionPlans')}
                      label="Adicionar este contato a todas os planos de ação das contas vinculadas."
                      onChange={() =>
                        setValue('allActionPlans', !watch('allActionPlans'), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                    />
                  </div>
                </div>
                <div>
                  <Combobox
                    label={{
                      text: 'Conta',
                    }}
                    onSearch={setAccountFilter}
                    {...register('account')}
                    value={watch('account')?.aggregatedAccountName}
                    items={parseDataToComboboxV2(
                      data.filter(
                        (data) =>
                          !watch('accounts')?.find((acc) => acc.id === data.id),
                      ) || [],
                      'aggregatedAccountName',
                    )}
                    itemLabel={(item) => {
                      const agentItem = item as ComboboxItem<AggregatedAccount>

                      return agentItem.value.serviceType?.name || ''
                    }}
                    onChange={(selected) => {
                      const selectedAccount = (
                        selected as ComboboxItem<AggregatedAccount>
                      ).value

                      setValue(
                        'account',
                        {
                          id: selectedAccount.id,
                          serviceType: selectedAccount.serviceType?.name,
                          aggregatedAccountName:
                            selectedAccount.aggregatedAccountName,
                        },
                        { shouldValidate: true, shouldDirty: true },
                      )
                    }}
                    isError={isError}
                    isLoading={isFetching}
                    onEndReached={fetchNextPage}
                  />
                  <Button
                    type="tertiary"
                    icon={PlusSignIcon}
                    disabled={!watch('account')}
                    onClick={handleAddToAccounts}
                    buttonTitle="Adicionar à lista"
                    className={styles.addAccountButton}
                  />
                  <div className={styles.accountsBox}>
                    <h3>Lista de contas vinculadas</h3>
                    <ul
                      {...register('accounts')}
                      className={styles.accountsList}
                    >
                      {watch('accounts')?.map((account) => (
                        <ContactItem
                          account={account}
                          onAdmin={(account, value) => {
                            const newValue = watch('accounts')?.map((item) => {
                              if (item.id === account) {
                                return { ...item, admin: value }
                              }

                              return item
                            })

                            setValue('accounts', newValue, {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }}
                          onRemove={(account) => {
                            setRemove((old) => [...old, account])

                            const newValue = watch('accounts')?.filter(
                              (item) => item.id !== account,
                            )

                            setValue('accounts', newValue, {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }}
                          key={account.id}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.actions}>
                <Button
                  type="secondary"
                  buttonTitle="Cancelar"
                  onClick={handleClose}
                />
                <Button
                  type="primary"
                  buttonTitle="Salvar"
                  htmlType="submit"
                  disabled={
                    !isValid ||
                    !isDirty ||
                    createContactIsLoading ||
                    editContactIsLoading
                  }
                />
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ContactModal
