import { useCallback, Fragment, useEffect, useMemo, useState } from 'react'
import { ToggleHook, useToggle } from 'shared/hooks'

import { maskedDateTime } from 'utilities/date'
import { EventTag } from 'domains/occurrence/components/EventTag/EventTag'

import {
  Icon,
  TableV2 as Table,
  LoaderV2 as Loader,
  EmptyState,
  PaginationV2 as Pagination,
} from 'components'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import { FormProvider, useForm } from 'react-hook-form'

import OccurrenceDriver from 'services/occurrence'
import { OccurrenceListResponse } from 'services/occurrence/types'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { Filter } from 'domains/customer/screens/History/components/Occurrence/Filter/Filter'

import {
  OccurrenceFiltersSchema,
  occurrenceFiltersSchema,
} from 'domains/customer/schemas/occurrence/searchOccurrenceSchema'

import styles from './Occurrence.module.scss'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { InfoModal } from 'domains/occurrence/components'
import { Result } from 'services/types'
import { OccurrenceListFiltersSchema } from 'domains/occurrence/schemas/searchOccurrenceListSchema'

const columnHelper = createColumnHelper<OccurrenceListResponse>()

interface OccurrenceProps {
  filtersProps?: ToggleHook
  filteredAccount: string
  openInNewTab?: boolean
}

export const Occurrence = ({
  filtersProps,
  filteredAccount,
}: OccurrenceProps) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { getFilters, setFilters } =
    usePersistentFilters<OccurrenceListFiltersSchema>('occurrences')

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState<Result<OccurrenceListResponse>>({
    data: [],
    totalElements: 0,
  })

  const infoModal = useToggle()

  const form = useForm<OccurrenceFiltersSchema>({
    resolver: joiResolver(occurrenceFiltersSchema),
    defaultValues: getFilters(),
  })

  const { watch } = form

  const fetchOccurrences = useCallback(async () => {
    setIsFetching(true)

    const currentPage = Number(searchParams.get('page')) || 0
    const size = 15

    const partition = watch('partition')
    const occurrenceType = watch('occurrenceType')
    try {
      const occurrences = await OccurrenceDriver.findAllOccurrences({
        page: currentPage - 1,
        size,
        sort: 'createdAt,desc',
        accountId: filteredAccount,
        ...(partition && { partitionId: partition.id }),
        ...(occurrenceType && { occurrenceTypeId: occurrenceType.id }),
      })

      setData(occurrences)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [filteredAccount, watch, searchParams])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchOccurrences()
    }
  }, [page, filter, fetchOccurrences])

  const handleView = useCallback(
    (occurrenceId: string) => {
      navigate(`/occurrence/attendance/${occurrenceId}`, {
        state: {
          readonly: true,
          from: 'management-occurrence',
        },
      })
    },
    [navigate],
  )

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (occurrence) => handleView(occurrence.id),
      },
    ],
    [],
  )

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '0',
    })
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('number', {
        size: 60,
        id: 'number',
        header: 'Número',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row, {
        header: 'Conta',
        cell: (info) => {
          const customer = info.row.original.customer?.name
          const code = info.row.original.account?.code

          return `${code} - ${customer}`
        },
      }),
      columnHelper.accessor('typeName', {
        size: 63,
        header: 'Tipo',
        cell: (info) => {
          return (
            <span className={styles.tagContainer}>
              <EventTag type={info.getValue()} />
            </span>
          )
        },
      }),
      columnHelper.accessor('createdAt', {
        size: 93,
        header: 'Abertura',
        cell: (info) => maskedDateTime(info.getValue()),
      }),
      columnHelper.accessor('finishedAt', {
        size: 93,
        header: 'Fechamento',
        cell: (info) => info.getValue() && maskedDateTime(info.getValue()),
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 42,
        cell: (info) => {
          const value = info.getValue()

          return (
            <Icon
              name={actions[0].Icon}
              onClick={() => actions[0].handler(value)}
              style={{ cursor: 'pointer' }}
            />
          )
        },
      }),
    ],
    [columnHelper, actions],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      <div className={styles.wrapper}>
        {
          {
            view: (
              <div className={styles.tableContainer}>
                <Table.Root>
                  <Table.Header>
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.ResizableColumn
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.column.getSize() }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Table.ResizableColumn>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Header>

                  <Table.Body className={styles.tbody}>
                    {table?.getRowModel().rows.map((row) => (
                      <Fragment key={row.id}>
                        <Table.Row>
                          {row.getVisibleCells().map((cell) => (
                            <Table.Cell
                              key={cell.id}
                              className={styles[cell.column.id]}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Fragment>
                    ))}
                  </Table.Body>
                </Table.Root>
              </div>
            ),
            empty: <EmptyState type="EmptyDataFromBFF" />,
            error: <EmptyState type="ErrorToLoadInfos" />,
            loading: (
              <div className={styles.loader}>
                <Loader />
              </div>
            ),
          }[handleRender(data?.data, isError, isFetching)]
        }
        <Pagination
          className={`${styles.pagination} ${filtersProps ? styles.paginationWrapper : ''}`}
          totalElements={data?.totalElements}
        />
      </div>
      {filtersProps && (
        <FormProvider {...form}>
          <Filter
            {...filtersProps}
            filteredAccount={filteredAccount}
            onApplyFilters={() => {
              setFilters(watch())
              filtersProps.hide()
              fetchOccurrences()
              handleResetPagination()
            }}
          />
          {infoModal.isVisible && (
            <InfoModal
              isVisible={infoModal.isVisible}
              onClose={infoModal.hide}
              title="Ocorrência em atendimento"
              text="Você está atualmente atendendo a uma ocorrência. Antes de prosseguir com outra, por favor, conclua a atual."
            />
          )}
        </FormProvider>
      )}
    </>
  )
}
