import { FC, useCallback, useMemo } from 'react'

import { useToast } from 'shared/hooks'
import { useNavigate, useLocation } from 'react-router-dom'

import { Loader } from 'components'
import { PatrolForm } from 'domains/customer/screens/Patrol/components'

import { PatrolRequest } from 'services/patrol/types'
import { useFetchPatrol, useUpdatePatrol } from 'services/patrol'

import { groupIntoBusinessDays } from 'domains/customer/screens/Patrol/utilities/schedules'

const UpdatePatrol: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { addToast } = useToast()

  const { data: patrolData, isFetching } = useFetchPatrol(
    location.state?.patrolId || '',
  )

  const { mutate } = useUpdatePatrol(location.state?.patrolId || '')

  const formattedPatrolData = useMemo(() => {
    if (patrolData) {
      const formattedScales = groupIntoBusinessDays(patrolData?.schedules)

      return {
        ...patrolData,
        schedules: formattedScales,
      }
    }
  }, [patrolData])

  const onSubmit = useCallback(
    (formData: PatrolRequest) => {
      mutate(formData, {
        onSuccess: () => {
          addToast({ message: 'Ocorrência periódica editada com sucesso.' })
          navigate(
            `/account/management/${formData.accountId}/officeHours?page=1`,
          )
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar ocorrência periódica. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [mutate, addToast, navigate],
  )

  if (!formattedPatrolData || isFetching) return <Loader isVisible />

  return (
    <PatrolForm
      onSubmit={(formData) => onSubmit(formData)}
      patrol={formattedPatrolData}
    />
  )
}

export default UpdatePatrol
