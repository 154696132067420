import { FC, useCallback } from 'react'

import { useToast } from 'shared/hooks'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'components'
import { PatrolForm } from 'domains/customer/screens/Patrol/components'

import { useCreatePatrol } from 'services/patrol'
import { PatrolRequest } from 'services/patrol/types'

const CreatePatrol: FC = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()

  const { mutate, status } = useCreatePatrol()

  const onSubmit = useCallback(
    (formData: PatrolRequest) => {
      mutate(formData, {
        onSuccess: () => {
          addToast({ message: 'Ocorrência periódica criada com sucesso.' })
          navigate(
            `/account/management/${formData.accountId}/officeHours?page=1`,
          )
        },
        onError: () => {
          addToast({
            message: 'Erro ao criar ocorrência periódica. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [mutate, addToast, navigate],
  )

  if (status === 'pending') return <Loader isVisible />

  return <PatrolForm onSubmit={(formData) => onSubmit(formData)} />
}

export default CreatePatrol
