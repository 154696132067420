import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useToast } from 'shared/hooks'

import { useCreateImage } from 'services/image'
import { useUpdateDevice, useGetDevice } from 'services/device'

import { Form } from '../types'

import { DeviceForm, FormLoader } from '../components'

import { DeviceResponse } from 'services/device/types'

import styles from './styles.module.scss'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'

const UpdateDevice: React.FC = () => {
  const location = useLocation()

  const deviceId = new URLSearchParams(location.search).get('deviceId') || ''

  const { addToast } = useToast()

  const { data, isFetching, refetch } = useGetDevice(deviceId)
  const { mutate: createImage, isPending: isCreatingImage } = useCreateImage()
  const { mutate: updateDevice, isPending: isUpdateDevice } =
    useUpdateDevice(deviceId)
  const { account, customer, patrimony } = useCustomerContext()

  const handleCreateImage = useCallback(
    (image: File[], installedDevice: DeviceResponse) => {
      const accountId = account?.id || ''
      const customerId = customer?.id || ''
      const patrimonyId = patrimony?.id || ''

      const { id, deviceTypeId, centralId } = installedDevice

      if (patrimonyId && customerId) {
        createImage(
          {
            image: image[0],
            payload: {
              principalOwner: {
                id,
                name: 'InstalledDevice',
              },
              owners: [
                {
                  id: customerId,
                  name: 'Customer',
                },
                {
                  id: patrimonyId,
                  name: 'Patrimony',
                },
                {
                  id: accountId,
                  name: 'Account',
                },
                {
                  id: centralId,
                  name: 'Central',
                },
                {
                  id: deviceTypeId,
                  name: 'Device',
                },
                {
                  id,
                  name: 'InstalledDevice',
                },
              ],
            },
          },
          {
            onSuccess: () => {
              addToast({
                message: 'Equipamento editado com sucesso.',
                type: 'success',
              })

              refetch()
            },

            onError: () => {
              addToast({
                message: 'Erro ao editar equipamento. Tente novamente.',
                type: 'alert',
              })
            },
          },
        )
      }
    },
    [addToast, createImage, refetch],
  )

  const onSubmit = useCallback(
    (data: Form) => {
      updateDevice(
        {
          code: data.code.replace(/^0+/, ''),
          name: data.name,
          deviceStatus: true,
          modelId: data.model.id,
          centralId: data.centralId,
          contactId: data.contact?.id,
          deviceTypeId: data.device.id,
          deviceTypeCode: data.device.code,
          tags: data.tags?.map((tag) => ({ id: tag.id, name: tag.name })),
          partitions: data.partitions.map((partition) => partition.id),
        },
        {
          onSuccess: (installedDevice) => {
            if (typeof data.image !== 'string' && !!data.image) {
              handleCreateImage(data.image, installedDevice)
            } else {
              addToast({
                message: 'Equipamento editado com sucesso.',
                type: 'success',
              })
            }

            refetch()
          },
          onError: () => {
            addToast({
              message: 'Erro ao editar equipamento. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [updateDevice, handleCreateImage, addToast, refetch],
  )

  const handleRender = () => {
    if (!data || isFetching) return 'loading'

    return 'view'
  }

  return (
    <main className={styles.container}>
      {
        {
          view: !!data && (
            <DeviceForm
              onSubmit={onSubmit}
              isLoading={isFetching || isCreatingImage || isUpdateDevice}
              defaultValues={{
                ...data,
                code: data.code,
                tags: data.tags.map((tag) => ({
                  id: tag.id,
                  name: tag.name,
                })),
              }}
            />
          ),
          loading: <FormLoader />,
        }[handleRender()]
      }
    </main>
  )
}

export default UpdateDevice
