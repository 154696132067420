import { useState, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as CoordinateIcon } from 'assets/svg/coordinate.svg'
import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'
import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrenceExclamation.svg'
import { ReactComponent as ShareChainIcon } from 'assets/svg/shareChain.svg'

import {
  useTimer,
  useToast,
  useTooltipVisibility,
  useUserInfo,
} from 'shared/hooks'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { occurrenceTypesLabel } from '../../../../types'
import { handleStateRender } from '../../../../utils/handleStateRender'
import { usePersistentTab } from 'domains/occurrence/hooks'
import {
  useCreateInterventation,
  useFetchQuickAlerts,
  useFetchTacticals,
  useGetOccurrence,
} from 'services/displacementMap'
import {
  OccurrenceDetails as OccurrenceDetailsType,
  StateName,
} from 'services/displacementMap/types'
import { dateNow } from 'utilities/date'
import {
  Button,
  Icon,
  IconButton,
  ProtectedImage,
  Tag,
  Tooltip,
} from 'components'
import {
  useDisplacementStep,
  useDisplacementModal,
  useMap,
} from '../../../../contexts'

import { DropDown, BackDrop, Loader } from '../../../../components'
import styles from './OccurrenceDetails.module.scss'
import { formatDocument } from 'utilities/masks'
import { EventItem } from './components/EventItem'

interface OccurrenceDetailsProps {
  onClose: () => void
  occurrenceId: string
}

const handleTitle = (step: StateName, agentName: string) => {
  const steps: Record<StateName, string> = {
    TRAVEL_FINISHED: 'Chegada no local',
    QUEUED: 'Mudança de status para Observação',
    AVAILABLE: 'Mudança de status para Disponível',
    TRAVEL_WAITING: 'Mudança de status para Deslocamento',
    TRAVEL_STARTED: `Agente tático ${agentName} em trânsito`,
    TRAVEL_VIEWED: `Solicitação visualizada pelo agente tático ${agentName}`,
    TRAVEL_SENT: '',
    FINISHED: '',
    IN_PROGRESS: '',
  }

  return steps[step]
}

const tags: Record<StateName, string> = {
  QUEUED: 'emObservacao',
  AVAILABLE: 'disponivel',
  FINISHED: '',
  IN_PROGRESS: '',
  TRAVEL_SENT: '',
  TRAVEL_WAITING: 'deslocamento',
  TRAVEL_STARTED: 'deslocamentoIniciado',
  TRAVEL_FINISHED: 'deslocamentoNoLocal',
  TRAVEL_VIEWED: 'deslocamentoVisualizado',
}

export const OccurrenceDetails: React.FC<OccurrenceDetailsProps> = ({
  onClose,
  occurrenceId,
}: OccurrenceDetailsProps) => {
  const { userInfo } = useUserInfo()

  const userId = userInfo.id
  const [modalIsVisible, setModalIsVisible] = useState(false)

  const navigate = useNavigate()
  const { addToast } = useToast()
  const { selectedRoute, handleRoute } = useMap()
  const { data: quickAlerts } = useFetchQuickAlerts(userId)
  const { handleOpenModal, handleCloseModal } = useDisplacementModal()
  const { handleDisplacementStep } = useDisplacementStep()

  const quickAlertOnAttendant = quickAlerts?.find(
    (alert) => alert.user.id === userId,
  )?.alertType

  const { data, isFetched } = useGetOccurrence(occurrenceId)

  const { data: agentDuty } = useFetchTacticals(userId, 10000)

  const currentTactical = agentDuty?.find(
    (tactical) => selectedRoute.tactical?.id === tactical.id,
  )

  const timer = useTimer({
    initialTime: data?.createdAt ?? new Date().getTime(),
    finalTime: data?.finishedAt,
  })

  const { replaceUrl } = usePersistentTab('occurrence-navigation')

  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  const handleSeeMoreAccount = useCallback(() => {
    if (data?.account.id) {
      replaceUrl(CustomerPaths('patrimonies', data.account.id))
    }
  }, [replaceUrl, data])

  const handleSeeMore = useCallback(() => {
    navigate(`/occurrence/attendance/${occurrenceId}`, {
      state: { readonly: true },
    })
  }, [navigate, occurrenceId])

  const defaultModalItems = useMemo(
    () => [
      {
        label: 'Visualizar',
        onClick: handleSeeMore,
      },
      {
        label: 'Finalizar',
        onClick: () => {
          if (
            data &&
            ['TRAVEL_SENT', 'TRAVEL_VIEWED', 'TRAVEL_STARTED'].includes(
              data?.stateName,
            )
          ) {
            handleOpenModal('CHANGE_STATE', {
              imageId: '',
              images: [],
              onClose: () => {},
              onSubmit: () => handleOpenModal('END_OCCURRENCE'),
            })
          } else {
            handleOpenModal('END_OCCURRENCE')
          }
        },
      },
      {
        hasDivider: true,
        label: data?.tactical ? 'Alterar tático' : 'Atribuir tático',
        onClick: () => {
          handleOpenModal('CHANGE_TACTICAL')
        },
      },
    ],
    [data, handleOpenModal, handleSeeMore],
  )

  const [modalItems, setModalItems] = useState(defaultModalItems)

  const handleFormatAddress = (
    addres: OccurrenceDetailsType['patrimony']['address'],
  ) => {
    return `${addres?.street}, ${addres?.number} - ${addres?.district}, ${addres?.city}, ${addres?.acronym}`
  }

  const handleFormatDate = (date?: number) => {
    return date ? dateNow(date) : '-'
  }

  type ModalItems = {
    label: string
    onClick: () => void
  }

  const { mutate } = useCreateInterventation(data?.id)

  const handleClick = useCallback(
    (step: StateName) => {
      setModalIsVisible(false)
      if (userId && (step.includes('TRAVEL') ? currentTactical : true)) {
        mutate(
          {
            userId,
            title: handleTitle(step, currentTactical?.agentName || ''),
            tags: [{ name: tags[step] }],
            typeName: 'STATE_CHANGE',
            attributes: {
              stateName: step,
              ...(currentTactical?.id &&
                step !== 'TRAVEL_WAITING' && {
                  agent: {
                    id: currentTactical.agentId,
                    name: currentTactical.agentName,
                  },
                  vehicle: currentTactical.vehicle,
                  agentCoordinates: currentTactical.position,
                }),
            },
          },
          {
            onSuccess: () => {
              if (currentTactical) {
                handleRoute({
                  type: 'tactical',
                  marker: { id: currentTactical?.id },
                })
              }
            },
            onError: () =>
              addToast({
                message: 'Erro ao realizar intervenção',
                type: 'alert',
              }),
          },
        )
      }
    },
    [addToast, currentTactical, mutate, tags],
  )

  const handleModalOptions = () => {
    const modalAllOptions: Record<StateName, ModalItems> = {
      AVAILABLE: {
        label: 'Tornar disponível',
        onClick: () => handleClick('QUEUED'),
      },
      FINISHED: {
        label: 'Finalizar',
        onClick: () => handleClick('QUEUED'),
      },
      IN_PROGRESS: {
        label: 'Em Progresso',
        onClick: () => handleClick('QUEUED'),
      },
      QUEUED: {
        label: 'Colocar em observação',
        onClick: () => {
          if (
            data &&
            ['TRAVEL_SENT', 'TRAVEL_VIEWED', 'TRAVEL_STARTED'].includes(
              data?.stateName,
            )
          ) {
            handleOpenModal('CHANGE_STATE', {
              imageId: '',
              images: [],
              onClose: () => {},
              onSubmit: () => {
                handleClick('QUEUED')
                handleCloseModal()
              },
            })
          } else {
            handleClick('QUEUED')
          }
        },
      },
      TRAVEL_FINISHED: {
        label: 'Chegada no local',
        onClick: () => handleClick('TRAVEL_FINISHED'),
      },
      TRAVEL_VIEWED: {
        label: 'Solicitação visualizada',
        onClick: () => handleClick('TRAVEL_VIEWED'),
      },
      TRAVEL_SENT: {
        label: 'Enviar para deslocamento',
        onClick: () => handleClick('TRAVEL_SENT'),
      },
      TRAVEL_WAITING: {
        label: 'Enviar para deslocamento',
        onClick: () => handleClick('TRAVEL_WAITING'),
      },
      TRAVEL_STARTED: {
        label: 'Trânsito iniciado',
        onClick: () => handleClick('TRAVEL_STARTED'),
      },
    }

    const {
      QUEUED,
      AVAILABLE,
      TRAVEL_VIEWED,
      TRAVEL_WAITING,
      TRAVEL_STARTED,
      TRAVEL_FINISHED,
    } = modalAllOptions

    const options: Record<StateName, ModalItems[]> = {
      FINISHED: [QUEUED],
      AVAILABLE: [QUEUED],
      QUEUED: [AVAILABLE],
      TRAVEL_WAITING: [QUEUED],
      TRAVEL_FINISHED: [QUEUED],
      IN_PROGRESS: [QUEUED, TRAVEL_WAITING],
      TRAVEL_STARTED: [QUEUED, TRAVEL_FINISHED],
      TRAVEL_VIEWED: [QUEUED, TRAVEL_STARTED, TRAVEL_FINISHED],
      TRAVEL_SENT: [QUEUED, TRAVEL_VIEWED, TRAVEL_STARTED, TRAVEL_FINISHED],
    }

    return data ? options[data.stateName] : [QUEUED]
  }

  useEffect(() => {
    if (data) {
      setModalItems([
        ...defaultModalItems,
        {
          label: 'Definir como...',
          subItems: handleModalOptions(),
        },
      ])
    } else {
      setModalItems(defaultModalItems)
    }
  }, [data, handleOpenModal, defaultModalItems, handleDisplacementStep])

  const serviceType = data?.patrimony?.serviceType?.name
  const serviceTypeColor = data?.patrimony?.serviceType?.color

  return (
    <BackDrop onClose={onClose}>
      <main className={styles.container}>
        {
          {
            view: !!data && (
              <>
                <header>
                  <div className={styles.occurrenceHeader}>
                    <h1>Ocorrência #{data.number}</h1>
                    <div className={styles.actions}>
                      <IconButton onClick={() => setModalIsVisible(true)}>
                        <Icon name="menu-kebab" color="element-default" />
                      </IconButton>
                      <IconButton type="button" onClick={onClose}>
                        <Icon name="close-xlg" color="element-default" />
                      </IconButton>
                    </div>
                    {modalIsVisible && (
                      <DropDown
                        onClose={() => setModalIsVisible(false)}
                        items={modalItems}
                        className={styles.dropDown}
                      />
                    )}
                  </div>
                  <div
                    className={[
                      styles.ocurrenceInfo,
                      styles[data.typeName],
                    ].join(' ')}
                  >
                    <span
                      ref={ref}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <OccurrenceIcon />

                      {[occurrenceTypesLabel[data.typeName], data.title]
                        .filter(Boolean)
                        .join(' - ')}
                    </span>
                    {isTooltipVisible && (
                      <Tooltip
                        type="informative"
                        isVisible={isTooltipVisible}
                        parentRef={ref}
                      >
                        {data?.title}
                      </Tooltip>
                    )}
                    <div>
                      <span>{timer}</span>
                    </div>
                  </div>
                </header>
                <div className={styles.content}>
                  {data.patrimony.image && (
                    <ProtectedImage imageId={data.patrimony.image} />
                  )}
                  <section className={styles.customerInfo}>
                    <h2
                      className={data.customer?.secondName && styles.secondName}
                    >
                      {data.customer?.name}
                    </h2>
                    {data.customer?.secondName && (
                      <h3>{data.customer?.secondName}</h3>
                    )}
                    <h4 className={styles.document}>
                      {formatDocument(data.customer.document || '')}
                    </h4>
                  </section>
                  <section>
                    <div className={styles.patrimonyInfo}>
                      <h3 className={styles.patrimonyName}>
                        {data.patrimony?.name}
                      </h3>
                      <h4>
                        {data.account?.code} - {data.account.name}
                      </h4>
                      {!!serviceType && (
                        <Tag
                          color="green"
                          {...(serviceTypeColor && {
                            style: {
                              backgroundColor: serviceTypeColor,
                              color: serviceTypeColor,
                            },
                          })}
                          singleColor={!!serviceTypeColor}
                        >
                          {serviceType}
                        </Tag>
                      )}
                    </div>
                    <div>
                      {data.account.tags && (
                        <>
                          <h4>Tags da conta</h4>
                          <div className={styles.tags}>
                            {data.account.tags.map((tag) => (
                              <span key={tag.id}>{tag.name}</span>
                            ))}
                          </div>
                        </>
                      )}
                      <Button
                        buttonTitle="Ver Conta"
                        type="tertiary"
                        className={styles.seeAccount}
                        icon={ShareIcon}
                        onClick={handleSeeMoreAccount}
                      />
                    </div>
                  </section>
                  <hr />
                  <section>
                    <div>
                      <h4>Endereço</h4>
                      <span>
                        {handleFormatAddress(data?.patrimony.address)}
                      </span>
                      <Button
                        type="tertiary"
                        icon={CoordinateIcon}
                        buttonTitle="Atualizar coordenadas"
                        disabled={
                          !data.alerts.coordinatesUpdateRequest &&
                          quickAlertOnAttendant !== 'COORDINATES_UPDATE_REQUEST'
                        }
                        onClick={() => handleOpenModal('UPDATE_COORDINATES')}
                      />
                    </div>
                    {data.patrimony.note && (
                      <div>
                        <h4>Observações</h4>
                        <span>{data.patrimony.note}</span>
                      </div>
                    )}
                    {(data.priority || data.patrimony.serviceType) && (
                      <div className={styles.patrimonyTags}>
                        {!!data.priority && (
                          <div>Prioridade {data.priority}</div>
                        )}
                      </div>
                    )}
                  </section>
                  <hr />
                  <section>
                    <div>
                      <h4>Recebido</h4>
                      <span>{handleFormatDate(data.createdAt)}</span>
                    </div>
                    <div>
                      <h4>Deslocado</h4>
                      <span>{handleFormatDate(data.states.travelSent)}</span>
                    </div>
                    <div>
                      <h4>Exibido</h4>
                      <span>{handleFormatDate(data.states.travelViewed)}</span>
                    </div>
                    <div>
                      <h4>Iniciado</h4>
                      <span>{handleFormatDate(data.states.travelStarted)}</span>
                    </div>
                    <div>
                      <h4>No local</h4>
                      <span>
                        {handleFormatDate(data.states.travelFinished)}
                      </span>
                    </div>
                  </section>
                  {!!data.totalEvents && (
                    <section>
                      <div>
                        <h3>
                          Últimos eventos
                          <div>
                            <ShareChainIcon />
                            {String(data.totalEvents).padStart(2, '0')}
                          </div>
                        </h3>
                      </div>
                      <ul className={styles.eventsList}>
                        {data.events?.map((event) => (
                          <EventItem key={event.id} event={event} />
                        ))}
                      </ul>
                    </section>
                  )}
                  {!!data.images?.length && (
                    <section className={styles.imagesSection}>
                      <span>Galeria de fotos</span>
                      <div>
                        <ul>
                          {data.images.map((image) => (
                            <ProtectedImage
                              key={image.id}
                              imageId={image.id}
                              onClick={() => {
                                handleOpenModal('GALLERY', {
                                  imageId: image.id,
                                  onClose: handleCloseModal,
                                  images: data.images,
                                })
                              }}
                            />
                          ))}
                        </ul>
                      </div>
                    </section>
                  )}
                  <section>
                    <Button
                      buttonTitle="Ver mais"
                      type="tertiary"
                      icon={ShareIcon}
                      onClick={handleSeeMore}
                    />
                  </section>
                </div>
              </>
            ),
            loading: <Loader />,
            error: (
              <span className={styles.error}>
                Erro ao carregar ocorrência. Tente novamente.
              </span>
            ),
          }[handleStateRender(data, isFetched)]
        }
      </main>
    </BackDrop>
  )
}
