import styles from './Instruction.module.scss'
import Icon from 'components/Icon'

type InstructionProps = {
  text: string
  className?: string
}

export const Instruction = ({ text, className }: InstructionProps) => {
  return (
    <div className={[styles.container, className].filter(Boolean).join(' ')}>
      <Icon
        name="information"
        color="accent-gray-default"
        width={20}
        height={20}
      />
      <p>{text}</p>
    </div>
  )
}
