import {
  createContext,
  useContext,
  useState,
  ComponentProps,
  useEffect,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
  useMemo,
  cloneElement,
  MouseEvent,
  ReactElement,
  WheelEvent,
  RefObject,
} from 'react'

import { createPortal } from 'react-dom'

import { maskedDateTime } from 'utilities/date'
import { formatDetectLabels } from 'utilities/image'
import joinClassNames from 'utilities/joinClassNames'
import { DetectionName, DetectLabel, Owner } from 'services/image'

import { ProtectedImage, IconButton, Icon } from 'components'

import {
  extractTransformValues,
  calculateZoomFocus,
  updateTransform,
  getDetectionsLabel,
  handleFormatDateForTimezone,
} from './utils'

import {
  DEFAULT_ZOOM,
  DOUBLE_CLICK_ZOOM_LEVEL,
  WHEEL_ZOOM_LEVEL,
  BUTTON_ZOOM_LEVEL,
  MAX_ZOOM,
} from './constants'

import { FormattedDetectLabelInstance } from './utils/types'

import styles from './styles.module.scss'

export type Image = {
  creation: number
  id: string
  url?: string
  title?: string
  note?: string
  hidden?: boolean
  owners?: Owner[]
  labels?: DetectLabel[]
}

type GalleryContextProps = {
  isOpen: boolean
  showDetections: boolean
  onClose: () => void
  onOpen: () => void
  currentImageIndex: [number, (index: number) => void]
  defaultState: [boolean, Dispatch<SetStateAction<boolean>>]
  currentImage: Image
  images: Image[]
  imageRef: RefObject<HTMLImageElement>
}

export const GalleryContext = createContext<GalleryContextProps | undefined>(
  undefined,
)

export const useGalleryContext = () => {
  const context = useContext<GalleryContextProps | undefined>(GalleryContext)

  if (!context) {
    throw new Error('useGalleryContext must be used within a GalleryProvider')
  }

  return context
}

interface RootProps extends ComponentProps<'div'> {
  isOpen?: boolean
  showDetections?: boolean
  onClose?: () => void
  onOpen?: () => null
  initialIndex?: number
  onImageChange?: (currentIndex: number) => void
  images: Image[]
}

const Root = ({
  isOpen = false,
  showDetections = false,
  onClose,
  initialIndex = 0,
  onImageChange,
  images,
  ...props
}: RootProps) => {
  const [open, setOpen] = useState(isOpen)
  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  const [defaultState, setDefaultState] = useState(true)

  const imageRef = useRef<HTMLImageElement>(null)

  const handleClose = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    },
    [onClose],
  )

  const handleChangeIndex = useCallback(
    (newIndex: number) => {
      setCurrentIndex(newIndex)
      onImageChange?.(newIndex)
    },
    [onImageChange],
  )

  const value: GalleryContextProps = {
    isOpen: open,
    showDetections,
    onClose: handleClose,
    onOpen: () => setOpen(true),
    defaultState: [defaultState, setDefaultState],
    currentImageIndex: [currentIndex, handleChangeIndex],
    currentImage: images[currentIndex],
    images,
    imageRef,
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen)
    }
  }, [isOpen])

  return <GalleryContext.Provider value={value} {...props} />
}

const Content = ({ className, ...props }: ComponentProps<'div'>) => {
  const { isOpen } = useGalleryContext()

  return (
    isOpen &&
    createPortal(
      <div className={styles.wrapper}>
        <div {...props} className={joinClassNames(styles.root, className)} />
      </div>,
      document.body,
    )
  )
}

const Trigger = ({ onClick, children, ...props }: ComponentProps<'button'>) => {
  const { onOpen } = useGalleryContext()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    onOpen()
  }

  return cloneElement(children as ReactElement, {
    onClick: handleClick,
    ...props,
  })
}

const Header = ({ ...props }: ComponentProps<'div'>) => {
  const { onClose, currentImage } = useGalleryContext()

  return (
    <div className={styles.header} {...props}>
      <div className={styles.titleInfo}>
        {currentImage.title && <span>{currentImage.title}</span>}
        <small>
          {maskedDateTime(handleFormatDateForTimezone(currentImage.creation))}
        </small>
      </div>

      <IconButton onClick={onClose}>
        <Icon name="close-lg" />
      </IconButton>
    </div>
  )
}

const CurrentImage = ({ className, ...props }: ComponentProps<'img'>) => {
  const {
    currentImage,
    imageRef,
    showDetections,
    defaultState: [defaultState],
  } = useGalleryContext()

  const [isDrag, setIsDrag] = useState(false)

  const [selectedBoundingBox, setSelectedBoundingBox] =
    useState<FormattedDetectLabelInstance | null>(null)

  const instances = useMemo(() => {
    if (!currentImage || !currentImage.labels) {
      return []
    }

    const formattedLabels = formatDetectLabels(currentImage.labels)
    return formattedLabels.reduce(
      (previous: FormattedDetectLabelInstance[], current) => {
        const result: FormattedDetectLabelInstance[] = current.instances.map(
          (instance, index) => ({
            ...instance,
            id: `${index}${instance.confidence}`,
            label: current.name,
          }),
        )

        return [...previous, ...result]
      },
      [],
    )
  }, [currentImage])

  const legend = useMemo(() => {
    const count = currentImage.labels?.flatMap(
      (label) => label.instances.length,
    )

    if (!count) return

    const label = selectedBoundingBox?.id
      ? `${selectedBoundingBox?.label === DetectionName.PERSON ? 'Pessoa' : 'Pet'} (${Math.round(
          selectedBoundingBox.confidence,
        )})% de precisão`
      : ''

    const detectionLabel = getDetectionsLabel(instances || [])

    if (label) return label

    return `${count} ${Number(count) > 1 ? 'detecções' : 'detecção'} (${detectionLabel})`
  }, [currentImage, instances, selectedBoundingBox])

  return (
    <>
      <ProtectedImage
        {...props}
        key={currentImage.id}
        className={joinClassNames(styles.currentImage, className)}
        imageId={currentImage.id}
        draggable={false}
        onMouseDown={() => setIsDrag(true)}
        onMouseUp={() => setIsDrag(false)}
        style={{
          cursor: isDrag ? 'grabbing' : 'grab',
        }}
        ref={imageRef}
      />

      {defaultState && showDetections && (
        <>
          {instances.map((instance) => (
            <button
              key={instance.id}
              style={{
                position: 'absolute',
                left: `${instance.boundingBox.left * 100}%`,
                top: `${instance.boundingBox.top * 100}%`,
                width: `${instance.boundingBox.width * 100}%`,
                height: `${instance.boundingBox.height * 100}%`,
              }}
              className={joinClassNames(
                styles.boundingBox,
                !!selectedBoundingBox &&
                  selectedBoundingBox.id !== instance.id &&
                  styles.lessOpaque,
              )}
              onClick={() => {
                if (selectedBoundingBox?.id === instance.id) {
                  setSelectedBoundingBox(null)
                  return
                }
                setSelectedBoundingBox(instance)
              }}
            />
          ))}

          {legend && (
            <div className={styles.imageTitle}>
              <span>{legend}</span>
            </div>
          )}
        </>
      )}
    </>
  )
}

const ZoomPanImage = ({ className, ...props }: ComponentProps<'div'>) => {
  const [isDrag, setIsDrag] = useState(false)

  const {
    imageRef,
    defaultState: [_, setDefaultState],
    currentImageIndex: [currentImageIndex],
  } = useGalleryContext()

  const translateRef = useRef({ x: 0, y: 0 })
  const startPanRef = useRef({ x: 0, y: 0 })

  const handleMouseDown = (e: MouseEvent) => {
    setIsDrag(true)
    startPanRef.current = {
      x: e.clientX - translateRef.current.x,
      y: e.clientY - translateRef.current.y,
    }
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDrag || !imageRef.current) return

      const newTranslate = {
        x: e.clientX - startPanRef.current.x,
        y: e.clientY - startPanRef.current.y,
      }

      translateRef.current = newTranslate

      imageRef.current.style.transform = updateTransform(imageRef.current, {
        translateX: newTranslate.x,
        translateY: newTranslate.y,
      })

      imageRef.current.style.transition = ''
    },
    [isDrag, imageRef],
  )

  const handleWheelZoom = useCallback(
    (e: WheelEvent) => {
      if (!imageRef.current || isDrag) return

      imageRef.current.style.transition = 'transform 0.3s ease'

      const currentTransform = extractTransformValues(
        imageRef.current.style.transform || '',
      )

      const delta =
        e.deltaY > 0 ? DEFAULT_ZOOM / WHEEL_ZOOM_LEVEL : WHEEL_ZOOM_LEVEL

      const newScale = Math.max(
        DEFAULT_ZOOM,
        Math.min(MAX_ZOOM, currentTransform.scale * delta),
      )

      const newTranslate = calculateZoomFocus(
        imageRef.current,
        e.clientX,
        e.clientY,
        newScale,
        translateRef,
      )

      translateRef.current = newTranslate

      imageRef.current.style.transform = updateTransform(imageRef.current, {
        scale: newScale,
        translateX: newTranslate.x,
        translateY: newTranslate.y,
      })

      setDefaultState(newScale === 1)
    },
    [imageRef, isDrag, setDefaultState],
  )

  const handleDoubleClick = () => {
    if (!imageRef.current) return

    imageRef.current.style.transition = 'transform 0.3s ease'

    const currentTransform = extractTransformValues(
      imageRef.current.style.transform,
    )

    const newScale =
      currentTransform.scale === DEFAULT_ZOOM
        ? DOUBLE_CLICK_ZOOM_LEVEL
        : DEFAULT_ZOOM

    translateRef.current = { x: 0, y: 0 }

    imageRef.current.style.transform = updateTransform(imageRef.current, {
      scale: newScale,
      translateX: 0,
      translateY: 0,
    })

    const isRotateZero = Math.abs(currentTransform.rotate % 360) < 0.1
    setDefaultState(newScale === DEFAULT_ZOOM && isRotateZero)
  }

  useEffect(() => {
    translateRef.current = { x: 0, y: 0 }
    startPanRef.current = { x: 0, y: 0 }
  }, [currentImageIndex])

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={() => setIsDrag(false)}
      onMouseLeave={() => setIsDrag(false)}
      onWheel={handleWheelZoom}
      onDoubleClick={handleDoubleClick}
      className={joinClassNames(styles.imageContainer, className)}
      {...props}
    />
  )
}

const Footer = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={joinClassNames(styles.footer, className)} />
}

const Actions = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div {...props} className={joinClassNames(styles.actions, className)} />
  )
}

const SliderCarousel = ({ className, ...props }: ComponentProps<'div'>) => {
  const {
    images,
    currentImageIndex: [currentImageIndex, setCurrentImageIndex],
    defaultState: [_, setDefaultState],
  } = useGalleryContext()

  const thumbnailRefs = useRef<HTMLButtonElement[]>([])

  useEffect(() => {
    thumbnailRefs.current[currentImageIndex]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, [currentImageIndex])

  return (
    <div
      {...props}
      className={joinClassNames(styles.sliderContainer, className)}
    >
      <div className={styles.slider}>
        {images.map((image, index) => (
          <button
            key={image.id}
            ref={(el) => el && (thumbnailRefs.current[index] = el)}
            onClick={() => {
              setDefaultState(true)
              setCurrentImageIndex(index)
            }}
          >
            <ProtectedImage
              className={joinClassNames(
                styles.thumbnail,
                currentImageIndex === index && styles.selected,
              )}
              imageId={image.id}
            />
          </button>
        ))}
      </div>
    </div>
  )
}

const Navigation = () => {
  const {
    images,
    currentImageIndex: [currentImageIndex, setCurrentImageIndex],
    defaultState: [_defaultState, setDefaultState],
  } = useGalleryContext()

  const handleClick = (direction: string) => {
    setCurrentImageIndex(
      direction === 'next'
        ? (currentImageIndex + 1) % images.length
        : (currentImageIndex - 1 + images.length) % images.length,
    )

    setDefaultState(true)
  }

  return ['prev', 'next'].map((direction) => (
    <IconButton
      className={joinClassNames(styles.navButton, styles[direction])}
      key={direction}
      onClick={() => handleClick(direction)}
    >
      <Icon
        name={direction === 'prev' ? 'chevron-left' : 'chevron-right'}
        color="background-default"
      />
    </IconButton>
  ))
}

const Zoom = () => {
  const {
    imageRef,
    defaultState: [defaultState, setDefaultState],
  } = useGalleryContext()

  const updateScale = (newZoomLevel: number) => {
    if (imageRef.current) {
      imageRef.current.style.transition = 'transform 0.3s ease'
      setDefaultState(newZoomLevel === 1)

      imageRef.current.style.transform = updateTransform(imageRef.current, {
        scale: newZoomLevel,
      })
    }
  }

  const handleZoomIn = () => {
    if (imageRef.current) {
      setDefaultState(false)
      const transform = imageRef.current.style.transform

      const { scale } = extractTransformValues(transform)

      const newZoomLevel = scale + BUTTON_ZOOM_LEVEL
      updateScale(newZoomLevel)
    }
  }

  const handleZoomOut = () => {
    if (imageRef.current) {
      setDefaultState(false)
      const transform = imageRef.current.style.transform

      const { scale } = extractTransformValues(transform)
      const newZoomLevel = Math.max(scale - BUTTON_ZOOM_LEVEL, DEFAULT_ZOOM)
      updateScale(newZoomLevel)
    }
  }

  useEffect(() => {
    if (imageRef.current) {
      const transform = imageRef.current.style.transform

      const { scale } = extractTransformValues(transform)

      setDefaultState(scale === 1)
    }
  }, [imageRef.current?.style.transform])

  return (
    <>
      <IconButton onClick={handleZoomIn}>
        <Icon name="zoom-in" color="background-default" />
      </IconButton>

      <IconButton onClick={handleZoomOut}>
        <Icon
          name="zoom-out"
          color={defaultState ? 'element-light' : 'neutral'}
        />
      </IconButton>
    </>
  )
}

const Rotate = () => {
  const {
    imageRef,
    defaultState: [_, setDefaultState],
  } = useGalleryContext()

  const updateRotation = (newRotationAngle: number) => {
    if (imageRef.current) {
      setDefaultState(false)

      const transform = imageRef.current.style.transform

      const { rotate } = extractTransformValues(transform)

      imageRef.current.style.transition = ''

      imageRef.current.style.transform = updateTransform(imageRef.current, {
        rotate: rotate + newRotationAngle,
      })
    }
  }

  return (
    <>
      <IconButton onClick={() => updateRotation(-90)}>
        <Icon name="turn-left" color="background-default" />
      </IconButton>

      <IconButton onClick={() => updateRotation(90)}>
        <Icon name="turn-right" color="background-default" />
      </IconButton>
    </>
  )
}

const Gallery = {
  Root,
  Content,
  Trigger,
  Header,
  ZoomPanImage,
  CurrentImage,
  Navigation,
  SliderCarousel,
  Footer,
  Actions,
  Zoom,
  Rotate,
}

export default Gallery
