import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { CellProps, Column } from 'react-table'

import {
  ContactCanDeleteReponse,
  ContactQueryResponse,
} from 'services/contact/types'
import { Paging } from 'services/types'
import { ContactDriver } from 'services/contact'
import { useMakeCall } from 'services/attendance'

import { maskedDateTime } from 'utilities/date'

import { useToggle, useUserInfo } from 'shared/hooks'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import {
  Button,
  Modal,
  Toast,
  Table,
  TableRef,
  Input,
  Droplist,
  Icon,
} from 'components'

import { Filters } from './components'

import { ToastType } from 'components/Toast/types'
import { SearchContact, searchContactSchema } from './schemas/searchContact'

import { ReactComponent as DeleteIcon } from 'assets/svg/close.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { handleFormatPhone } from 'domains/occurrence/screens/Attendance/utils'

import classes from './Contacts.module.scss'
import { useCustomerContext } from '../CustomerManagementTabs/CustomerProvider'

const handleWhatsapp = (phoneNumber: string): Window | null => {
  return window.open(`https://wa.me/+55${phoneNumber}`, '_blank')
}

const columns: Column<ContactQueryResponse>[] = [
  { id: 'nome', Header: 'Nome', accessor: (contact) => contact?.name },
  { id: 'role', Header: 'Função', accessor: (contact) => contact?.role || '-' },
  {
    id: 'telephone',
    Header: 'Telefone',
    accessor: (contact) => contact,
    Cell: (cell: CellProps<ContactQueryResponse>) => {
      const { phones } = cell.value as ContactQueryResponse
      const phone = phones?.reduce((a, b) =>
        b.priorityOrder < a.priorityOrder ? b : a,
      )
      const { userInfo } = useUserInfo()
      const { mutate: call } = useMakeCall()
      const phoneFormatted = `${phone?.provinceCode || ''}${phone?.number}`
      return phones ? (
        <Droplist
          trigger={
            <span className={classes.phone}>
              <Icon name="phone" width={10} />
              {` (${phones[0].provinceCode}) ${handleFormatPhone(
                phones[0].number.toString(),
              )}`}
            </span>
          }
        >
          <button
            className={classes.dropDownItem}
            onClick={() => {
              call({
                phoneNumber: phoneFormatted,
                phoneExtension: userInfo?.phoneExtension,
              })
            }}
          >
            Ligar para o número
          </button>
          <button
            className={classes.dropDownItem}
            onClick={() => handleWhatsapp(phoneFormatted)}
          >
            Chat via Whatsapp
          </button>
        </Droplist>
      ) : (
        ''
      )
    },
  },
  {
    id: 'email',
    Header: 'E-mail',
    accessor: (contact) => contact.email || '-',
  },
  {
    id: 'createdAt',
    Header: 'Cadastrado em',
    accessor: (contact) => maskedDateTime(contact.createdAt),
  },
]

export const Contacts = () => {
  const { customer, account: accountContext } = useCustomerContext()
  const contactModal = useToggle()
  const cannotDeleteContactModal = useToggle()
  const deleteContactModal = useToggle()
  const onDeleteLoader = useToggle()

  const tableRef = useRef<TableRef>(null)
  const location = useLocation()
  const navigate = useNavigate()

  const { getFilters, setFilters } =
    usePersistentFilters<SearchContact>('contacts')

  const form = useForm<SearchContact>({
    resolver: joiResolver(searchContactSchema),
    defaultValues: getFilters(),
  })

  const { register, handleSubmit, getValues } = form

  const name = getValues('name')
  const phone = getValues('phone')
  const email = getValues('email')

  const [partitionNames, setPartitionNames] = useState<string>('')
  const [contactToDelete, setContactToDelete] =
    useState<ContactCanDeleteReponse>({
      contactId: '',
      name: '',
      partitions: [],
      hasControlDevice: false,
    })

  const toast = useToggle()
  const filtersProps = useToggle()
  const [toastProps, setToastProps] = useState<{
    message: string
    type?: ToastType
  }>({
    message: '',
  })

  const hasFilter = useMemo(() => {
    const appliedFilters = getFilters()

    return !!(appliedFilters?.phone || appliedFilters?.email)
  }, [getFilters])

  const handleFetchPage = useCallback(
    async (pagination: Partial<Paging>) => {
      try {
        const contacts = await ContactDriver.query({
          customerId: customer?.id || '',
          ...pagination,
          ...(name && { name }),
          ...(email && { email }),
          ...(phone && {
            number: Number(phone?.substring(5).replace('-', '')),
            provinceCode: Number(phone?.substring(1, 3)),
            countryCode: 55,
          }),
          sort: 'name',
          sortType: 'ASC',
        })
        return contacts
      } catch (error) {
        console.error(error)
      }
    },
    [email, name, phone],
  )

  const onDeleteContact = async (contactId: string) => {
    try {
      onDeleteLoader.show()
      await ContactDriver.delete(contactId)
      tableRef.current?.handleFetchPage()
      setToastProps({
        message: 'Contato excluído com sucesso.',
      })
    } catch {
      setToastProps({
        message: 'Erro ao excluir contato.',
        type: 'alert',
      })
    } finally {
      onDeleteLoader.hide()
      deleteContactModal.hide()
      handleFetchPage({ offset: 0, recordsPerPage: 15 })
      toast.show()
    }
  }

  const getCanDeleteContactResponse = async (contact: ContactQueryResponse) => {
    try {
      const response = await ContactDriver.canDelete(contact.id)
      setContactToDelete({ ...response.data, name: contact.name })
      if (response.data.hasControlDevice || response.data.partitions?.length) {
        cannotDeleteContactModal.show()
      } else if (response.data.contactId) {
        deleteContactModal.show()
      }
    } catch (error) {
      console.log('Error to get if contact can be deleted', error)
    }
  }

  const renderCannotDeleteContactModalMessage = (): string => {
    let message = ''
    if (contactToDelete.hasControlDevice) {
      message =
        'Este contato está vinculado a um controle remoto. Para excluí-lo, desvincule o controle remoto do contato.'
    } else if (contactToDelete.partitions?.length) {
      message = `Este contato é o único contato usuário vinculado às seguintes partições: ${partitionNames}.`
    }
    return message
  }

  useEffect(() => {
    // format contact partition names
    if (contactToDelete.partitions?.length) {
      const formattedNames = contactToDelete.partitions
        .map((partition) => `"${partition.name}"`)
        .join(', ')
      setPartitionNames(formattedNames)
    }
  }, [contactToDelete])

  return (
    <div className={classes.container}>
      <FormProvider {...form}>
        <Filters
          isVisible={filtersProps.isVisible}
          onClose={filtersProps.hide}
          onApplyFilters={handleSubmit((data) => {
            if (name) {
              form.setValue('name', undefined)
            }
            setFilters(data)
            tableRef.current?.handleFetchPage()
            navigate({ state: '?page=1' })
            filtersProps.hide()
          })}
        />
        <form
          onSubmit={handleSubmit(() => {
            tableRef?.current?.handleFetchPage()
          })}
        >
          <div className={classes.actions}>
            <Input
              id="name"
              label="Nome do contato"
              placeholder="Digite o nome do contato"
              {...register('name')}
              autoComplete="off"
              disabled={filtersProps?.isVisible}
            />
            <Button
              buttonTitle="Pesquisar"
              type="primary"
              htmlType="submit"
              disabled={filtersProps?.isVisible}
            />
            <Button
              className={classes.actionsAddButton}
              buttonTitle="Novo contato"
              icon={PlusIcon}
              type="secondary"
              onClick={() => {
                navigate(`/contact/${accountContext?.id}`, {
                  state: { background: location },
                })
              }}
            />
            <Button
              buttonTitle={hasFilter ? 'Editar filtros' : 'Adicionar filtros'}
              type="tertiary"
              icon={FiltersIcon}
              onClick={filtersProps.show}
            />
          </div>
        </form>
      </FormProvider>

      <Table
        columns={columns}
        fetcher={handleFetchPage}
        paginated
        innerRef={tableRef}
        resetPagination={false}
        actions={[
          {
            label: 'Editar',
            Icon: EditIcon,
            handler: (contact) => {
              navigate(`/contact/${accountContext?.id}/${contact.id}`, {
                state: { background: location },
              })
              contactModal.show()
            },
          },
          {
            label: 'Excluir',
            Icon: DeleteIcon,
            handler: (contact) => {
              getCanDeleteContactResponse(contact)
            },
          },
        ]}
      />

      <Modal
        simple
        title="Este contato não pode ser excluído."
        className={classes.cannotDeleteContactModal}
        isVisible={cannotDeleteContactModal.isVisible}
        onClose={cannotDeleteContactModal.hide}
      >
        <p className={classes.cannotDeleteContactModalText}>
          {renderCannotDeleteContactModalMessage()}
        </p>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="primary"
            id="btn-close"
            onClick={cannotDeleteContactModal.hide}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        simple
        title="Exclusão de contato"
        className={classes.deleteContactModal}
        isVisible={deleteContactModal.isVisible}
        onClose={deleteContactModal.hide}
      >
        <p className={classes.deleteContactModalText}>
          Você deseja excluir o contato <strong>{contactToDelete?.name}</strong>
          ? Ao prosseguir, este contato será removido de todas as contas ao qual
          se encontra vinculado
        </p>
        <p>Esta ação não poderá ser desfeita.</p>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Cancelar"
            id="cancel-delete-button"
            type="secondary"
            onClick={deleteContactModal.hide}
          />
          <Button
            width="172px"
            buttonTitle="Excluir"
            id="delete-button"
            disabled={onDeleteLoader.isVisible}
            type="cancel-primary"
            onClick={() => onDeleteContact(contactToDelete.contactId)}
          />
        </Modal.Footer>
      </Modal>

      <Toast
        onClose={toast.hide}
        isVisible={toast.isVisible}
        type={toastProps.type}
      >
        {toastProps.message}
      </Toast>
    </div>
  )
}

export default Contacts
