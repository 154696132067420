import { useNavigate, useParams } from 'react-router-dom'

import { Breadcrumbs, ContainerScreen, Loader } from 'components'

import { convertSecondsToMinutes } from 'utilities/date'
import { useToast } from 'shared/hooks'

import { UpdateCentralPayload } from 'services/central/types'
import { useGetCentral, usePatchCentral } from 'services/central'
import ImageDriver from 'services/image'
import { CentralForm } from 'domains/customer/components/CentralForm'
import { Form } from 'domains/customer/components/CentralForm/types'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { useCustomerContext } from '../CustomerManagementTabs/CustomerProvider'

export const UpdateCentral = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const { centralId = '' } = useParams()

  const { mutate: mutatePatchCentral, status: statusPatchCentral } =
    usePatchCentral(centralId)

  const { data: centralInfo } = useGetCentral(centralId)

  const { customer, patrimony } = useCustomerContext()

  const submit = (data: Form) => {
    const payload: UpdateCentralPayload = {
      partitions: data.partitions?.map((partition) => ({
        id: partition.id,
        code: partition.code,
        name: partition.name,
        status: partition.status,
        keyNumber: partition?.keyNumber || '',
        externalId: partition?.externalId || '',
      })),
      central: {
        id: data.central.id,
        commandPassword: data.central?.commandPassword,
        modelId: data.central?.model?.id,
        versionCode: data.central.versionCode,
        topicGroup: data.central.topicGroup,
        code: data.central.code?.replace(/:/g, ''),
        externalId: data.central.externalId,
        modelDescription: data.central?.modelDescription,
        gprsModuleModelId: data.central?.gprsModuleModel?.id,
      },
      contacts: data.contacts?.map((contact) => ({
        contactId: contact.id,
        code: contact.code,
      })),
      monitoring: {
        ...(data?.monitoring?.autoTest?.active && {
          autoTest: {
            id: data.monitoring.autoTest.id,
            interval:
              data.monitoring.autoTest.interval?.type === 'minutes'
                ? data.monitoring.autoTest.interval?.time
                : convertSecondsToMinutes(
                    data.monitoring.autoTest.interval?.time ?? 0,
                  ),
            tolerance:
              data.monitoring.autoTest.tolerance?.type === 'minutes'
                ? data.monitoring.autoTest.tolerance?.time
                : convertSecondsToMinutes(
                    data.monitoring.autoTest.tolerance?.time ?? 0,
                  ),
            failEventRecurrenceInterval: 0,
            type: data.monitoring.autoTest.type,
          },
        }),
        ...(data?.monitoring?.keepAlive?.active && {
          keepAlive: {
            id: data.monitoring.keepAlive.id,
            interval:
              data.monitoring.keepAlive.interval?.type === 'minutes'
                ? data.monitoring.keepAlive.interval?.time
                : convertSecondsToMinutes(
                    data.monitoring.keepAlive.interval?.time ?? 0,
                  ),
            failEventRecurrenceInterval:
              data.monitoring.keepAlive.failEventRecurrenceInterval?.type ===
              'minutes'
                ? data.monitoring.keepAlive.failEventRecurrenceInterval?.time
                : convertSecondsToMinutes(
                    data.monitoring.keepAlive.failEventRecurrenceInterval
                      ?.time || 0,
                  ),
            tolerance: 0,
            type: data.monitoring.keepAlive.type,
          },
        }),
      },
    }

    mutatePatchCentral(payload, {
      onSuccess: async (response) => {
        if (data.image && customer && patrimony) {
          try {
            await ImageDriver.persist(data.image, {
              principalOwner: {
                id: String(response.centralId),
                name: 'Central',
              },
              owners: [
                {
                  id: customer.id,
                  name: 'Customer',
                },
                {
                  id: patrimony.id,
                  name: 'Patrimony',
                },
                {
                  id: data.account.id,
                  name: 'Account',
                },
                {
                  id: response.central.id,
                  name: 'Central',
                },
                {
                  id: response.central.id,
                  name: 'InstalledDevice',
                },
              ],
            })
          } catch (error) {
            console.debug('Erro ao cadastrar imagem')
          }
        }
        addToast({
          message: 'Central editada com sucesso.',
          type: 'success',
        })
        navigate(CustomerPaths('central', data.account.id))
      },
      onError: () =>
        addToast({
          message: 'Erro ao editar central. Tente novamente.',
          error: true,
          type: 'alert',
        }),
    })
  }

  if (!centralInfo || statusPatchCentral === 'pending')
    return <Loader isVisible />

  return (
    <ContainerScreen
      clickable
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: '/account/management/account' },
            { title: `${customer?.name}`, href: '/account/management/account' },
            {
              title: `${centralInfo ? 'EDIÇÃO DE CENTRAL' : 'NOVA CENTRAL'}`,
              href: '#',
            },
          ]}
        />
      }
    >
      <CentralForm onSubmit={submit} centralToUpdate={centralInfo} />
    </ContainerScreen>
  )
}
