import { ReactElement } from 'react'

import { ReactComponent as CableOn } from 'assets/svg/datacentraltype1ON.svg'
import { ReactComponent as CableOff } from 'assets/svg/datacentraltype1OFF.svg'
import { ReactComponent as WifiOn } from 'assets/svg/datacentraltype2ON.svg'
import { ReactComponent as WifiOff } from 'assets/svg/datacentraltype2OFF.svg'
import { ReactComponent as GsmOn } from 'assets/svg/datacentraltype3ON.svg'
import { ReactComponent as GsmOff } from 'assets/svg/datacentraltype3OFF.svg'
import { ReactComponent as CentralDisarmed } from 'assets/svg/centralDisarmed.svg'
import { ReactComponent as CentralArmedStay } from 'assets/svg/centralArmedStay.svg'
import { ReactComponent as CentralArmedLeave } from 'assets/svg/centralArmedLeave.svg'
import { ReactComponent as NoInfo } from 'assets/svg/default1.svg'

import { ReactComponent as CentralConnected } from 'domains/accounts/screens/Accounts/svg/centralConnected.svg'
import { ReactComponent as CentralDisconnected } from 'domains/accounts/screens/Accounts/svg/centralDisconnected.svg'
import {
  CentralFragment,
  CommunicationType,
  Partition,
} from 'services/account/types'

import { PartitionStatus } from 'services/patrimony/types'
import { v4 as uuid } from 'uuid'

import { ReactComponent as EmptyBattery } from 'utilities/central/svg/battery/colorfullEmptyBattery.svg'
import { ReactComponent as OneThirdBattery } from 'utilities/central/svg/battery/colorfullOneThirdBattery.svg'
import { ReactComponent as TwoThirdBattery } from 'utilities/central/svg/battery/colorfullTwoThirdBattery.svg'
import { ReactComponent as FullBattery } from 'utilities/central/svg/battery/colorfullFullBattery.svg'
import { ReactComponent as ConnectedBattery } from 'utilities/central/svg/battery/colorfullConnectedBattery.svg'

export const NO_INFO_TITLE = 'Sem informação'

export enum BATTERY_ICON_TITLES {
  ENERGY_CABLE = 'Central conectada ao cabo de energia',
  EMPTY_BATTERY = 'Sem bateria',
  ONE_THIRD_BATTERY = 'Um terço de bateria',
  TWO_THIRDS_BATTERY = 'Dois terços de bateria',
  FULL_BATERY = 'Bateria cheia',
}

export enum COMMUNICATION_ICON_TITLES {
  CABLE_ON = 'Central conectada ao cabo',
  CABLE_OFF = 'Central desconectada do cabo',
  WIFI_ON = 'Central conectada a rede wifi',
  WIFI_OFF = 'Central desconectada da rede wifi',
  GSM_ON = 'Central conectada via chip',
  GSM_OFF = 'Chip da central sem comunicação',
}

export type CentralCommunication = {
  communicationType?: CommunicationType
  wifiConnectionStatus?: boolean
  ethernetConnectionStatus?: boolean
  gprsConnectionStatus?: boolean
  isConnected: boolean
  isUbisafe: boolean
}

type GetCentralBatteryIconProps = {
  batteryLevel?: number
  isConnected: boolean
  batteryAlarmed?: boolean
}

export const getCentralBatteryIcon = ({
  batteryLevel,
  isConnected,
  batteryAlarmed = false,
}: GetCentralBatteryIconProps): JSX.Element => {
  if (isConnected) return <ConnectedBattery aria-label="connected-battery" />

  if (batteryAlarmed || Number(batteryLevel) < 25)
    return <EmptyBattery aria-label="battery-alarmed" />

  if (Number(batteryLevel) < 50)
    return <OneThirdBattery aria-label="oneThird-battery" />

  if (Number(batteryLevel) < 75)
    return <TwoThirdBattery aria-label="twoThird-battery" />

  return <FullBattery aria-label="full-battery" />
}

export const getCentralCommunicationIcon = ({
  communicationType,
  isConnected,
  isUbisafe,
  wifiConnectionStatus,
  ethernetConnectionStatus,
  gprsConnectionStatus,
}: CentralCommunication): ReactElement => {
  if (
    communicationType === CommunicationType.CABLE ||
    ethernetConnectionStatus
  ) {
    if (isConnected) {
      return <CableOn />
    } else {
      return <CableOff />
    }
  }

  if (communicationType === CommunicationType.WIFI || wifiConnectionStatus) {
    if (isConnected) {
      return <WifiOn />
    } else {
      return <WifiOff />
    }
  }

  if (communicationType === CommunicationType.GSM || gprsConnectionStatus) {
    if (isConnected) {
      return <GsmOn />
    } else {
      return <GsmOff />
    }
  }

  if (!isUbisafe) {
    if (isConnected) return <CentralConnected />

    return <CentralDisconnected />
  }

  return <NoInfo />
}

export enum CENTRAL_STATUS_ICON_TITLES {
  ARMED = 'Central armada em modo sair',
  ARMED_STAY = 'Central armada em modo ficar',
  DISARMED = 'Central desarmada',
}

export const getCentralStatusIcon = (
  central?: CentralFragment,
): JSX.Element => {
  let partition: Partition | undefined

  if (!central?.partitions) {
    return <NoInfo key={uuid()} />
  }

  // get central partition status by the business defined precedence
  partition = central.partitions.find(
    (partition) => partition.status === PartitionStatus.ARMED,
  )

  if (!partition) {
    partition = central.partitions.find(
      (partition) => partition.status === PartitionStatus.ARMED_STAY,
    )
  }

  if (!partition) {
    partition = central.partitions.find(
      (partition) => partition.status === PartitionStatus.DISARMED,
    )
  }

  // returning only one icon for the entire central status
  if (partition) {
    if (partition.status === PartitionStatus.DISARMED) {
      return <CentralDisarmed key={uuid()} />
    }

    if (partition.status === PartitionStatus.ARMED_STAY) {
      return <CentralArmedStay key={uuid()} />
    }

    if (partition.status === PartitionStatus.ARMED) {
      return <CentralArmedLeave key={uuid()} />
    }
  }

  return <NoInfo key={uuid()} />
}
