import {
  ComponentProps,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  cloneElement,
  ReactElement,
  MouseEvent,
} from 'react'
import ReactDOM from 'react-dom'

import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'

interface DrawerContextProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const DrawerContext = createContext<DrawerContextProps>({
  isOpen: false,
  onClose: () => null,
  onOpen: () => null,
})

const useDrawerContext = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider')
  }
  return context
}

interface RootProps extends ComponentProps<'div'> {
  isOpen?: boolean
  onClose?: () => void
}

const Root = ({ isOpen = false, onClose, ...props }: RootProps) => {
  const [open, setOpen] = useState(isOpen)

  const handleClose = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen)
    }
  }, [isOpen])

  return (
    <DrawerContext.Provider
      value={{
        isOpen: open,
        onClose: handleClose,
        onOpen: () => setOpen(true),
      }}
      {...props}
    />
  )
}

interface TriggerProps extends ComponentProps<'button'> {}

const Trigger = ({ onClick, children, ...props }: TriggerProps) => {
  const { onOpen } = useDrawerContext()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    onOpen()
  }
  return cloneElement(children as ReactElement, {
    onClick: handleClick,
    ...props,
  })
}

type CloseProps = ComponentProps<'button'>

const Close = ({
  onClick,
  children = (
    <IconButton>
      <Icon name="close-xlg" color="element-default" />
    </IconButton>
  ),
  ...props
}: CloseProps) => {
  const { onClose } = useDrawerContext()
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    onClose()
  }
  return cloneElement(children as ReactElement, {
    onClick: handleClick,
    ...props,
  })
}

interface ContentProps extends ComponentProps<'div'> {
  position?: 'left' | 'right'
}

const Content = ({ className, position = 'right', ...props }: ContentProps) => {
  const { isOpen } = useDrawerContext()
  return (
    isOpen &&
    ReactDOM.createPortal(
      <div
        className={joinClassNames(
          styles.drawer,
          styles[position],
          isOpen && styles.open,
          className,
        )}
        {...props}
      />,
      document.body,
    )
  )
}

const Title = ({ className, ...props }: ComponentProps<'div'>) => (
  <div {...props} className={joinClassNames(styles.title, className)} />
)

const Drawer = {
  Root,
  Trigger,
  Close,
  Content,
  Title,
}

export default Drawer
