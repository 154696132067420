import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Customer from '../GeneralInfo/GeneralInfo'
import Patrimony from '../Patrimonies/Patrimonies'
import Contacts from '../Contacts/Contacts'
import { Equipments } from '../Equipments/Equipments'
import History from '../History/History'
import { OfficeHoursList } from 'domains/customer/screens/OfficeHours/screens'
import { Patrol } from 'domains/Patrol'

import { Breadcrumbs, TabBar } from 'components'
import { Tabs } from 'components/Tabs'

import { CustomerPaths } from 'routes/constants/paths/customer'
import { TabData } from 'components/Tabs/types'

import styles from './CustomerManagementTabs.module.scss'
import { Central } from '../Central/Central'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { ReactComponent as ShopIcon } from 'assets/svg/shop.svg'
import { ReactComponent as Icon } from 'assets/svg/exclamationMessageBlue.svg'
import { useCustomerContext } from './CustomerProvider'

import Reports from '../Reports'

const tabData: TabData[] = [
  {
    id: '0',
    label: 'Horários de funcionamento',
    icon: <ShopIcon />,
    content: <OfficeHoursList />,
    path: 'officeHours',
  },
  {
    id: '1',
    label: 'Horários de ocorrências periódicas',
    icon: <Icon />,
    content: <Patrol />,
    path: 'patrol',
  },
]

export const CustomerManagement = () => {
  const location = useLocation()

  const { customer, account } = useCustomerContext()

  const navigate = useNavigate()
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const [tab] = location.pathname.split('/').reverse()
  const [activeTabPath, setActiveTabPath] = useState<string>(tab)

  useEffect(() => {
    setActiveTabPath(tab)
  }, [tab])

  return (
    <>
      <div className={styles.wrapperBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: '/account/list' },
            { title: customer?.name || '', href: '' },
          ]}
        />
      </div>

      <TabBar>
        <TabBar.Tab
          id="account"
          idSelected={activeTabPath}
          label="Cliente"
          onClick={() => {
            navigate(CustomerPaths('account', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <Customer />
        </TabBar.Tab>
        <TabBar.Tab
          id="history"
          idSelected={activeTabPath}
          label="Histórico"
          onClick={() => {
            navigate(CustomerPaths('history', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <History />
        </TabBar.Tab>
        <TabBar.Tab
          id="patrimonies"
          idSelected={activeTabPath}
          label="Patrimônios"
          onClick={() => {
            navigate(CustomerPaths('patrimonies', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <Patrimony />
        </TabBar.Tab>
        <TabBar.Tab
          id="central"
          idSelected={activeTabPath}
          label="Centrais"
          onClick={() => {
            navigate(CustomerPaths('central', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <Central />
        </TabBar.Tab>
        <TabBar.Tab
          id="contacts"
          idSelected={activeTabPath}
          label="Contatos"
          onClick={() => {
            navigate(CustomerPaths('contacts', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <Contacts />
        </TabBar.Tab>
        <TabBar.Tab
          id="equipments"
          idSelected={activeTabPath}
          label="Equipamentos"
          onClick={() => {
            navigate(CustomerPaths('equipments', account?.id || ''), {
              replace: true,
            })
          }}
        >
          <Equipments />
        </TabBar.Tab>
        <TabBar.Tab
          id="officeHours"
          idSelected={activeTabPath}
          label="Horários"
          onClick={() => {
            navigate(CustomerPaths('officeHours', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <Tabs tabs={tabData} />
        </TabBar.Tab>
        <TabBar.Tab
          id="reports"
          idSelected={activeTabPath}
          label="Relatórios"
          onClick={() => {
            navigate(CustomerPaths('reports', account?.id || ''), {
              replace: true,
            })
            clearAllLocalStorageFilters()
          }}
        >
          <Reports />
        </TabBar.Tab>
      </TabBar>
    </>
  )
}

export default CustomerManagement
