import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { Query, QueryResponse } from './types'

export const queryCentral = async (queryParams?: Query) => {
  const response = await HttpClient.get<QueryResponse>(
    buildUrl({ route: endpoints.query, queryParams }),
  )

  return response.data
}

export const useQueryCentral = (query?: Query) => {
  return useQuery({
    queryKey: ['query-central', query],
    queryFn: async () => await queryCentral(query),
  })
}
