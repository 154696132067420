import { useQuery } from '@tanstack/react-query'
import { PatrimonyDriver } from 'services/patrimony'
import { PartitionListResponse } from 'services/patrimony/types'
import { Result } from 'services/types'

type Callback = () => void
type UseGetPatrimonyPartitions = {
  patrimonyPartitions: Result<PartitionListResponse> | undefined
  isFetchingPatrimonyPartitions: boolean
}

const fetchPartitions = async (patrimonyId: string, onError?: Callback) => {
  let response: Result<PartitionListResponse> = {
    data: [],
    totalElements: 0,
  }

  try {
    response = await PatrimonyDriver.getPartitionsList(patrimonyId)

    return response
  } catch (error) {
    if (onError) onError()
  }
}

export const useGetPatrimonyPartitions = (
  patrimonyId: string,
  enabled: boolean,
  onError?: Callback,
): UseGetPatrimonyPartitions => {
  const {
    data: patrimonyPartitions,
    isFetching: isFetchingPatrimonyPartitions,
  } = useQuery({
    queryKey: ['equipments-partitions', patrimonyId],
    queryFn: () => fetchPartitions(patrimonyId, onError),
    enabled,
  })

  return { patrimonyPartitions, isFetchingPatrimonyPartitions }
}
