import React, { useCallback } from 'react'

import { useToast } from 'shared/hooks'

import { useCreateImage } from 'services/image'
import { useCreateDevice } from 'services/device'
import { DeviceResponse } from 'services/device/types'

import { Form } from '../types'
import { DeviceForm } from '../components'

import styles from './styles.module.scss'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'

const CreateDevice: React.FC = () => {
  const { addToast } = useToast()

  const { mutate: createImage, isPending: isCreateImage } = useCreateImage()
  const { mutate: createDevice, isPending: isCreateDevice } = useCreateDevice()
  const { customer, patrimony } = useCustomerContext()

  const handleCreateImage = useCallback(
    (image: File, installedDevice: DeviceResponse, accountId: string) => {
      const customerId = customer?.id || ''
      const patrimonyId = patrimony?.id || ''

      const { id, deviceTypeId, centralId } = installedDevice

      if (patrimonyId && customerId) {
        createImage(
          {
            image,
            payload: {
              principalOwner: {
                id,
                name: 'InstalledDevice',
              },
              owners: [
                {
                  id: customerId,
                  name: 'Customer',
                },
                {
                  id: patrimonyId,
                  name: 'Patrimony',
                },
                {
                  id: accountId,
                  name: 'Account',
                },
                {
                  id: centralId,
                  name: 'Central',
                },
                {
                  id: deviceTypeId,
                  name: 'Device',
                },
                {
                  id,
                  name: 'InstalledDevice',
                },
              ],
            },
          },
          {
            onSuccess: () => {
              addToast({
                message: 'Equipamento cadastrado com sucesso.',
                type: 'success',
              })
            },
            onError: () => {
              addToast({
                message: 'Erro ao cadastrar equipamento. Tente novamente.',
                type: 'alert',
              })
            },
          },
        )
      }
    },
    [addToast, createImage],
  )

  const onSubmit = useCallback(
    (data: Form) => {
      createDevice(
        {
          code: data.code.replace(/^0+/, ''),
          name: data.name,
          deviceStatus: true,
          modelId: data.model.id,
          centralId: data.centralId,
          contactId: data.contact?.id,
          deviceTypeId: data.device.id,
          deviceTypeCode: data.device.code,
          tags: data.tags,
          partitions: data.partitions.map((partition) => partition.id),
        },
        {
          onSuccess: (installedDevice) => {
            if (typeof data.image !== 'string' && !!data.image) {
              handleCreateImage(data.image[0], installedDevice, data.account.id)
            } else {
              addToast({
                message: 'Equipamento cadastrado com sucesso.',
                type: 'success',
              })
            }
          },
          onError: () => {
            addToast({
              message: 'Erro ao cadastrar equipamento. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [addToast, createDevice, handleCreateImage],
  )

  return (
    <main className={styles.container}>
      <DeviceForm
        onSubmit={onSubmit}
        isLoading={isCreateImage || isCreateDevice}
      />
    </main>
  )
}

export default CreateDevice
