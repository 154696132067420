import { Icon, IconButton, ModalV2, TabsV2 } from 'components'
import { EventTable, OccurrenceTable, SOTable } from './components'

import styles from './styles.module.scss'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

const AllHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleResetPageParam = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  useEffect(() => {
    handleResetPageParam()
  }, [])

  return (
    <>
      <ModalV2.Title className={styles.title}>
        Histórico
        <ModalV2.Close asChild>
          <IconButton>
            <Icon name="close-xlg" color="element-default" />
          </IconButton>
        </ModalV2.Close>
      </ModalV2.Title>
      <TabsV2.Root>
        <TabsV2.List>
          <TabsV2.Tab onClick={() => handleResetPageParam()}>
            Ordens de serviço
          </TabsV2.Tab>
          <TabsV2.Tab onClick={() => handleResetPageParam()}>
            Ocorrências
          </TabsV2.Tab>
          <TabsV2.Tab onClick={() => handleResetPageParam()}>
            Eventos
          </TabsV2.Tab>
        </TabsV2.List>
        <TabsV2.Panels className={styles.container}>
          <TabsV2.Panel>
            <SOTable />
          </TabsV2.Panel>
          <TabsV2.Panel>
            <OccurrenceTable />
          </TabsV2.Panel>
          <TabsV2.Panel>
            <EventTable />
          </TabsV2.Panel>
        </TabsV2.Panels>
      </TabsV2.Root>
    </>
  )
}

export default AllHistory
