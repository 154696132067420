import {
  FC,
  ReactElement,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Breadcrumbs, Droplist, EmptyState, Loader } from 'components'
import { ReactComponent as CamIcon } from 'assets/svg/cameraIcon.svg'
import { ReactComponent as GridIcon } from './svg/grid.svg'
import { ReactComponent as TwoColumnGridIcon } from './svg/twoColumnGrid.svg'
import { ReactComponent as ThreeColumnGridIcon } from './svg/threeColumnGrid.svg'
import { ReactComponent as FourColumnGridIcon } from './svg/fourColumnGrid.svg'
import { ReactComponent as SixColumnGridIcon } from './svg/sixColumnGrid.svg'

import { useToast, useUserInfo } from 'shared/hooks'
import { Controls, PlayerTimeline, VideoPlayer } from './components'

import { useGetOccurrence, useGetStreams, usePostScreenshots } from './hooks'
import { CapturedFile } from './types'
import { useCreateInterventation } from 'services/displacementMap'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'

type GridLayout = 'twoColumns' | 'threeColumns' | 'fourColumns' | 'sixColumns'

const CFTV: FC = () => {
  const toast = useToast()
  const { userInfo } = useUserInfo()
  const { occurrenceId } = useParams()

  const [playing, setPlaying] = useState(true)

  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [offlineCamHeight, setOfflineCamHeight] = useState(0)

  const gridRef = useRef<HTMLDivElement>(null)
  const [playerRefs, setPlayerRefs] = useState<RefObject<HTMLVideoElement>[]>(
    [],
  )

  const { data: occurrence } = useGetOccurrence(occurrenceId, 10000)
  const { data: streamData, isFetching: isFetchingStreams } = useGetStreams(
    occurrence?.accountId,
  )
  const { mutate: mutateScreenshots, isPending: isPendingPostScreenshots } =
    usePostScreenshots()
  const { mutate: mutateIntervention, isPending: isPendingPostIntervention } =
    useCreateInterventation(occurrence?.id)

  const handleOfflineCam = () => {
    if (playerRefs[0]?.current?.offsetHeight) {
      setOfflineCamHeight(Number(playerRefs[0].current.offsetHeight))
    }
  }

  const handleGridLayout = (layout: GridLayout) => {
    const regex = /\b(?:twoColumns|threeColumns|fourColumns|sixColumns)\b/
    if (gridRef.current) {
      Object.keys(styles).forEach((className) => {
        if (regex.test(className)) {
          gridRef.current?.classList.remove(styles[className])
        }
      })
      gridRef.current.classList.add(styles[layout])
    }
    handleOfflineCam()
  }

  const gridLayouts: { layout: GridLayout; icon: ReactElement }[] = [
    {
      layout: 'twoColumns',
      icon: (
        <TwoColumnGridIcon
          key="twoColumns"
          onClick={() => handleGridLayout('twoColumns')}
        />
      ),
    },
    {
      layout: 'threeColumns',
      icon: (
        <ThreeColumnGridIcon
          key="threeColumns"
          onClick={() => handleGridLayout('threeColumns')}
        />
      ),
    },
    {
      layout: 'fourColumns',
      icon: (
        <FourColumnGridIcon
          key="fourColumns"
          onClick={() => handleGridLayout('fourColumns')}
        />
      ),
    },
    {
      layout: 'sixColumns',
      icon: (
        <SixColumnGridIcon
          key="sixColumns"
          onClick={() => handleGridLayout('sixColumns')}
        />
      ),
    },
  ]

  const captureScreenShots = (
    videoRef?: RefObject<HTMLVideoElement>,
    sid?: string,
  ): Promise<CapturedFile[]> => {
    return new Promise<CapturedFile[]>((resolve, reject) => {
      const capturedFiles: CapturedFile[] = []
      const promises: Promise<void>[] = []

      let refsToCaptureScreenShots: RefObject<HTMLVideoElement>[] = playerRefs

      if (videoRef) {
        refsToCaptureScreenShots = []
        refsToCaptureScreenShots.push(videoRef)
      }

      if (
        occurrence &&
        refsToCaptureScreenShots.length &&
        streamData?.streams.length
      ) {
        refsToCaptureScreenShots.forEach((videoRef, index) => {
          if (videoRef.current) {
            const camSid = streamData.streams[index].sid || ''

            promises.push(
              new Promise<void>((resolve) => {
                const videoElement = videoRef.current

                if (videoElement) {
                  const canvas = document.createElement('canvas')
                  canvas.width = videoElement.videoWidth
                  canvas.height = videoElement.videoHeight
                  const ctx = canvas.getContext('2d')

                  if (ctx) {
                    ctx.drawImage(
                      videoElement,
                      0,
                      0,
                      canvas.width,
                      canvas.height,
                    )

                    canvas.toBlob(
                      (blob) => {
                        if (blob) {
                          const file = new File(
                            [blob],
                            `screenshot_${index}.jpeg`,
                            {
                              type: 'image/jpeg',
                            },
                          )
                          capturedFiles.push({
                            file,
                            sid: sid || camSid,
                            occurrenceId: occurrence?.id,
                          })
                        }
                        resolve()
                      },
                      'image/jpeg',
                      0.75,
                    )
                  } else {
                    resolve()
                  }
                }
              }),
            )
          }
        })
      }

      Promise.all(promises)
        .then(() => {
          resolve(capturedFiles)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handlePersistScreenShots = async (
    videoRef?: RefObject<HTMLVideoElement>,
    sid?: string,
  ) => {
    const screenShots = (await captureScreenShots(videoRef, sid)) || []

    mutateScreenshots(screenShots, {
      onSuccess: (imageIds) => {
        mutateIntervention({
          userId: userInfo.id,
          title: 'Captura de imagens do CFTV',
          tags: [{ name: 'capturaImagem' }],
          typeName: 'SEND_IMAGE',
          attributes: {
            imageIds,
          },
        })

        toast.addToast({
          message: 'Os frames capturados foram salvos na galeria.',
          type: 'success',
        })
      },
      onError: () =>
        toast.addToast({
          message: 'Não foi possível capturar os frames. Tenta novamente.',
          type: 'alert',
          error: true,
        }),
    })
  }

  const handleSeek = useCallback(
    (time: number, slide?: boolean) => {
      const newTime = slide ? time : currentTime + time
      if (newTime < duration && playerRefs.length) {
        setCurrentTime(newTime)
        playerRefs.forEach((playerRef) => {
          if (!playerRef.current) return
          playerRef.current.currentTime = newTime
        })
      }
    },
    [playerRefs, duration, currentTime],
  )

  const hasOnlineCams = useMemo(
    () =>
      Boolean(streamData?.streams?.map((stream) => stream.status === 2).length),
    [streamData],
  )

  const handleRender = () => {
    if (isFetchingStreams) return 'loader'
    if (occurrence?.stateName === 'FINISHED' || !occurrence)
      return 'waitingOccurrence'
    return 'videos'
  }

  return (
    <main className={styles.main}>
      <Breadcrumbs
        items={[
          {
            title: `Ocorrência #${String(occurrence?.number)}`,
            href: `/occurrence/attendance/${occurrence?.id}`,
          },
          { title: 'CFTV', href: '/#' },
        ]}
      />
      <div>
        <Loader
          isVisible={isPendingPostScreenshots || isPendingPostIntervention}
        />
        ,
        <section
          ref={gridRef}
          className={[styles.videosContainer, styles.sixColumns].join(' ')}
        >
          {
            {
              waitingOccurrence: (
                <Loader isVisible>
                  <div className={styles.waitingOccurrence}>
                    <p>Aguardando nova Ocorrência</p>
                    <span>Isto pode demorar.</span>
                  </div>
                </Loader>
              ),
              loader: <Loader isVisible />,
              videos: (
                <>
                  {streamData?.streams.length ? (
                    streamData?.streams
                      .sort((a, b) =>
                        a.status === 2 && b.status !== 2 ? -1 : 1,
                      )
                      .map((stream, index) =>
                        stream.status === 2 ? (
                          <div key={stream.sid}>
                            <VideoPlayer
                              stream={{
                                playing,
                                sid: stream.sid,
                                ubistreamToken: streamData.ubistreamToken,
                                url: stream.url,
                              }}
                              getRef={(playerRef) => {
                                if (
                                  playerRef &&
                                  !playerRefs.includes(playerRef)
                                ) {
                                  setPlayerRefs((prevState) => [
                                    ...prevState,
                                    playerRef,
                                  ])
                                }
                              }}
                              onScreenShot={handlePersistScreenShots}
                              onProgress={(time) => {
                                if (index === 0) setCurrentTime(time)
                              }}
                              onDuration={(time) => {
                                if (index === 0) setDuration(time)
                                handleOfflineCam()
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            key={stream.sid}
                            className={styles.offline}
                            style={{
                              height: hasOnlineCams
                                ? `${offlineCamHeight || 200}px`
                                : '200px',
                            }}
                          >
                            <div>Câmera offline</div>
                            <div>{String(stream.sid)}</div>
                          </div>
                        ),
                      )
                  ) : (
                    <div className={styles.emptySate}>
                      <EmptyState type="EmptyDataFromBFF" />
                    </div>
                  )}
                </>
              ),
            }[handleRender()]
          }
        </section>
      </div>
      {!!streamData?.streams.length && (
        <footer className={styles.controlsContainer}>
          <Controls
            onSeek={handleSeek}
            onPlayPause={() => setPlaying((prevState) => !prevState)}
            playing={playing}
          />
          <PlayerTimeline
            handleSeek={(e) => handleSeek(parseFloat(e.target.value), true)}
            occurrenceCreatedAt={occurrence?.createdAt || 0}
            camState={streamData.streams[0].state === 2 ? 'Play' : 'Recording'}
            currentTime={currentTime}
            duration={duration}
          />
          <div className={styles.buttons}>
            <button onClick={() => handlePersistScreenShots()}>
              <CamIcon width={16} height={14} />
            </button>
            <Droplist
              trigger={
                <GridIcon
                  className={styles.gridButton}
                  width={45}
                  height={45}
                />
              }
            >
              <div className={styles.gridOptions}>
                {gridLayouts.map((grid) => grid.icon)}
              </div>
            </Droplist>
          </div>
        </footer>
      )}
    </main>
  )
}

export default CFTV
