const PREFIX = '/account/management'

const routeEntity = {
  account: 'account',
  patrimonies: 'patrimonies',
  equipments: 'equipments',
  central: 'central',
  contacts: 'contacts',
  history: 'history',
  officeHours: 'officeHours',
  reports: 'reports',
}

type RouteEntity = keyof typeof routeEntity

export const CustomerPaths = (routeEntity: RouteEntity, accountId: string) => {
  let path = `${PREFIX}/${accountId}/${routeEntity}`

  if (
    routeEntity === 'contacts' ||
    routeEntity === 'history' ||
    routeEntity === 'officeHours'
  ) {
    path += `?page=1`
  }

  return path
}
