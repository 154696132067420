import { ReactNode, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import {
  Accounts,
  CustomerManagement,
  UserManagement,
  ChangePasssword,
  Profile,
  PasswordManagementScreen,
  Login,
} from '../domains/index'

import { MainContainer } from './components'
import { Home, PageNotFound } from './screens'

import { UbideskPermissions } from './types'
import { usePermissions } from 'shared/hooks'
import { SearchServiceOrders } from 'domains/serviceOrders/screens/list/SearchServiceOrders/SearchServiceOrders'
import {
  ServiceOrdersValidation,
  UnscheduledServiceOrdersScreen,
  InProgressServiceOrders,
  TechnicianSchedulesPause,
} from 'domains/serviceOrders/screens'

import { Vehicles } from 'domains/vehicles/screens/Vehicles/Vehicles'
import { CreateVehicle } from 'domains/vehicles/screens/CreateVehicle/CreateVehicle'
import { EditVehicle } from 'domains/vehicles/screens/EditVehicle/EditVehicle'
import { List as CentralRefusedList } from 'domains/centralRefused/screens'
import './Routes.scss'
import {
  Occurrences,
  OccurrenceManagement,
  SearchOccurrence,
  DisplacementMap,
} from 'domains/occurrence/screens'

import { CreateUser } from 'domains/user/screens/CreateUser/CreateUser'
import { UpdateUser } from 'domains/user/screens/UpdateUser/UpdateUser'
import {
  AttendanceProfile,
  CreateAttendanceProfile,
  UpdateAttendanceProfile,
} from 'domains/attendanceProfile/screens'
import {
  AttendancePolicy,
  CreateAttendancePolicy,
  UpdateAttendancePolicy,
} from 'domains/attendancePolicy/screens'
import { CreateHoliday } from 'domains/holiday/screens/CreateHoliday/CreateHoliday'
import { UpdateHoliday } from 'domains/holiday/screens/UpdateHoliday/UpdateHoliday'

import { Holidays } from 'domains/holiday/screens/Holidays'

import {
  CreateOfficeHours,
  UpdateOfficeHours,
  OfficeHourDetails,
} from 'domains/customer/screens/OfficeHours/screens'
import Attendance from 'domains/occurrence/screens/Attendance'
import { CreateAccount } from 'domains/accounts/screens/CreateAccount/CreateAccount'
import { CreateCentral } from 'domains/customer/screens/CreateCentral/CreateCentral'
import { UpdateCentral } from 'domains/customer/screens/UpdateCentral/UpdateCentral'
import {
  CreateDevice,
  UpdateDevice,
} from 'domains/customer/screens/Equipments/containers'
import CFTV from 'domains/occurrence/screens/CFTV'
import {
  UpdatePatrol,
  CreatePatrol,
} from 'domains/customer/screens/Patrol/screens'
import { parseCookies } from 'nookies'
import { ContactModal } from 'domains/customer/screens/Contacts/components'
import { Create, Details, Update } from 'domains/serviceOrder/screens'
import { CustomerProvider } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'

interface PrivateRouteProps {
  element: ReactNode | null
  canAccess: boolean
}

const handlePrivateRoute = ({ element, canAccess }: PrivateRouteProps) =>
  canAccess && element

interface StartedSessionContainerProps {
  children: ReactNode
}

const StartedSessionContainer = ({
  children,
}: StartedSessionContainerProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { userId } = parseCookies()

  useEffect(() => {
    if (!userId && location.pathname !== '/login') {
      navigate('/login', { state: { from: location } })
    }
  }, [])

  return children
}

export const ContainerRoutes = () => {
  const location = useLocation()
  const { hasAccess } = usePermissions()
  const background = location.state?.background

  return (
    <>
      <Routes location={background || location}>
        <Route path="/login" element={<Login />} />
        <Route
          path="/reset-password/*"
          element={<PasswordManagementScreen />}
        />
        <Route path="/save-password/*" element={<PasswordManagementScreen />} />

        <Route
          path="/"
          element={
            <StartedSessionContainer>
              <MainContainer />
            </StartedSessionContainer>
          }
        >
          <Route index element={<Home />} />

          <Route
            path="userServiceOrders"
            element={handlePrivateRoute({
              element: <SearchServiceOrders />,
              canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
            })}
          />

          <Route path="patrol/:accountId/*">
            <Route
              path="create"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <CreatePatrol />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="edit"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <UpdatePatrol />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
          </Route>

          <Route path="config">
            <Route
              path="centralRefused"
              element={handlePrivateRoute({
                element: <CentralRefusedList />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_CENTRAL_REFUSED_CONNECTIONS,
                ),
              })}
            />
            <Route
              path="attendanceProfiles"
              element={handlePrivateRoute({
                element: <AttendanceProfile />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_ATTENDANCE_PROFILE,
                ),
              })}
            />
            <Route
              path="attendanceProfile/update"
              element={handlePrivateRoute({
                element: <UpdateAttendanceProfile />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_ATTENDANCE_PROFILE,
                ),
              })}
            />
            <Route
              path="attendanceProfile/create"
              element={handlePrivateRoute({
                element: <CreateAttendanceProfile />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_ATTENDANCE_PROFILE,
                ),
              })}
            />

            <Route
              path="attendancePolicy"
              element={handlePrivateRoute({
                element: <AttendancePolicy />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_ATTENDANCE_POLICY,
                ),
              })}
            />
            <Route
              path="attendancePolicy/update"
              element={handlePrivateRoute({
                element: <UpdateAttendancePolicy />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_ATTENDANCE_POLICY,
                ),
              })}
            />
            <Route
              path="attendancePolicy/create"
              element={handlePrivateRoute({
                element: <CreateAttendancePolicy />,
                canAccess: hasAccess(
                  UbideskPermissions.CONFIG_ATTENDANCE_POLICY,
                ),
              })}
            />

            <Route
              path="vehicles"
              element={handlePrivateRoute({
                element: <Vehicles />,
                canAccess: hasAccess(UbideskPermissions.CONFIG_VEHICLE),
              })}
            />
            <Route
              path="vehicle/new"
              element={handlePrivateRoute({
                element: <CreateVehicle />,
                canAccess: hasAccess(UbideskPermissions.CONFIG_VEHICLE),
              })}
            />
            <Route
              path="vehicle/edit/:vehicleId"
              element={handlePrivateRoute({
                element: <EditVehicle />,
                canAccess: hasAccess(UbideskPermissions.CONFIG_VEHICLE),
              })}
            />

            <Route
              path="userManagement"
              element={handlePrivateRoute({
                element: <UserManagement />,
                canAccess: [
                  UbideskPermissions.CONFIG_USER,
                  UbideskPermissions.CONFIG_USER_WRITE,
                ].some((permission) => hasAccess(permission)),
              })}
            />
            <Route
              path="userManagement/create"
              element={handlePrivateRoute({
                element: <CreateUser />,
                canAccess: [
                  UbideskPermissions.CONFIG_USER,
                  UbideskPermissions.CONFIG_USER_WRITE,
                ].some((permission) => hasAccess(permission)),
              })}
            />
            <Route
              path="userManagement/update/:userId"
              element={handlePrivateRoute({
                element: <UpdateUser />,
                canAccess: [
                  UbideskPermissions.CONFIG_USER,
                  UbideskPermissions.CONFIG_USER_WRITE,
                ].some((permission) => hasAccess(permission)),
              })}
            />

            <Route
              path="holiday"
              element={handlePrivateRoute({
                element: <Holidays />,
                canAccess: hasAccess(UbideskPermissions.CONFIG_HOLIDAY),
              })}
            />
            <Route
              path="holiday/update"
              element={handlePrivateRoute({
                element: <UpdateHoliday />,
                canAccess: hasAccess(UbideskPermissions.CONFIG_HOLIDAY),
              })}
            />
            <Route
              path="holiday/create"
              element={handlePrivateRoute({
                element: <CreateHoliday />,
                canAccess: hasAccess(UbideskPermissions.CONFIG_HOLIDAY),
              })}
            />
          </Route>

          <Route path="so">
            <Route
              path="unscheduled"
              element={handlePrivateRoute({
                element: <UnscheduledServiceOrdersScreen />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
            <Route
              path="search"
              element={handlePrivateRoute({
                element: <SearchServiceOrders />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
            <Route
              path="in_validation"
              element={handlePrivateRoute({
                element: <ServiceOrdersValidation />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
            <Route
              path="inProgressServiceOrders"
              element={handlePrivateRoute({
                element: <InProgressServiceOrders />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
            <Route
              path="calendarBlocking"
              element={handlePrivateRoute({
                element: <TechnicianSchedulesPause />,
                canAccess: [
                  UbideskPermissions.SERVICE_ORDER,
                  UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE,
                ].some((permission) => hasAccess(permission)),
              })}
            />
            <Route
              path=":serviceOrderId"
              element={handlePrivateRoute({
                element: <Details />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
            <Route
              path="create"
              element={handlePrivateRoute({
                element: <Create />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
            <Route
              path="update/:serviceOrderId"
              element={handlePrivateRoute({
                element: <Update />,
                canAccess: hasAccess(UbideskPermissions.SERVICE_ORDER),
              })}
            />
          </Route>
          <Route path="account/:accountId/officeHours/">
            <Route
              path=":officeHoursId"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <OfficeHourDetails />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="create"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <CreateOfficeHours />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="edit/:officeHoursId"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <UpdateOfficeHours />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
          </Route>
          <Route path="equipments">
            <Route
              path="update/:deviceId"
              element={handlePrivateRoute({
                element: <UpdateDevice />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="create"
              element={handlePrivateRoute({
                element: <CreateDevice />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
          </Route>
          <Route path="central/:accountId/*">
            <Route
              path="update/:centralId"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <UpdateCentral />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="create"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <CreateCentral />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
          </Route>
          <Route path="account">
            <Route
              path="list"
              element={handlePrivateRoute({
                element: <Accounts />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="new"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <CreateAccount />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />

            <Route
              path="management/:accountId/*"
              element={handlePrivateRoute({
                element: (
                  <CustomerProvider>
                    <CustomerManagement />
                  </CustomerProvider>
                ),
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
          </Route>
          <Route path="occurrence">
            <Route
              path="list"
              element={handlePrivateRoute({
                element: <Occurrences />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="search"
              element={handlePrivateRoute({
                element: <SearchOccurrence />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="displacementMap"
              element={handlePrivateRoute({
                element: <DisplacementMap />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route path="management" element={<OccurrenceManagement />} />
            <Route
              path="attendance/:occurrenceId"
              element={handlePrivateRoute({
                element: <Attendance />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
            <Route
              path="cftv/:occurrenceId"
              element={handlePrivateRoute({
                element: <CFTV />,
                canAccess: hasAccess(UbideskPermissions.ACCOUNT),
              })}
            />
          </Route>

          <Route path="/user-info" element={<Profile />} />
          <Route path="/change-password" element={<ChangePasssword />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      {background && (
        <Routes>
          <Route
            path="contact/:accountId/:contactId?"
            element={
              <CustomerProvider>
                <ContactModal />
              </CustomerProvider>
            }
          />
        </Routes>
      )}
    </>
  )
}

export default ContainerRoutes
