import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useToast, useUserInfo } from 'shared/hooks'
import {
  Checkbox,
  DropDown,
  EmptyStateV2,
  ErrorState,
  Icon,
  IconButton,
} from 'components'
import {
  InterventionTypeName,
  useCreateIntervention,
  useGetInterventions,
} from 'services/serviceOrderV2'
import { handleRenderState } from 'utilities/handleRenderState'

import Loading from './Loading'
import styles from './styles.module.scss'
import { Intervention, Field, Form } from './components'

type Option = 'PRIVATE' | 'PUBLIC' | InterventionTypeName

interface Filter {
  label: string
  value: Option
}

export const filterOptions: Filter[] = [
  {
    label: 'Ligação',
    value: 'PHONE_CALL',
  },
  {
    label: 'Whatsapp',
    value: 'SEND_MESSAGE',
  },
  {
    label: 'Edição de OS',
    value: 'SO_CHANGE',
  },
  {
    label: 'Notas púlbicas',
    value: 'PUBLIC',
  },
  {
    label: 'Notas internas',
    value: 'PRIVATE',
  },
  {
    label: 'Envio de imagens',
    value: 'SEND_IMAGE',
  },
  {
    label: 'Alteração de status',
    value: 'STATUS_CHANGE',
  },
]

const filterDefaultValue = filterOptions.map((option) => option.value)

const TimeLine = () => {
  const { addToast } = useToast()
  const { serviceOrderId } = useParams()

  const [filters, setFilters] = useState<Option[]>(filterDefaultValue)

  const {
    userInfo: { id: userId },
  } = useUserInfo()

  const { mutate } = useCreateIntervention(serviceOrderId)
  const { data, isLoading, isError } = useGetInterventions(serviceOrderId)

  const onSubmit = useCallback(
    (data: Form) => {
      const tags = data.tags?.map(({ name }) => ({
        name,
      }))

      mutate(
        {
          typeName: 'MANUAL_RECORD',
          note: {
            userId,
            note: data.note,
            images: data.images,
            hidden: data.hidden,
            ...(tags?.length && {
              tags,
            }),
          },
          attributes: {
            hidden: data.hidden,
          },
          userId,
        },
        {
          onSuccess: () => {
            addToast({
              message: 'Nota interna adicionada com sucesso.',
              type: 'success',
            })
          },
          onError: () => {
            addToast({
              message: 'Erro ao adicionar nota. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [addToast, mutate, userId],
  )

  const handleFilterInterventions = () => {
    if (filters.length) {
      return data?.data.filter((intervention) => {
        return [...filters, 'SO_CREATED'].some((filter) => {
          if (filter === 'PUBLIC') {
            return (
              intervention.typeName === 'MANUAL_RECORD' &&
              !intervention.notes?.[0]?.hidden
            )
          }

          if (filter === 'PRIVATE') {
            return (
              intervention.typeName === 'MANUAL_RECORD' &&
              intervention.notes?.[0]?.hidden
            )
          }

          return intervention.typeName === filter
        })
      })
    } else {
      return data?.data.filter((intervention) => {
        return intervention.typeName === 'SO_CREATED'
      })
    }
  }

  const interventions = handleFilterInterventions()
  const maxFilter = filters.length === filterOptions.length

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <div>
          <h2>Linha do tempo</h2>
          <span>Alterações e observações adicionadas à OS</span>
        </div>
        <DropDown.Root>
          <DropDown.Trigger>
            <IconButton>
              {maxFilter ? '' : filters.length}
              <Icon
                name="filter"
                color="element-default"
                aria-label="Opções de filtro"
              />
            </IconButton>
          </DropDown.Trigger>
          <DropDown.Content>
            <DropDown.Item>
              <Checkbox
                id="ALL"
                label="Selecionar todas"
                checked={maxFilter}
                onChange={() => {
                  if (maxFilter) {
                    setFilters([])
                  } else {
                    setFilters(filterDefaultValue)
                  }
                }}
              />
            </DropDown.Item>
            <DropDown.Separator />
            {filterOptions.map((filter) => {
              const isChecked = filters.includes(filter.value)

              return (
                <DropDown.Item key={filter.value}>
                  <Checkbox
                    id={filter.value}
                    checked={isChecked}
                    label={filter.label}
                    onChange={() => {
                      if (isChecked) {
                        setFilters((old) =>
                          old.filter((old) => old !== filter.value),
                        )
                      } else {
                        setFilters((old) => [...old, filter.value])
                      }
                    }}
                  />
                </DropDown.Item>
              )
            })}
          </DropDown.Content>
        </DropDown.Root>
      </div>
      <Field onSubmit={onSubmit} />

      {
        {
          view: (
            <ul>
              {interventions?.map((intervention) => (
                <Intervention
                  key={intervention.id}
                  intervention={intervention}
                />
              ))}
            </ul>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data: interventions,
            isLoading,
            isError,
            isEmpty: !interventions?.length,
          })
        ]
      }
    </section>
  )
}

export default TimeLine
