import { useCallback, useEffect, useState } from 'react'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from '@tanstack/react-table'
import { useForm } from 'react-hook-form'

import {
  ButtonV2,
  LoaderV2,
  ErrorState,
  FormAddon,
  FormGroup,
  Icon,
  TableV2,
  TextField,
  IconButton,
  DropDown,
  ModalV2,
} from 'components'
import { maskedDateTime } from 'utilities/date'
import { handleRenderState } from 'utilities/handleRenderState'
import { CentralRefused, useGetCentralRefused } from 'services/centralRefused'

import { DeleteCentral, EnableCentral } from './components'

import styles from './styles.module.scss'

const columnHelper = createColumnHelper<CentralRefused>()

const columns = [
  columnHelper.accessor('account', {
    header: 'Conta da central',
    cell: (info) => info.getValue() || '--',
  }),
  columnHelper.accessor('model', {
    header: 'Modelo',
    cell: (info) => info.getValue() || '--',
  }),
  columnHelper.accessor((row) => row, {
    header: 'Código',
    cell: (info) => {
      const row = info.getValue()
      return row.macAddress || row.imei || '--'
    },
  }),
  columnHelper.accessor('hostAddress', {
    header: 'IP/Porta',
    cell: (info) => info.getValue() || '--',
  }),
  columnHelper.accessor('datetime', {
    header: 'Data e hora',
    cell: (info) => <>{maskedDateTime(info.getValue())}</>,
  }),
  columnHelper.accessor((row) => row, {
    id: 'action',
    header: '',
    size: 38,
    cell: (info) => (
      <DropDown.Root>
        <DropDown.Trigger>
          <IconButton>
            <Icon name="menu-kebab" />
          </IconButton>
        </DropDown.Trigger>
        <DropDown.Content offset={0}>
          <ModalV2.Root>
            <ModalV2.Trigger>
              <DropDown.Item>Habilitar GPRS/Ethernet</DropDown.Item>
            </ModalV2.Trigger>
            <ModalV2.Content size="lg">
              <EnableCentral central={info.getValue()} />
            </ModalV2.Content>
          </ModalV2.Root>
          <DropDown.Separator />
          <ModalV2.Root>
            <ModalV2.Trigger>
              <DropDown.Item color="red">Remover sem habilitar</DropDown.Item>
            </ModalV2.Trigger>
            <ModalV2.Content>
              <DeleteCentral centralId={info.getValue().id} />
            </ModalV2.Content>
          </ModalV2.Root>
        </DropDown.Content>
      </DropDown.Root>
    ),
  }),
]

interface Form {
  search: string
}

const List = () => {
  const { register, handleSubmit, watch } = useForm<Form>()
  const searchValue = watch('search', '')

  const { data, isLoading, isError } = useGetCentralRefused({})

  const [filteredData, setFilteredData] = useState<
    CentralRefused[] | undefined
  >([])

  const filterData = useCallback(
    (search: string) => {
      const trimmedSearch = search.trim().toLowerCase()

      return data?.data?.filter((item) =>
        Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(trimmedSearch),
        ),
      )
    },
    [data],
  )

  const onSubmit = (values: { search: string }) => {
    const result = filterData(values.search)

    setFilteredData(result)
  }

  useEffect(() => {
    if ((data?.data?.length || 0) > 0) {
      const initialFilter = filterData(searchValue)

      setFilteredData(initialFilter)
    }
  }, [data, filterData])

  const table = useReactTable({
    columns,
    data: filteredData || [],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <main className={styles.container}>
      <header>
        <h1>Habilitação de GPRS/Ethernet</h1>
        <hr />
      </header>

      <form className={styles.filters} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormAddon>
            <Icon name="search" color="element-default" />
          </FormAddon>
          <TextField
            placeholder="Buscar módulo GPRS/Ethernet"
            {...register('search')}
          />
        </FormGroup>
        <ButtonV2 type="submit">Pesquisar</ButtonV2>
      </form>

      <TableV2.Root>
        <TableV2.Header>
          {table?.getHeaderGroups().map((headerGroup) => (
            <TableV2.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableV2.Column
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.column.getSize() }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </TableV2.Column>
              ))}
            </TableV2.Row>
          ))}
        </TableV2.Header>
        <TableV2.Body>
          {
            {
              view: table?.getRowModel().rows.map((row) => (
                <TableV2.Row key={row.id}>
                  {row.getVisibleCells().map((cell, index) =>
                    index < 5 ? (
                      <TableV2.Cell
                        key={cell.id}
                        className={styles[cell.column.id]}
                        style={{ width: cell.column.getSize() }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableV2.Cell>
                    ) : (
                      <TableV2.Action key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableV2.Action>
                    ),
                  )}
                </TableV2.Row>
              )),
              loading: (
                <tr className={styles.exceptionState}>
                  <td colSpan={5}>
                    <div className={styles.empty}>
                      <LoaderV2 />
                      <span>Aguarde</span>
                      <small>Novas centrais conectadas aparecerão aqui.</small>
                    </div>
                  </td>
                </tr>
              ),
              error: (
                <tr className={styles.exceptionState}>
                  <td colSpan={5}>
                    <ErrorState />
                  </td>
                </tr>
              ),
              empty: (
                <tr className={styles.exceptionState}>
                  <td colSpan={5}>
                    <div className={styles.empty}>
                      <LoaderV2 />
                      <span>Aguarde</span>
                      <small>Novas centrais conectadas aparecerão aqui.</small>
                    </div>
                  </td>
                </tr>
              ),
            }[
              handleRenderState({
                data,
                isError,
                isLoading,
                isEmpty: !filteredData?.length,
              })
            ]
          }
        </TableV2.Body>
      </TableV2.Root>
    </main>
  )
}

export default List
