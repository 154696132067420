import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { createAccountSchema } from 'domains/accounts/schemas/createAccountSchema'
import { PatrimonyWithCustomerAndAccountPayload } from 'services/account/types'

import { usePutPatrimony } from 'services/patrimony/hooks/usePutPatrimony'
import { formatCnpj, formatCpf } from 'utilities/masks/document'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { useGetCustomers } from 'shared/hooks/services'
import { joiResolver } from '@hookform/resolvers/joi'
import { useToast } from 'shared/hooks'

import { PatrimonyForm } from 'domains/customer/components/PatrimonyForm/PatrimonyForm'
import { CustomerAlreadyExistsModal } from './components'
import {
  Breadcrumbs,
  Button,
  ContainerScreen,
  Input,
  Loader,
  Wizard,
} from 'components'

import styles from './CreateAccount.module.scss'

export const CreateAccount = () => {
  const form = useForm<PatrimonyWithCustomerAndAccountPayload>({
    mode: 'onChange',
    resolver: joiResolver(createAccountSchema),
  })

  const { register, watch, setValue, handleSubmit } = form
  const document: string = watch('customer.document')

  const isNaturalPersonType = document?.length === 14
  const isLegalPersonType = document?.length > 14

  const { addToast } = useToast()
  const navigate = useNavigate()

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)

  const { customers, isFetching: isFetchingCustomers } = useGetCustomers(
    '',
    document?.replace(/[.\-/]/g, ''),
    '',
    isNaturalPersonType || isLegalPersonType,
  )

  const { mutate: mutateUpdatePatrimony } = usePutPatrimony()

  const isDisabledNextButton = isNaturalPersonType
    ? !watch('customer.name')
    : !watch('customer.name') && !watch('customer.secondName')

  const onRedirectToAccount = useCallback(() => {
    if (customers[0]) {
      navigate(CustomerPaths('account', customers[0]?.accounts[0]?.id))
    }
  }, [customers, navigate])

  const handleNextPage = useCallback(() => {
    register('customer.personType')
    setValue('customer.personType', isLegalPersonType ? 'J' : 'F', {
      shouldValidate: true,
    })
    setActiveStepIndex(1)
  }, [isLegalPersonType, register, setValue])

  const onSubmit = useCallback(
    (payload: PatrimonyWithCustomerAndAccountPayload) => {
      mutateUpdatePatrimony(
        {
          patrimony: {
            ...payload.patrimony,
            id: payload.patrimony.id ?? '',
            postalCode: Number(
              String(payload.patrimony.postalCode).replace(/\D/g, ''),
            ),
            customerId: payload.patrimony.customerId ?? '',
          },
        },
        {
          onSuccess: () => {
            addToast({ message: 'Conta criada com sucesso.' })
            navigate(-1)
          },
          onError: () => {
            addToast({
              message: 'Erro ao criar conta. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [addToast, navigate, mutateUpdatePatrimony],
  )

  return (
    <>
      <Loader isVisible={isFetchingCustomers}>
        <div className={styles.loaderCustomer}>
          <h1>Pesquisando documento</h1>
          <p>Isto pode demorar alguns segundos.</p>
        </div>
      </Loader>
      <CustomerAlreadyExistsModal
        isVisible={!!customers.length}
        onClose={() => navigate(-1)}
        onClick={onRedirectToAccount}
      />
      <ContainerScreen
        clickable
        divider={false}
        renderBreadcrumbs={
          <Breadcrumbs
            items={[
              { title: 'Home', href: '/' },
              {
                title: 'Conta',
                href: '/account/list',
              },
              { title: 'Cadastrar nova conta', href: '#' },
            ]}
          />
        }
      >
        <div className={styles.divider} />
        <div className={styles.stepsContainer}>
          <FormProvider {...form}>
            <Wizard
              activeStepIndex={activeStepIndex}
              wizardBackground="#f1f1f3"
            >
              <Wizard.Step title="Dados do cliente">
                <div className={styles.accountSection}>
                  <h1>Dados do cliente</h1>
                  <Input
                    type="text"
                    label="Documento"
                    {...register('customer.document')}
                    maxLength={18}
                    onChange={(event) => {
                      const value = event.target.value
                      setValue(
                        'customer.document',
                        value
                          ? isLegalPersonType
                            ? formatCnpj(value)
                            : formatCpf(value)
                          : '',
                        {
                          shouldValidate: true,
                        },
                      )
                    }}
                  />
                  <Input
                    type="text"
                    label="Centro de custo"
                    {...register('customer.costCenter')}
                    maxLength={45}
                    onChange={(event) => {
                      const value = event.target.value
                      setValue('customer.costCenter', value, {
                        shouldValidate: true,
                      })
                    }}
                  />
                  {isLegalPersonType ? (
                    <>
                      <Input
                        type="text"
                        label="Razão social"
                        {...register('customer.name')}
                        onChange={(event) => {
                          setValue('customer.name', event.target.value, {
                            shouldValidate: true,
                          })
                        }}
                      />
                      <Input
                        type="text"
                        label="Nome fantasia"
                        {...register('customer.secondName')}
                        onChange={(event) => {
                          setValue('customer.secondName', event.target.value, {
                            shouldValidate: true,
                          })
                        }}
                      />
                    </>
                  ) : (
                    <Input
                      type="text"
                      label="Nome completo"
                      {...register('customer.name')}
                      onChange={(event) => {
                        setValue('customer.name', event.target.value, {
                          shouldValidate: true,
                        })
                      }}
                    />
                  )}
                </div>
              </Wizard.Step>
              <Wizard.Step title="Patrimônio e contas">
                <div className={styles.patrimonySection}>
                  <PatrimonyForm
                    title="Patrimônio"
                    onGoBack={() => setActiveStepIndex(0)}
                    onSave={handleSubmit(onSubmit)}
                  />
                </div>
              </Wizard.Step>
            </Wizard>
          </FormProvider>
        </div>
        {!activeStepIndex && (
          <div className={styles.buttons}>
            <Button
              buttonTitle={'Voltar à listagem'}
              onClick={() => navigate(-1)}
              type="secondary"
              width="172px"
            />
            <Button
              buttonTitle={'Próximo'}
              type="primary"
              width="172px"
              onClick={handleNextPage}
              disabled={isDisabledNextButton}
            />
          </div>
        )}
      </ContainerScreen>
    </>
  )
}
