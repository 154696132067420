import { ComponentProps } from 'react'

import Text, { TextProps } from '../Text'
import joinClassNames from 'utilities/joinClassNames'
import styles from './styles.module.scss'

const Root = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={joinClassNames(styles.root, className)} {...props} />
)

const Title = ({ className, ...props }: TextProps) => (
  <Text
    size="sm"
    weight="bold"
    color="element-medium"
    className={joinClassNames(className, styles.title)}
    {...props}
  />
)

const Content = ({ className, ...props }: TextProps) => (
  <Text className={joinClassNames(styles.content, className)} {...props} />
)

const Info = {
  Root,
  Title,
  Content,
}

export default Info
