import {
  ComponentProps,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'

import { useOnClickOutside } from 'shared/hooks'

import styles from './styles.module.scss'
import fieldStyles from '../field.module.scss'
import { FormGroup, Icon, Tooltip, FormAddon } from '../..'

type InputRef = HTMLInputElement & { dataValue?: string | Array<unknown> }

interface handleFilteredOptionsProps<T> {
  options?: T[]
  valueKey?: keyof T
  value?: string | unknown[]
}

const handleFilteredOptions = <T,>({
  options,
  value,
  valueKey,
}: handleFilteredOptionsProps<T>) => {
  return options?.filter((option) => {
    if (value) {
      return Array.isArray(value)
        ? !(value || []).some((item) => item[valueKey] === option[valueKey])
        : option[valueKey] !== value
    }

    return option
  })
}

interface SelectContextProps<T> {
  valueKey?: keyof T
  rootRef: RefObject<HTMLDivElement>
  inputRef: RefObject<InputRef>
  visible: [boolean, Dispatch<SetStateAction<boolean>>]
  options: [T[] | undefined, Dispatch<SetStateAction<T[] | undefined>>]
  unSelectedOptions: [
    T[] | undefined,
    Dispatch<SetStateAction<T[] | undefined>>,
  ]
}

const SelectContext = createContext<SelectContextProps<unknown> | undefined>(
  undefined,
)

const useSelectContext = <T,>() => {
  const context = useContext(SelectContext) as SelectContextProps<T>

  if (!context) {
    throw new Error('useSelectContext must be used within a SelectProvider')
  }

  return context
}

type ChildrenProps<T> = {
  options: T[] | undefined
  unSelectedOptions: T[] | undefined
}

interface RootProps<T> extends Omit<ComponentProps<'div'>, 'children'> {
  valueKey?: keyof T
  children?: ReactNode | ((props: ChildrenProps<T>) => ReactNode)
  data: T[]
}

const Root = <T extends { name: string }>({
  valueKey,
  data,
  className,
  children,
  ...props
}: RootProps<T>) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState<T[] | undefined>(data)
  const [unSelectedOptions, setUnSelectedOptions] = useState<T[] | undefined>(
    data,
  )

  const value: SelectContextProps<T> = {
    valueKey,
    rootRef,
    inputRef,
    options: [options, setOptions],
    visible: [visible, setVisible],
    unSelectedOptions: [unSelectedOptions, setUnSelectedOptions],
  }

  const handleRenderContent = () => {
    if (typeof children === 'function') {
      return children({ options, unSelectedOptions })
    }

    return children
  }

  const [hasChildFocused, setHasChildFocused] = useState(false)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (rootRef.current && rootRef.current.contains(event.target as Node)) {
        setHasChildFocused(true)
      } else {
        setVisible(false)
        setHasChildFocused(false)
      }
    }
    document.addEventListener('click', handleClick)
  }, [setHasChildFocused])

  return (
    <SelectContext.Provider value={value}>
      <div
        {...props}
        ref={rootRef}
        className={[
          styles.container,
          hasChildFocused && styles.focused,
          className,
        ].join(' ')}
      >
        {handleRenderContent()}
      </div>
    </SelectContext.Provider>
  )
}

interface FieldProps extends Omit<ComponentProps<'input'>, 'value'> {
  value?: string | Array<unknown>
}

const Field = <T extends { name: string }>({
  value,
  onClick,
  className,
  defaultValue,
  autoComplete = 'off',
  ...props
}: FieldProps) => {
  const {
    rootRef,
    inputRef,
    valueKey,
    options: [options],
    visible: [visible, setVisible],
    unSelectedOptions: [_unSelectedOptions, setUnSelectedOptions],
  } = useSelectContext<T>()

  const handleResetFieldValue = () => {
    if (
      inputRef.current &&
      (typeof value === 'string' || value === undefined)
    ) {
      inputRef.current.value = value || ''
    }
  }

  useOnClickOutside(rootRef, () => {
    setVisible(false)
    handleResetFieldValue()
  })

  useEffect(() => {
    handleResetFieldValue()

    setUnSelectedOptions(() =>
      handleFilteredOptions({
        value,
        valueKey,
        options,
      }),
    )

    if (inputRef.current) {
      inputRef.current.dataValue = value
    }
  }, [value])

  return (
    <FormGroup>
      <input
        {...props}
        autoComplete={autoComplete}
        ref={inputRef}
        onClick={(event) => {
          setVisible(true)
          onClick?.(event)
        }}
        className={[fieldStyles.field, styles.input, className].join(' ')}
      />
      <FormAddon>
        <Icon
          color="element-default"
          name="chevron-sm-down"
          className={[visible && styles.rotate].join(' ')}
        />
      </FormAddon>
    </FormGroup>
  )
}

interface OptionProps extends ComponentProps<'li'> {
  shouldCloseGroup?: boolean
}

const Option = ({
  children,
  className,
  onClick,
  shouldCloseGroup = true,
  ...props
}: OptionProps) => {
  const {
    visible: [_visible, setVisible],
  } = useSelectContext()

  const ref = useRef<HTMLTableCellElement>(null)
  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const handleMouseEnter = useCallback(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setTooltipVisible(true)
    }
  }, [])

  const handleMouseLeave = useCallback(() => {
    setTooltipVisible(false)
  }, [])

  return (
    <li
      {...props}
      className={[styles.option, className].join(' ')}
      onClick={(event) => {
        onClick?.(event)
        shouldCloseGroup && setVisible(false)
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isTooltipVisible && (
        <Tooltip
          parentRef={ref}
          type="informative"
          isVisible={isTooltipVisible}
        >
          {children}
        </Tooltip>
      )}
    </li>
  )
}

const Options = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div className={[styles.options, className].join(' ')} {...props} />
}

const Group = ({ className, children, ...props }: ComponentProps<'ul'>) => {
  const {
    visible: [visible],
  } = useSelectContext()

  return (
    <ul
      {...props}
      className={[styles.list, visible && styles.visible, className].join(' ')}
    >
      {children}
    </ul>
  )
}

const Select = {
  Root,
  Field,
  Group,
  Option,
  Options,
}

export default Select
