import { FC, SVGProps, Suspense, forwardRef, lazy, useMemo } from 'react'

import joinClassNames from 'utilities/joinClassNames'

import { IconName } from './icon-names'
import { NestedColors } from '../types'
import styles from './styles.module.scss'

type SVGElement = SVGProps<SVGSVGElement>

export interface IconProps extends Omit<SVGElement, 'fill' | 'ref'> {
  name: IconName
  color?: NestedColors
}

const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      name,
      width = 16,
      height,
      color = 'accent-brand-default',
      style,
      className,
      ...props
    },
    ref,
  ) => {
    const IconComponent = useMemo(
      () =>
        lazy(async () => ({
          default: (await import(`./svg/${name}.svg`))
            .ReactComponent as FC<SVGElement>,
        })),
      [name],
    )

    return (
      <Suspense>
        <IconComponent
          ref={ref}
          {...props}
          role="img"
          width={width}
          height={height || width}
          style={{
            minWidth: width,
            minHeight: height || width,
            '--icon-current-color': `var(--${color})`,
            ...style,
          }}
          className={joinClassNames(styles.icon, className)}
        />
      </Suspense>
    )
  },
)

Icon.displayName = 'Icon'

export default Icon
